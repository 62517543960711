import React, { Component } from "react";
import Helper from "../../utilities/Helper";
import { withIntl } from "../../../common/lang";

class CauseOfHeartDisease extends Component {
  componentDidUpdate = (prv) => {
    if (prv.causeOfHeartDisease !== this.props.causeOfHeartDisease) {
      this.props.setSelected(
        "causeOfHeartDisease",
        this.checkCauseOfHeartDisease(this.props.causeOfHeartDisease)
      );
    }
  };

  handleHeartDiseaseInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  causeOfHeartDiseaseHelper = () => {
    if (this.checkCauseOfHeartDisease(this.props.causeOfHeartDisease)) {
      return <Helper text="" />;
    }
  };

  checkCauseOfHeartDisease(causeOfHeartDisease) {
    //TODO: Not implemented
    return false;
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.causeOfHeartDisease" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select-lg"
              name="causeOfHeartDisease"
              onChange={this.handleHeartDiseaseInputChange}
              value={this.props.causeOfHeartDisease}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="heartValveDisease">
                {formatMessage({ id: "form.heartValveDisease" })}
              </option>
              <option value="congenitalDisease">
                {formatMessage({ id: "form.congenitalDisease" })}
              </option>
              <option value="cardiomyopathy">
                {formatMessage({ id: "form.cardiomyopathy" })}
              </option>
              <option value="coronaryArteryDisease">
                {formatMessage({ id: "form.coronaryArteryDisease" })}
              </option>
              <option value="other">
                {formatMessage({ id: "form.other" })}
              </option>
              <option value="dontKnow">
                {formatMessage({ id: "form.dontKnow" })}
              </option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">
              {this.causeOfHeartDiseaseHelper()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(CauseOfHeartDisease);
