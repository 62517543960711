import React from "react";
import { useIntl } from "react-intl";

const IncreasedInfectiousRiskDescriptionLung = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="title pl-0">
          {formatMessage({ id: "utils.infectionRisk.title" })}:
        </div>
        {formatMessage({ id: "utils.infectionRisk.lung.description" })}
      </div>
      <div className="collapse w-100 iif-example" id="collapseIIR">
        <div className="card-body list"></div>
      </div>
    </div>
  );
};

export default IncreasedInfectiousRiskDescriptionLung;
