import React, { Component } from "react";
import Helper from "../../utilities/Helper";
import { withIntl } from "../../../common/lang";

class HepBPositive extends Component {
  componentDidUpdate = (prv) => {
    if (prv.hepBPositive !== this.props.hepBPositive) {
      this.props.setSelected(
        "hepBPositive",
        this.checkHepBPositive(this.props.hepBPositive)
      );
    }
  };

  handleHepBPositiveInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  hepBPositiveHelper = () => {
    const { formatMessage } = this.props.intl;
    if (this.checkHepBPositive(this.props.hepBPositive)) {
      return (
        <Helper text={formatMessage({ id: "form.hepBPositive.helper" })} />
      );
    }
  };

  checkHepBPositive = (hepBPositive) => {
    if (hepBPositive === "yes") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.hepBPositive" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select search-select-md"
              name="hepBPositive"
              onChange={this.handleHepBPositiveInputChange}
              value={this.props.hepBPositive}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="yes">{formatMessage({ id: "form.yes" })}</option>
              <option value="no">{formatMessage({ id: "form.no" })}</option>
              <option value="notSure">
                {formatMessage({ id: "form.notSure" })}
              </option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">{this.hepBPositiveHelper()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(HepBPositive);
