import AuthService from "./AuthService";
const Host = process.env.REACT_APP_API_HOST;

export default class AccountService {
  constructor(domain) {
    this.domain = domain || Host;
    this.Auth = new AuthService();
  }

  async confirmUser(userId, code) {
    return await this.Auth.fetchWithCreds(
      `${this.domain}/account/confirmemail`,
      {
        method: "POST",
        body: JSON.stringify({
          userId,
          code,
        }),
      }
    );
  }

  async sendForgotPasswordLink(email) {
    return await this.Auth.fetchWithCreds(
      `${this.domain}/account/forgotpassword`,
      {
        method: "POST",
        body: JSON.stringify({
          email,
        }),
      }
    );
  }

  async resetPassword(userId, email, code, password, confirmPassword) {
    return await this.Auth.fetchWithCreds(
      `${this.domain}/account/resetpassword`,
      {
        method: "POST",
        body: JSON.stringify({
          userId,
          email,
          code,
          password,
          confirmPassword,
        }),
      }
    );
  }

  async setInitialPassword(userId, code, password) {
    return await this.Auth.fetchWithCreds(
      `${this.domain}/account/setpassword`,
      {
        method: "POST",
        body: JSON.stringify({
          userId,
          code,
          password,
        }),
      }
    );
  }
}
