import React from "react";
import { ClipLoader } from "react-spinners";

const Loading = ({ isLoading, text }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div>
      <div className="col-12 loading d-flex justify-content-center">
        <ClipLoader
          sizeunit="px"
          size={72}
          color="#ff8f31"
          loading={isLoading}
        />
      </div>
      <div className="col-12 d-flex justify-content-center mt-1">{text}</div>
    </div>
  );
};

export default Loading;
