import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

import AuthService from "../../services/AuthService";
import makeCancelable from "makecancelable";
import Loading from "../utilities/Loading";

const PrivateRoute = ({ redirectPath = "/login" }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const cancelFetchRef = useRef();
  const location = useLocation();
  const locationRef = useRef();

  useEffect(() => {
    if (locationRef.current === location.pathname) {
      return;
    }

    setIsLoading(true);
    locationRef.current = location.pathname;

    const fetchAuthState = async () => {
      const authService = new AuthService();

      cancelFetchRef.current = await makeCancelable(
        authService.checkAuth(),
        (isAuthenticated) => {
          setIsLoading(false);
          setIsAuthenticated(isAuthenticated);
        },
        (error) => console.error(error)
      );
    };

    fetchAuthState();

    return () => {
      cancelFetchRef.current?.();
    };
  }, [location]);

  if (isLoading) {
    return <Loading text="Checking authentication..." isLoading={isLoading} />;
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
