import React from "react";
import { useIntl } from "react-intl";
import FAQs from "../components/faq/FAQs";

const FAQ = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="inner-container">
      <div className="white-block rounded content-body">
        <img
          alt="Transplant Center Search"
          className="hand"
          src={require("../assets/images/hand.png")}
        />
        <h1 className="heading">{formatMessage({ id: "faq.title" })}</h1>
        <img
          alt="Line separator"
          className="img-fluid line"
          src={require("../assets/images/line.png")}
        />
        <FAQs />
      </div>
    </div>
  );
};

export default FAQ;
