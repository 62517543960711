import React from "react";
import { useIntl } from "react-intl";

const DecisionGuideFAQs = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="faq-body">
      <h2>{formatMessage({ id: "ui.label.step1" })}</h2>

      <h4>{formatMessage({ id: "decisionGuideFAQ.question1" })}</h4>
      <div className="faq-content">
        {formatMessage({ id: "decisionGuideFAQ.question1.answer" })}
      </div>

      <h4>{formatMessage({ id: "decisionGuideFAQ.question2" })}</h4>
      <div className="faq-content">
        {formatMessage({ id: "decisionGuideFAQ.question2.answer" })}
      </div>

      <h2>{formatMessage({ id: "ui.label.step2" })}</h2>

      <h4>{formatMessage({ id: "decisionGuideFAQ.question3" })}</h4>
      <div className="faq-content">
        {formatMessage({ id: "decisionGuideFAQ.question3.answer" })}
      </div>

      <h4>{formatMessage({ id: "decisionGuideFAQ.question4" })}</h4>
      <div className="faq-content">
        {formatMessage({ id: "decisionGuideFAQ.question4.answer" })}
      </div>

      <h4>{formatMessage({ id: "decisionGuideFAQ.question5" })}</h4>
      <div className="faq-content">
        {formatMessage({ id: "decisionGuideFAQ.question5.answer" })}
      </div>

      <h4>{formatMessage({ id: "decisionGuideFAQ.question6" })}</h4>
      <div className="faq-content">
        {formatMessage({ id: "decisionGuideFAQ.question6.answer" })}
      </div>

      <h4>{formatMessage({ id: "decisionGuideFAQ.question7" })}</h4>
      <div className="faq-content">
        <ul>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.distance" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.distance.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.age" })}
            </div>
            <div className="faq-content-subcontent u-pre-line">
              {formatMessage({
                id: "decisionGuideFAQ.question7.age.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.heightWeight" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.heightWeight.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.insurance" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.insurance.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.hivPositive" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.hivPositive.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.hepBPositive" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.hepBPositive.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.hepCPositive" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.hepCPositive.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              <u>
                <h4>
                  {formatMessage({
                    id: "decisionGuideFAQ.question7.section.kidney",
                  })}
                </h4>
              </u>
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.bloodType" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.bloodType.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.causeKidney" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.causeKidney.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.multiOrgan" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.multiOrgan.answer.kidney",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              <u>
                <h4>
                  {formatMessage({
                    id: "decisionGuideFAQ.question7.section.liver",
                  })}
                </h4>
              </u>
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.causeLiver" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.causeLiver.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.multiOrgan" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.multiOrgan.answer.liver",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({
                id: "decisionGuideFAQ.question7.previousLiverTransplant",
              })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.previousLiverTransplant.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              <u>
                <h4>
                  {formatMessage({
                    id: "decisionGuideFAQ.question7.section.heart",
                  })}
                </h4>
              </u>
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.causeHeart" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.causeHeart.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.diabetes" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.diabetes.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.multiOrgan" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.multiOrgan.answer.heart",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({
                id: "decisionGuideFAQ.question7.previousHeartTransplant",
              })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.previousHeartTransplant.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({
                id: "decisionGuideFAQ.question7.previousHeartSurgery",
              })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.previousHeartSurgery.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              <u>
                <h4>
                  {formatMessage({
                    id: "decisionGuideFAQ.question7.section.lung",
                  })}
                </h4>
              </u>
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({ id: "decisionGuideFAQ.question7.causeLung" })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.causeLung.answer",
              })}
            </div>
          </li>
          <li>
            <div className="faq-content-subheader">
              {formatMessage({
                id: "decisionGuideFAQ.question7.previousLungTransplant",
              })}
            </div>
            <div className="faq-content-subcontent">
              {formatMessage({
                id: "decisionGuideFAQ.question7.previousLungTransplant.answer",
              })}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DecisionGuideFAQs;
