import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Pagination } from "./Pagination";

import "./Table.css";

const calcPadRows = (data, minRows) => {
  if (data.length >= minRows) {
    return [];
  }

  const padRowsToAdd = minRows - data.length;
  const padRows = [];

  for (let index = 0; index < padRowsToAdd; index++) {
    padRows.push({ id: index });
  }

  return padRows;
};

const Table = ({
  className = "",
  columns,
  columnVisibility = {},
  data,
  defaultPageSize = 10,
  defaultSorted = [],
  minRows = 0,
  showPagination = true,
}) => {
  const padRows = calcPadRows(data, minRows);

  const table = useReactTable({
    initialState: {
      columnVisibility,
      sorting: defaultSorted,
      pagination: {
        pageSize: defaultPageSize,
      },
    },
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleSortingUpdate = (sortingId) => {
    let newSorting;

    const tableSorting = table.getState().sorting;

    if (
      tableSorting &&
      tableSorting.length &&
      tableSorting[0].id === sortingId
    ) {
      const currentSorting = tableSorting[0];

      newSorting = {
        id: sortingId,
        desc: !currentSorting.desc,
      };
    } else {
      newSorting = {
        id: sortingId,
        desc: true,
      };
    }

    table.setSorting([newSorting]);
  };

  const getSortingClassName = (columnId) => {
    const tableSorting = table.getState().sorting;
    const isSortingTable = tableSorting && tableSorting.length;

    if (!isSortingTable) {
      return "";
    }

    const foundColumnSorting = tableSorting.find(
      (sorter) => sorter.id === columnId
    );

    const isSortingAsc = foundColumnSorting && !foundColumnSorting.desc;

    return isSortingAsc ? "-sort-asc" : "-sort-desc";
  };

  const shouldRenderAsGroup = table.getHeaderGroups().length > 1;

  return (
    <div className={`ReactTable${className ? ` ${className}` : ""}`}>
      <div className="rt-table" role="grid">
        {table.getHeaderGroups().map((headerGroup, index) => {
          const isGroup = shouldRenderAsGroup && index === 0;

          return (
            <div
              key={headerGroup.id}
              className={`rt-thead ${isGroup ? "-headerGroups" : "-header"}`}
              style={{ minWidth: "963px" }}
            >
              <div key={headerGroup.id} className="rt-tr" role="row">
                {headerGroup.headers.map((header) => {
                  const { columnDef } = header.column ?? {};
                  const headerClassName = columnDef.headerClassName ?? "";
                  const isSortable = columnDef.enableSorting ?? false;
                  const flexGrow = columnDef.maxWidth ?? 100;

                  return (
                    <div
                      key={header.id}
                      className={`rt-th -cursor-pointer ${headerClassName ?? ""}
                      ${isSortable ? getSortingClassName(header.id) : ""}
                      `}
                      role="columnheader"
                      tabIndex="-1"
                      style={{
                        flex: `${flexGrow} 0 auto`,
                        width: columnDef.width
                          ? `${columnDef.width}px`
                          : "100px",
                        maxWidth: columnDef.maxWidth
                          ? `${columnDef.maxWidth}px`
                          : "none",
                        ...(columnDef.styles || {}),
                      }}
                      onClick={() => {
                        if (isSortable) {
                          handleSortingUpdate(header.id);
                        }
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="rt-tbody" style={{ minWidth: "963px" }}>
          {table.getRowModel().rows.map((row, index) => {
            return (
              <div key={row.id} className="rt-tr-group" role="rowgroup">
                <div
                  className={`rt-tr ${index % 2 ? "-even" : "-odd"}`}
                  role="row"
                >
                  {row.getVisibleCells().map((cell) => {
                    const { columnDef } = cell.column ?? {};
                    const flexGrow = columnDef.maxWidth ?? 100;

                    return (
                      <div
                        key={cell.id}
                        className="rt-td"
                        role="gridcell"
                        style={{
                          flex: `${flexGrow} 0 auto`,
                          width: columnDef.width
                            ? `${columnDef.width}px`
                            : "100px",
                          maxWidth: columnDef.maxWidth
                            ? `${columnDef.maxWidth}px`
                            : "none",
                          ...(columnDef.styles || {}),
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          {!padRows.length &&
            padRows.map((row, index) => {
              return (
                <div key={row.id} className="rt-tr-group" role="rowgroup">
                  <div
                    className={`rt-tr -padRow ${index % 2 ? "-even" : "-odd"}`}
                    role="row"
                  >
                    {table.getVisibleLeafColumns().map((cell) => {
                      const { columnDef } = cell ?? {};
                      const flexGrow = columnDef.maxWidth ?? 100;
                      return (
                        <div
                          key={cell.id}
                          className="rt-td"
                          role="gridcell"
                          style={{
                            flex: `${flexGrow} 0 auto`,
                            width: columnDef.width
                              ? `${columnDef.width}px`
                              : "100px",
                            maxWidth: columnDef.maxWidth
                              ? `${columnDef.maxWidth}px`
                              : "none",
                            ...(columnDef.styles || {}),
                          }}
                        >
                          <span> </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {showPagination && <Pagination table={table} />}
    </div>
  );
};

export default Table;
