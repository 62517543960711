import React from "react";
import { useIntl } from "react-intl";

import bars1 from "../../assets/images/bars1.png";
import bars5 from "../../assets/images/bars5.png";

const Compare = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="better-worse">
      <div className="text">{formatMessage({ id: "utils.compare.title" })}</div>
      <div className="better-worse__image-container">
        <figure>
          <img src={bars1} alt={formatMessage({ id: "utils.compare.worse" })} />
          <figcaption>
            {formatMessage({ id: "utils.compare.worse" })}
          </figcaption>
        </figure>
        <figure>
          <img
            src={bars5}
            alt={formatMessage({ id: "utils.compare.better" })}
          />
          <figcaption>
            {formatMessage({ id: "utils.compare.better" })}
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

export default Compare;
