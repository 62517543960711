export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ORGAN":
      return {
        ...state,
        organ: action.organ.name,
        organDisplay: action.organ.display,
      };
    case "SET_DISTANCE":
      return {
        ...state,
        distanceMiles: action.distanceMiles,
      };
    case "SET_DONORTYPE":
      return {
        ...state,
        donorType: action.donorType.name,
        donorTypeDisplay: action.donorType.display,
      };
    default:
      return state;
  }
};

export default reducer;
