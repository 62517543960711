import React, { useState } from "react";
import { useIntl } from "react-intl";
import { PatternFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { object, string } from "yup";

import Loading from "../components/utilities/Loading";

const Host = process.env.REACT_APP_API_HOST;
const ApiUrl = Host + "/feedback/submit";

const emailSchema = object({
  email: string().email().required(),
});

const Feedback = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [feedbackSuccess, setFeedbackSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailAddressValid, setIsEmailAddressValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const [form, setForm] = useState({
    organs: [],
    choosingTransplantCenter: "N/A",
    whoAreYou: "N/A",
    other: "",
    status: "N/A",
    comments: "",
    phoneNumber: "",
    emailAddress: "",
    canContact: "N/A",
    discussed: "N/A",
  });

  const handleInputChange = (e) => {
    setForm((prevFormValue) => ({
      ...prevFormValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOrganChange = (e) => {
    let organs = [];

    if (e) {
      organs = e.map(function (organ) {
        return organ.value;
      });
    }
    setForm((prevFormValue) => ({
      ...prevFormValue,
      organs: organs,
    }));
  };

  const handleSelectChange = (e, meta) => {
    setForm((prevFormValue) => ({
      ...prevFormValue,
      [meta.name]: e.value,
    }));
  };

  const showOther = () => {
    if (form.whoAreYou === "6 - Other") {
      return (
        <div className="mt-2">
          <label>{formatMessage({ id: "form.other.specify" })}</label>
          <input
            name="other"
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
      );
    }
  };

  const goSearch = () => {
    navigate("/");
  };

  const submitFeedback = async () => {
    setIsLoading(true);

    try {
      const body = {
        organs: form.organs,
        choosingTransplantCenter: form.choosingTransplantCenter,
        whoAreYou: form.whoAreYou,
        other: form.other,
        status: form.status,
        comments: form.comments,
        phoneNumber: form.phoneNumber,
        emailAddress: form.emailAddress,
        canContact: form.canContact,
        discussed: form.discussed,
      };

      await fetch(ApiUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      setIsLoading(false);
      setFeedbackSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };

  const checkPhoneNumber = (e) => {
    const phoneNumber = e.target.value;
    let valid = true;
    if (phoneNumber !== "" && phoneNumber.indexOf("_") > 0) {
      valid = false;
    }

    setIsPhoneNumberValid(valid);
  };

  const checkEmailAddress = (e) => {
    const emailAddress = e.target.value;

    emailSchema
      .validate({ email: emailAddress })
      .then(() => {
        setIsEmailAddressValid(true);
      })
      .catch(() => {
        setIsEmailAddressValid(false);
      });
  };

  const getValidPhoneNumber = () => {
    if (!isPhoneNumberValid) {
      return (
        <small className="text-danger">
          {formatMessage({ id: "feedback.question7.validPhoneNumber" })}
        </small>
      );
    }
  };

  const getValidEmailAddress = () => {
    if (!isEmailAddressValid) {
      return (
        <small className="text-danger">
          {formatMessage({ id: "feedback.question7.validEmailAddress" })}
        </small>
      );
    }
  };

  const renderPageContent = () => {
    if (isLoading) {
      return (
        <div className="col-12 loading d-flex justify-content-center">
          <Loading
            text={formatMessage({ id: "feedback.submitting" })}
            isLoading={isLoading}
          />
        </div>
      );
    } else if (feedbackSuccess) {
      return (
        <div className="feedback-success">
          <h2>{formatMessage({ id: "feedback.success" })}</h2>
          <div>
            <button
              className="btn btn-no-radius btn-outline-purple rounded-top step ml-2"
              type="button"
              onClick={goSearch}
            >
              <div className="row align-items-center justify-content-around">
                <div className="text-left u-pre-line">
                  {formatMessage({ id: "feedback.button.search" })}
                </div>
                <div className="btn-right-text">
                  <img
                    alt="Patient-Specific Search"
                    src={require("../assets/images/arrow.png")}
                  />
                </div>
              </div>
            </button>
          </div>
        </div>
      );
    } else {
      const organOptions = [
        { value: "Kidney", label: "organs.kidney" },
        { value: "Liver", label: "organs.liver" },
        { value: "Heart", label: "organs.heart" },
        { value: "Lung", label: "organs.lung" },
      ];

      const animatedComponents = makeAnimated();

      return (
        <div className="feedback-body">
          <div className="mb-2">
            {formatMessage({ id: "feedback.information1" })}
          </div>
          <div className="mb-2">
            {formatMessage({ id: "feedback.information2" })}
          </div>
          <ul className="content-body-answers">
            <li>
              <div className="content-body-question">
                1) {formatMessage({ id: "feedback.question1" })}&nbsp;
                <small className="text-danger">
                  *{formatMessage({ id: "ui.label.required" })}
                </small>
              </div>
              <div className="content-body-answer">
                <Select
                  className="select-dropdown"
                  classNamePrefix="select-dropdown-sub"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={organOptions}
                  placeholder={formatMessage({
                    id: "feedback.question1.placeholder",
                  })}
                  onChange={handleOrganChange}
                  formatOptionLabel={(data) =>
                    formatMessage({ id: data.label })
                  }
                />
              </div>
            </li>
            <li>
              <div className="content-body-question">
                2) {formatMessage({ id: "feedback.question2" })}
              </div>
              <div className="content-body-answer">
                <Select
                  className="select-dropdown"
                  classNamePrefix="select-dropdown-sub"
                  name="choosingTransplantCenter"
                  onChange={handleSelectChange}
                  placeholder={formatMessage({
                    id: "feedback.question2.placeholder",
                  })}
                  formatOptionLabel={(data) =>
                    formatMessage({ id: data.label })
                  }
                  options={[
                    {
                      value: "1 - Not at all",
                      label: "feedback.question2.option1",
                    },
                    {
                      value: "2 - Somewhat not useful",
                      label: "feedback.question2.option2",
                    },
                    {
                      value: "3 - Neutral",
                      label: "feedback.question2.option3",
                    },
                    {
                      value: "4 - Somewhat useful",
                      label: "feedback.question2.option4",
                    },
                    {
                      value: "5 - Very useful",
                      label: "feedback.question2.option5",
                    },
                  ]}
                />
              </div>
            </li>
            <li>
              <div className="content-body-question">
                3) {formatMessage({ id: "feedback.question3" })}
              </div>
              <div className="content-body-answer">
                <Select
                  className="select-dropdown"
                  classNamePrefix="select-dropdown-sub"
                  name="whoAreYou"
                  onChange={handleSelectChange}
                  placeholder={formatMessage({
                    id: "feedback.question3.placeholder",
                  })}
                  formatOptionLabel={(data) =>
                    formatMessage({ id: data.label })
                  }
                  options={[
                    {
                      value: "1 - Patient seeking a transplant",
                      label: "feedback.question3.option1",
                    },
                    {
                      value:
                        "2 - Family member or friend of a patient seeking a transplant",
                      label: "feedback.question3.option2",
                    },
                    {
                      value: "3 - Transplant Recipient",
                      label: "feedback.question3.option3",
                    },
                    {
                      value: "4 - Clinician or other providers",
                      label: "feedback.question3.option4",
                    },
                    {
                      value: "5 - Insurance company",
                      label: "feedback.question3.option5",
                    },
                    {
                      value: "6 - Other",
                      label: "feedback.question3.option6",
                    },
                  ]}
                />
                {showOther()}
              </div>
            </li>
            <li>
              <div className="content-body-question">
                4) {formatMessage({ id: "feedback.question4" })}
              </div>
              <div className="content-body-answer">
                <Select
                  className="select-dropdown"
                  classNamePrefix="select-dropdown-sub"
                  name="status"
                  onChange={handleSelectChange}
                  placeholder={formatMessage({
                    id: "feedback.question4.placeholder",
                  })}
                  formatOptionLabel={(data) =>
                    formatMessage({ id: data.label })
                  }
                  options={[
                    {
                      value: "1 - Active",
                      label: "feedback.question4.option1",
                    },
                    {
                      value: "2 - Inactive",
                      label: "feedback.question4.option2",
                    },
                    {
                      value: "3 - Not listed",
                      label: "feedback.question4.option3",
                    },
                    {
                      value: "4 - Not known or not applicable",
                      label: "feedback.question4.option4",
                    },
                  ]}
                />
              </div>
            </li>
            <li>
              <div className="content-body-question">
                5) {formatMessage({ id: "feedback.question5" })}
              </div>
              <div className="content-body-answer">
                <Select
                  className="select-dropdown"
                  classNamePrefix="select-dropdown-sub"
                  name="discussed"
                  onChange={handleSelectChange}
                  placeholder={formatMessage({
                    id: "feedback.question5.placeholder",
                  })}
                  formatOptionLabel={(data) =>
                    formatMessage({ id: data.label })
                  }
                  options={[
                    {
                      value: "Yes",
                      label: "feedback.question5.option1",
                    },
                    {
                      value: "No",
                      label: "feedback.question5.option2",
                    },
                    {
                      value: "Not Sure",
                      label: "feedback.question5.option3",
                    },
                  ]}
                />
              </div>
            </li>
            <li>
              <div className="content-body-question">
                6) {formatMessage({ id: "feedback.question6" })}
              </div>
              <div className="content-body-answer">
                <Select
                  className="select-dropdown"
                  classNamePrefix="select-dropdown-sub"
                  name="canContact"
                  onChange={handleSelectChange}
                  placeholder={formatMessage({
                    id: "feedback.question6.placeholder",
                  })}
                  formatOptionLabel={(data) =>
                    formatMessage({ id: data.label })
                  }
                  options={[
                    {
                      value: "Yes",
                      label: "feedback.question6.option1",
                    },
                    {
                      value: "No",
                      label: "feedback.question6.option2",
                    },
                  ]}
                />
              </div>
            </li>
            <li
              className={"animate" + (form.canContact !== "Yes" ? " hide" : "")}
            >
              <div className="content-body-question">
                7) {formatMessage({ id: "feedback.question7" })}
              </div>
              <div className="content-body-answer">
                {getValidPhoneNumber()}
                <PatternFormat
                  format="(###) ###-####"
                  mask="_"
                  name="phoneNumber"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder={formatMessage({
                    id: "feedback.question7.phoneNumber",
                  })}
                  type="tel"
                  pattern="^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$"
                  onBlur={checkPhoneNumber}
                />
                {formatMessage({ id: "form.text.or" })}
                <br />
                {getValidEmailAddress()}
                <input
                  name="emailAddress"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder={formatMessage({
                    id: "feedback.question7.emailAddress",
                  })}
                  type="email"
                  onBlur={checkEmailAddress}
                />
              </div>
            </li>
            <li>
              <div className="content-body-question">
                {formatMessage({ id: "feedback.question8" })}
              </div>
              <div className="content-body-answer">
                <textarea
                  className="form-control comments"
                  name="comments"
                  onChange={handleInputChange}
                  rows="8"
                  placeholder={formatMessage({
                    id: "feedback.question8.placeholder",
                  })}
                />
              </div>
            </li>
          </ul>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-no-radius btn-outline-purple rounded-top float-right w-160"
                type="button"
                onClick={() => submitFeedback()}
                disabled={
                  !isEmailAddressValid ||
                  !isPhoneNumberValid ||
                  form.organs.length === 0
                }
              >
                <div className="row align-items-center justify-content-around">
                  <div className="text-left u-pre-line">
                    {formatMessage({ id: "feedback.button.submit" })}
                  </div>
                  <div className="btn-right-text">
                    <img
                      alt="Search Transplant Centers"
                      src={require("../assets/images/arrow.png")}
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="inner-container">
      <div className="white-block rounded content-body">
        <img
          alt="Transplant Center Search"
          className="hand"
          src={require("../assets/images/hand.png")}
        />
        <h1 className="heading">{formatMessage({ id: "feedback.title" })}</h1>
        <img
          alt="Line separator"
          className="img-fluid line"
          src={require("../assets/images/line.png")}
        />
        <div className="row">
          <div className="col-12">{renderPageContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
