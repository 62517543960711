import React from "react";
import { useIntl } from "react-intl";

import Helper from "../../utilities/Helper";
import State from "../../utilities/State";

const Distance = (props) => {
  const { formatMessage } = useIntl();
  const handleDistanceMilesInputChange = (event) => {
    props.handleOnChange(event);
  };

  const handleDistanceZipCodeInputChange = (event) => {
    props.handleOnChange(event);
  };

  const handleDistanceStateInputChange = (event) => {
    event.target.name = "distanceState";
    props.handleOnChange(event);
  };

  const checkDistanceMiles = () => {
    if (props.distanceMiles === "50") {
      return <Helper text={formatMessage({ id: "form.distance.helper" })} />;
    }
  };

  return (
    <div className="col-12 element-group">
      <div className="row">
        <div className="col-12">
          <div className="search-label">
            {formatMessage({ id: "form.distance.title" })}
          </div>
        </div>
        <div className="col-12">
          <div className="row travel">
            <div className="col">
              <div className="travel-row">
                <div className="travel-row">
                  {formatMessage({ id: "form.text.within" })}
                </div>
                <select
                  className="form-control inline-select"
                  name="distanceMiles"
                  onChange={handleDistanceMilesInputChange}
                  value={props.distanceMiles}
                >
                  <option value="">
                    {formatMessage({ id: "form.distance.any" })}
                  </option>
                  <option value="50">
                    {formatMessage({ id: "form.distance.50" })}
                  </option>
                  <option value="100">
                    {formatMessage({ id: "form.distance.100" })}
                  </option>
                  <option value="250">
                    {formatMessage({ id: "form.distance.250" })}
                  </option>
                  <option value="500">
                    {formatMessage({ id: "form.distance.500" })}
                  </option>
                  <option value="700">
                    {formatMessage({ id: "form.distance.700" })}
                  </option>
                </select>
              </div>
              <div className="travel-row">
                {formatMessage({ id: "form.text.of" })}{" "}
                <input
                  className="form-control inline-search-zipcode"
                  name="distanceZipCode"
                  placeholder={formatMessage({
                    id: "form.zipCode.placeholder",
                  })}
                  onChange={handleDistanceZipCodeInputChange}
                  value={props.distanceZipCode}
                  maxLength="5"
                />
              </div>
              <div className="travel-row">
                {formatMessage({ id: "form.text.or" })}{" "}
                <State
                  onChange={handleDistanceStateInputChange}
                  value={props.distanceState}
                  additionalClass="inline-select-state"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="helper-text">{checkDistanceMiles()}</div>
        </div>
      </div>
    </div>
  );
};

export default Distance;
