import React from "react";
import { Link } from "react-router-dom";

const Dashboard = () => {
  return (
    <div className="inner-container">
      <div className="white-block">
        <h1>SITE ADMINISTRATION</h1>
        <img
          alt="Line separator"
          className="img-fluid line"
          src={require("../../assets/images/line.png")}
        />
        <div className="row mt-2">
          <div className="col">
            <div className="card-deck">
              <div className="card">
                <div className="card-header">Admin Links</div>
                <div className="card-body">
                  <ul>
                    <li>
                      <Link to="/admin/users">Manage Users</Link>
                    </li>
                    <li>
                      <Link to="/admin/feedback">
                        Manage Feedback Responses
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card">
                <div className="card-header">Site Data</div>
                <div className="card-body">
                  <ul>
                    <li>View Current Data (Not implemented)</li>
                    <li>
                      <Link to="/admin/data/import">Import New Data Set</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
