import React from "react";

export const renderFormError = (formik, fieldName, intl) => {
  const error = formik.errors[fieldName];

  let intlMessage = "";
  if (Boolean(error) & Boolean(intl)) {
    intlMessage = intl.formatMessage({ id: error, defaultMessage: error });
  }

  const isValidError = formik.touched[fieldName] && Boolean(error);

  return (
    isValidError && (
      <span className="form-error">{intlMessage ? intlMessage : error}</span>
    )
  );
};
