import { createColumnHelper } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CompanyService from "../../../services/CompanyService";
import Loading from "../../utilities/Loading";
import Table from "../../utilities/Table";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (info) => <div className="cell">{info.getValue()}</div>,
    enableSorting: true,
    sortDescFirst: true,
  }),
  columnHelper.accessor("address1", {
    header: "Location",
    cell: (info) => {
      const { row } = info;
      const address = (
        <div className="cell">
          <div>{info.getValue()}</div>
          <div>
            {row.original.city} {row.original.state} {row.original.zipcode}
          </div>
        </div>
      );

      return address;
    },
    enableSorting: true,
  }),
  columnHelper.accessor("id", {
    header: "",
    enableSorting: false,
    cell: (info) => (
      <div className="cell actions">
        <Link to={`/admin/companies/edit/${info.getValue()}`}>Edit</Link>
        &nbsp;|&nbsp;
        <Link to={`/admin/companies/delete/${info.getValue()}`}>Delete</Link>
      </div>
    ),
    width: 500,
    maxWidth: 200,
  }),
];

export const Companies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const companyService = new CompanyService();

    companyService
      .getCompanies()
      .then((data) => {
        setIsLoading(false);
        setShowError(false);
        setCompanies(data);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setShowError(true);
      });
  }, []);

  const renderPageContent = () => {
    if (isLoading) {
      return <Loading text="Getting companies..." isLoading={isLoading} />;
    } else if (!isLoading && !showError && companies.length > 0) {
      return (
        <div className="row mt-4">
          <div className="col-12">
            <Table
              data={companies}
              columns={columns}
              resizable={false}
              defaultPageSize={10}
              minRows={1}
              showPagination={companies.length > 10}
            />
          </div>
        </div>
      );
    } else if (!isLoading && !showError && companies.length === 0) {
      return (
        <div className="row mt-4">
          <div className="col-12">There are no companies to display.</div>
        </div>
      );
    } else {
      return (
        <div className="row mt-4">
          <div className="col">
            There was an error retrieving companies. Please try again.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="jumbotron  bg-white users">
      <Link to="/admin" className="btn btn-secondary float-right ml-2">
        Back
      </Link>
      <Link to="/admin/companies/add" className="btn btn-purple float-right">
        Add Company
      </Link>
      <h1>MANAGE COMPANIES</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../../assets/images/line.png")}
      />
      {renderPageContent()}
    </div>
  );
};

export default Companies;
