import { createColumnHelper } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserService from "../../../services/UserService";

import Loading from "../../utilities/Loading";

import Table from "../../utilities/Table";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("firstName", {
    header: "First Name",
    enableSorting: true,
    sortDescFirst: true,
    cell: (info) => <div className="cell">{info.getValue()}</div>,
  }),
  columnHelper.accessor("lastName", {
    header: "Last Name",
    enableSorting: true,
    cell: (info) => <div className="cell">{info.getValue()}</div>,
  }),
  columnHelper.accessor("companyName", {
    header: "Company",
    enableSorting: true,
    cell: (info) => <div className="cell">{info.getValue()}</div>,
  }),
  columnHelper.accessor("userId", {
    header: "",
    enableSorting: false,
    cell: (info) => (
      <div className="cell actions">
        <Link to={`/admin/users/edit/${info.getValue()}`}>Edit</Link>
        &nbsp;|&nbsp;
        <Link to={`/admin/users/delete/${info.getValue()}`}>Delete</Link>
      </div>
    ),
    width: 500,
    maxWidth: 200,
  }),
];

const Users = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const userService = new UserService();
    userService
      .getUsers()
      .then((data) => {
        setIsLoading(false);
        setShowError(false);
        setUsers(data);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setShowError(true);
      });
  }, []);

  const renderPageContent = () => {
    if (isLoading) {
      return <Loading text="Getting users..." isLoading={isLoading} />;
    } else if (!isLoading && !showError && users.length > 0) {
      return (
        <div className="row mt-4">
          <div className="col-12">
            <Table
              data={users}
              columns={columns}
              resizable={false}
              defaultPageSize={10}
              minRows={1}
              showPagination={users.length > 10}
            />
          </div>
        </div>
      );
    } else if (!isLoading && !showError && users.length === 0) {
      return (
        <div className="row mt-4">
          <div className="col-12">There are no users to display.</div>
        </div>
      );
    } else {
      return (
        <div className="row mt-4">
          <div className="col">
            There was an error retrieving users. Please try again.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="inner-container">
      <div className="white-block users">
        <Link to="/admin/" className="btn btn-secondary float-right ml-2">
          Back
        </Link>
        <Link to="/admin/users/add" className="btn btn-purple float-right">
          Add User
        </Link>
        <h1>MANAGE USERS</h1>
        <img
          alt="Line separator"
          className="img-fluid line"
          src={require("../../../assets/images/line.png")}
        />
        {renderPageContent()}
      </div>
    </div>
  );
};

export default Users;
