import React from "react";
import { useIntl } from "react-intl";

const IncreasedInfectiousRiskDescriptionLiver = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="title pl-0">
          {formatMessage({ id: "utils.infectionRisk.title" })}:
        </div>
        {formatMessage(
          { id: "utils.infectionRisk.liver.description1" },
          {
            button: (c) => (
              <a
                data-toggle="collapse"
                href="#collapseIIR"
                role="button"
                aria-expanded="false"
                aria-controls="collapseIIR"
              >
                {c}
              </a>
            ),
          }
        )}
      </div>
      <div className="collapse w-100 iif-example" id="collapseIIR">
        <div className="card-body list">
          {formatMessage({ id: "utils.infectionRisk.liver.description2" })}
        </div>
      </div>
    </div>
  );
};

export default IncreasedInfectiousRiskDescriptionLiver;
