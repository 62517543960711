export const getCustomSearchHelpers = (intl) => {
  const { formatMessage } = intl;
  //Custom search fields
  return {
    PatientsOverAgeSixtyFive: {
      value: "ageOverSixtyFive",
      label: formatMessage({ id: "customSearchHelper.ageOverSixtyFive" }),
    },
    PatientsOverAgeSeventy: {
      value: "ageOverSeventy",
      label: formatMessage({ id: "customSearchHelper.ageOverSeventy" }),
    },
    BMIOverThirty: {
      value: "bmiOverThirty",
      label: formatMessage({ id: "customSearchHelper.bmiOverThirty" }),
    },
    BMIOverThirtyFive: {
      value: "bmiOverThirtyFive",
      label: formatMessage({ id: "customSearchHelper.bmiOverThirtyFive" }),
    },
    BMIOverForty: {
      value: "bmiOverForty",
      label: formatMessage({ id: "customSearchHelper.bmiOverForty" }),
    },
    BRecipient: {
      value: "bRecipient",
      label: formatMessage({ id: "customSearchHelper.bRecipient" }),
    },
    InsuranceMedicaid: {
      value: "insuranceMedicaid",
      label: formatMessage({ id: "customSearchHelper.insuranceMedicaid" }),
    },
    LivingDonor: {
      value: "pairedLivingDonation",
      label: formatMessage({ id: "customSearchHelper.pairedLivingDonation" }),
    },
    HIVPositiveDonor: {
      value: "donorHIVPositive",
      label: formatMessage({ id: "customSearchHelper.donorHIVPositive" }),
    },
    HIVPositiveRecipient: {
      value: "recipientHIVPositive",
      label: formatMessage({ id: "customSearchHelper.recipientHIVPositive" }),
    },
    HepBPositive: {
      value: "donorHepBPositiveRecipientHepBPositive",
      label: formatMessage({
        id: "customSearchHelper.donorHepBPositiveRecipientHepBPositive",
      }),
    },
    HepCPositive: {
      value: "donorHepCPositiveRecipientHepCPositive",
      label: formatMessage({
        id: "customSearchHelper.donorHepCPositiveRecipientHepCPositive",
      }),
    },
    HepCNegative: {
      value: "donorHepCPositiveRecipientHepCNegative",
      label: formatMessage({
        id: "customSearchHelper.donorHepCPositiveRecipientHepCNegative",
      }),
    },
    MultiOrgan: {
      value: "spk",
      label: formatMessage({ id: "customSearchHelper.spk" }),
    },
    PreviousLiverTransplant: {
      value: "retransplant",
      label: formatMessage({ id: "customSearchHelper.retransplant.liver" }),
    },
    Diabetes: {
      value: "diabetes",
      label: formatMessage({ id: "customSearchHelper.diabetes" }),
    },
    HeartRetransplant: {
      value: "retransplant",
      label: formatMessage({ id: "customSearchHelper.retransplant.heart" }),
    },
    PreviousHeartSurgery: {
      value: "previousNonTxCardiacSurgery",
      label: formatMessage({
        id: "customSearchHelper.previousNonTxCardiacSurgery",
      }),
    },
    LungRetransplant: {
      value: "retransplant",
      label: formatMessage({ id: "customSearchHelper.retransplant.lung" }),
    },

    //Donor Options
    KDPI: {
      value: "kdpiGreaterEightyFivePercent",
      label: formatMessage({
        id: "customSearchHelper.kdpiGreaterEightyFivePercent",
      }),
    },
    CDCHighRiskDonor: {
      value: "cdcHighRiskDonor",
      label: formatMessage({ id: "customSearchHelper.cdcHighRiskDonor" }),
    },
    DonorHepCNat: {
      value: "donorHepCNAT",
      label: formatMessage({ id: "customSearchHelper.donorHepCNAT" }),
    },
    DCD: {
      value: "dcd",
      label: formatMessage({ id: "customSearchHelper.dcd" }),
    },
    Perfused: {
      value: "perfused",
      label: formatMessage({ id: "customSearchHelper.perfused" }),
    },
  };
};

export const getCustomSearchFields = (organ, intl) => {
  const csh = getCustomSearchHelpers(intl);

  let customSearchFields = [];
  switch (organ.toLowerCase()) {
    case "kidney":
      customSearchFields.push(csh.PatientsOverAgeSeventy);
      customSearchFields.push(csh.BMIOverThirtyFive);
      customSearchFields.push(csh.BMIOverForty);
      customSearchFields.push(csh.BRecipient);
      customSearchFields.push(csh.InsuranceMedicaid);
      customSearchFields.push(csh.LivingDonor);
      customSearchFields.push(csh.HIVPositiveDonor);
      customSearchFields.push(csh.HIVPositiveRecipient);
      customSearchFields.push(csh.HepBPositive);
      customSearchFields.push(csh.HepCPositive);
      customSearchFields.push(csh.MultiOrgan);
      break;
    case "liver":
      customSearchFields.push(csh.PatientsOverAgeSixtyFive);
      customSearchFields.push(csh.PatientsOverAgeSeventy);
      customSearchFields.push(csh.BMIOverThirtyFive);
      customSearchFields.push(csh.BMIOverForty);
      customSearchFields.push(csh.InsuranceMedicaid);
      customSearchFields.push(csh.HIVPositiveDonor);
      customSearchFields.push(csh.HIVPositiveRecipient);
      customSearchFields.push(csh.HepBPositive);
      customSearchFields.push(csh.HepCPositive);
      customSearchFields.push(csh.HepCNegative);
      customSearchFields.push(csh.PreviousLiverTransplant);
      break;
    case "heart":
      customSearchFields.push(csh.PatientsOverAgeSixtyFive);
      customSearchFields.push(csh.BMIOverThirtyFive);
      customSearchFields.push(csh.Diabetes);
      customSearchFields.push(csh.InsuranceMedicaid);
      customSearchFields.push(csh.HIVPositiveDonor);
      customSearchFields.push(csh.HIVPositiveRecipient);
      customSearchFields.push(csh.HepCPositive);
      customSearchFields.push(csh.HepCNegative);
      customSearchFields.push(csh.HeartRetransplant);
      customSearchFields.push(csh.PreviousHeartSurgery);
      break;
    case "lung":
      customSearchFields.push(csh.PatientsOverAgeSeventy);
      customSearchFields.push(csh.BMIOverThirty);
      customSearchFields.push(csh.InsuranceMedicaid);
      customSearchFields.push(csh.HIVPositiveDonor);
      customSearchFields.push(csh.HIVPositiveRecipient);
      customSearchFields.push(csh.HepCPositive);
      customSearchFields.push(csh.HepCNegative);
      customSearchFields.push(csh.LungRetransplant);
      break;
    default:
  }

  return customSearchFields;
};

export const getDonorOptionsSearchFields = (organ, intl) => {
  const csh = getCustomSearchHelpers(intl);

  let donorOptionsSearchFields = [];
  switch (organ.toLowerCase()) {
    case "kidney":
      donorOptionsSearchFields.push(csh.KDPI);
      donorOptionsSearchFields.push(csh.CDCHighRiskDonor);
      donorOptionsSearchFields.push(csh.DonorHepCNat);
      break;
    case "liver":
      donorOptionsSearchFields.push(csh.CDCHighRiskDonor);
      donorOptionsSearchFields.push(csh.DCD);
      donorOptionsSearchFields.push(csh.DonorHepCNat);
      break;
    case "heart":
      donorOptionsSearchFields.push(csh.CDCHighRiskDonor);
      donorOptionsSearchFields.push(csh.DonorHepCNat);

      break;
    case "lung":
      donorOptionsSearchFields.push(csh.CDCHighRiskDonor);
      donorOptionsSearchFields.push(csh.DCD);
      donorOptionsSearchFields.push(csh.DonorHepCNat);
      donorOptionsSearchFields.push(csh.Perfused);
      break;
    default:
  }

  return donorOptionsSearchFields;
};
