import { useFormik } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { object, string } from "yup";

import { renderFormError } from "../../../common/utils/form";
import SearchService from "../../../services/SearchService";
import Loading from "../../utilities/Loading";

const emailMedicalProfileSchema = object({
  emailRecipient: string()
    .email("validation.emailRecipient.valid")
    .required("validation.emailRecipient.required"),
  emailMessage: string().notRequired(),
});

const EmailMedicalProfile = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      emailRecipient: "",
      emailMessage: "",
    },
    validationSchema: emailMedicalProfileSchema,
    onSubmit: (values) => {
      setIsSendingEmail(true);

      const url = props.url;

      const searchService = new SearchService();
      searchService
        .sendEmailSearchCriteria(
          values.emailRecipient,
          values.emailMessage,
          url
        )
        .then(() => {
          setIsSendingEmail(false);
          setEmailSent(true);

          formik.handleReset();
        })
        .catch((error) => {
          console.error(error);
          setIsSendingEmail(false);
          setEmailSent(false);
        });
    },
  });

  const handleClose = () => {
    setIsSendingEmail(false);
    setEmailSent(false);
    formik.resetForm();
  };

  const getEmailProfileModalContent = () => {
    if (!isSendingEmail && !emailSent) {
      return (
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="emailModalLabel">
                {formatMessage({ id: "emailProfile.modal.title" })}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={handleClose}
                aria-label={formatMessage({ id: "ui.label.close" })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>{formatMessage({ id: "emailProfile.modal.subtitle" })}</div>

              <div className="form-group">
                <label htmlFor="emailRecipient" className="col-form-label">
                  {formatMessage({ id: "emailProfile.input.recipient" })}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="emailRecipient"
                  name="emailRecipient"
                  value={formik.values.emailRecipient}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  aria-describedby="emailHelp"
                  placeholder={formatMessage({
                    id: "emailProfile.input.recipient.placeholder",
                  })}
                />
                {renderFormError(formik, "emailRecipient", intl)}
                <small id="emailHelp" className="form-text text-muted">
                  {formatMessage({ id: "emailProfile.input.recipient.helper" })}
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="emailMessage" className="col-form-label">
                  {formatMessage({ id: "emailProfile.input.message" })}
                </label>
                <textarea
                  className="form-control"
                  id="emailMessage"
                  name="emailMessage"
                  rows="6"
                  placeholder={formatMessage({
                    id: "emailProfile.input.message.placeholder",
                  })}
                  value={formik.values.emailMessage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
                {renderFormError(formik, "emailMessage", intl)}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleClose}
              >
                {formatMessage({ id: "ui.label.cancel" })}
              </button>
              <button className="btn btn-purple" type="submit">
                {formatMessage({ id: "emailProfile.button.send" })}
              </button>
            </div>
          </form>
        </div>
      );
    } else if (isSendingEmail && !emailSent) {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="emailModalLabel">
              {formatMessage({ id: "emailProfile.sending.title" })}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label={formatMessage({ id: "ui.label.close" })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Loading
              text={formatMessage({ id: "emailProfile.sending.message" })}
              isLoading={isSendingEmail}
            />
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleClose}
              >
                {formatMessage({ id: "ui.label.cancel" })}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (!isSendingEmail && emailSent) {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="emailModalLabel">
              {formatMessage({ id: "emailProfile.sent.title" })}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label={formatMessage({ id: "ui.label.close" })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-4">
              {formatMessage({ id: "emailProfile.sent.message" })}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleClose}
              >
                {formatMessage({ id: "ui.label.close" })}
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className="modal fade"
      id="emailModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="emailModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        {getEmailProfileModalContent()}
      </div>
    </div>
  );
};

export default EmailMedicalProfile;
