import AuthService from "./AuthService";
const Host = process.env.REACT_APP_API_HOST;

export default class SearchService {
  constructor(domain) {
    this.domain = domain || Host;
    this.Auth = new AuthService();
  }

  setDonorType(donorType) {
    try {
      sessionStorage.setItem("donorType", JSON.stringify(donorType));
    } catch (error) {
      console.error(error);
    }
  }

  getDonorType() {
    try {
      let donorType = JSON.parse(sessionStorage.getItem("donorType"));
      if (donorType === null || typeof donorType === "undefined") {
        return "";
      }

      return donorType;
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  getDistanceMiles() {
    try {
      let value = sessionStorage.getItem("distanceMiles");
      if (value === null || typeof value === "undefined") {
        return "";
      }

      return value;
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  getSearchParameter(parmName) {
    try {
      let value = sessionStorage.getItem(parmName);
      if (value === null || typeof value === "undefined") {
        return "";
      }

      return value;
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  setSearchParameter(parmName, parmValue) {
    try {
      sessionStorage.setItem(parmName, parmValue);
    } catch (error) {
      console.error(error);
    }
  }

  async getSearch(organ, distanceZipCode, distanceMiles, distanceState) {
    return await this.Auth.fetchWithCreds(`${this.domain}/search/${organ}`, {
      method: "POST",
      body: JSON.stringify({
        organ: organ,
        distanceZipCode: distanceZipCode,
        distanceMiles: distanceMiles,
        distanceState: distanceState,
      }),
    });
  }

  async sendEmailSearchResults(recipient, message, url) {
    const response = await this.Auth.fetchWithCreds(
      `${this.domain}/search/emailsearchresults`,
      {
        method: "POST",
        body: JSON.stringify({
          recipient: recipient,
          message: message,
          url: url,
        }),
      }
    );

    if (response.status === "failed") {
      throw new Error(response.body);
    }
    return response;
  }

  async sendEmailSearchCriteria(recipient, message, url) {
    const response = await this.Auth.fetchWithCreds(
      `${this.domain}/search/emailsearchcriteria`,
      {
        method: "POST",
        body: JSON.stringify({
          recipient: recipient,
          message: message,
          url: url,
        }),
      }
    );

    if (response.status === "failed") {
      throw new Error(response.body);
    }
    return response;
  }
}
