import React from "react";
import { useIntl } from "react-intl";

const CustomSearchField = (props) => {
  const { formatMessage } = useIntl();

  let field = props.field;
  let row = props.row;
  let rowValue = row[field];
  if (row !== null && field !== null && typeof row[field] !== "undefined") {
    rowValue = row[field];
  } else {
    rowValue = 0;
  }

  if (field) {
    return (
      <div>
        <div className="results-number">{rowValue}</div>
        <div className="results-text">
          {formatMessage({ id: "ui.label.adults" })}
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export default CustomSearchField;
