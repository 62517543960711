import React, { Component } from "react";
import Helper from "../../utilities/Helper";
import { withIntl } from "../../../common/lang";

class CauseOfKidneyDisease extends Component {
  componentDidUpdate = (prv) => {
    if (prv.causeOfKidneyDisease !== this.props.causeOfKidneyDisease) {
      this.props.setSelected(
        "causeOfKidneyDisease",
        this.checkCauseOfKidneyDisease(this.props.causeOfKidneyDisease)
      );
    }
  };

  handleKidneyDiseaseInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  causeOfKidneyDiseaseHelper = () => {
    const { formatMessage } = this.props.intl;
    if (this.checkCauseOfKidneyDisease(this.props.causeOfKidneyDisease)) {
      return (
        <Helper
          text={formatMessage({ id: "form.causeOfKidneyDisease.helper" })}
        />
      );
    }
  };

  checkCauseOfKidneyDisease(causeOfKidneyDisease) {
    if (this.props.age >= 65 && causeOfKidneyDisease === "diabetes") {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.causeOfKidneyDisease" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select-lg"
              name="causeOfKidneyDisease"
              onChange={this.handleKidneyDiseaseInputChange}
              value={this.props.causeOfKidneyDisease}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="diabetes">
                {formatMessage({ id: "form.diabetes" })}
              </option>
              <option value="hypertension">
                {formatMessage({ id: "form.hypertension" })}
              </option>
              <option value="polycysticKidneyDisease">
                {formatMessage({ id: "form.polycysticKidneyDisease" })}
              </option>
              <option value="glomerularDisease">
                {formatMessage({ id: "form.glomerularDisease" })}
              </option>
              <option value="other">
                {formatMessage({ id: "form.other" })}
              </option>
              <option value="notsure">
                {formatMessage({ id: "form.notSure" })}
              </option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">
              {this.causeOfKidneyDiseaseHelper()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(CauseOfKidneyDisease);
