import React from "react";
import { Route, Routes } from "react-router-dom";

import Search from "../../components/search/Search";
import KidneyProfile from "../../components/search/medical-profile/KidneyProfile";
import LiverProfile from "../../components/search/medical-profile/LiverProfile";
import BasicSearch from "../../components/search/basic-search/BasicSearch";
import HeartProfile from "../../components/search/medical-profile/HeartProfile";
import LungProfile from "../../components/search/medical-profile/LungProfile";

const SearchRouter = () => {
  return (
    <Routes>
      <Route exact path="basic" element={<BasicSearch />} />
      <Route exact path="kidney" element={<KidneyProfile />} />
      <Route exact path="liver" element={<LiverProfile />} />
      <Route exact path="heart" element={<HeartProfile />} />
      <Route exact path="lung" element={<LungProfile />} />
      <Route exact path="*" element={<Search />} />
    </Routes>
  );
};

export default SearchRouter;
