import React from "react";
import check from "../../assets/images/check.png";
import { useIntl } from "react-intl";

const Check = () => {
  const { formatMessage } = useIntl();

  return (
    <img src={check} alt={formatMessage({ id: "ui.label.appliesToYou" })} />
  );
};

export default Check;
