import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { object, string } from "yup";

import { renderFormError } from "../../../common/utils/form";
import CompanyService from "../../../services/CompanyService";
import Loading from "../../utilities/Loading";
import State from "../../utilities/State";

const addCompanySchema = object({
  companyName: string().required("Company Name is required"),
  address1: string().required("Address 1 is required"),
  address2: string().notRequired(),
  city: string().required("City is required"),
  state: string().required("State is required"),
  zipCode: string().required("Zip Code is required"),
  phoneNumber: string().required("Phone Number is required"),
  url: string().notRequired(),
});

const companyService = new CompanyService();

const AddCompany = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState([]);

  const formik = useFormik({
    initialValues: {
      companyName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      url: "",
    },
    validationSchema: addCompanySchema,
    onSubmit: (values) => {
      setIsLoading(true);
      setLoadingText("Saving company...");

      try {
        companyService
          .addCompany(
            values.companyName,
            values.address1,
            values.address2,
            values.city,
            values.state,
            values.zipCode,
            values.phoneNumber,
            values.url
          )
          .then((data) => {
            if (data.status === "succeeded") {
              setIsLoading(false);
              setShowError(false);
              navigate("/admin/companies");
            } else if (data.status === "failed") {
              setIsLoading(false);
              setErrors(data.body);
            }
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
            showError(true);
          });
      } catch (error) {
        console.error(error);
      }
    },
  });

  const getErrors = () => {
    return errors.map((error, index) => (
      <div className="text-danger pb-2" key={index}>
        {error.description}
      </div>
    ));
  };

  const renderPageContent = () => {
    if (isLoading) {
      return <Loading text={loadingText} isLoading={isLoading} />;
    } else if (!isLoading && !showError) {
      return (
        <div className="row mt-4">
          <div className="col-12">{getErrors()}</div>
          <div className="col-12">
            <form>
              <div className="form-group">
                <label htmlFor="company-name">Company Name</label>
                <input
                  id="company-name"
                  className="form-control"
                  name="companyName"
                  placeholder="Company Name"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {renderFormError(formik, "companyName")}
              </div>
              <div className="form-group">
                <label htmlFor="address-1">Address 1</label>
                <input
                  id="address-1"
                  type="text"
                  className="form-control"
                  name="address1"
                  placeholder="Address"
                  value={formik.values.address1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {renderFormError(formik, "address1")}
              </div>
              <div className="form-group">
                <label htmlFor="address-2">Address 2</label>
                <input
                  id="address-2"
                  type="text"
                  className="form-control"
                  name="address2"
                  placeholder="Address 2"
                  value={formik.values.address2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  type="text"
                  className="form-control"
                  name="city"
                  placeholder="City"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {renderFormError(formik, "city")}
              </div>
              <div className="form-group">
                <label htmlFor="state">State</label>
                <State
                  id="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {renderFormError(formik, "state")}
              </div>
              <div className="form-group">
                <label htmlFor="zip-code">Zip Code</label>
                <input
                  id="zip-code"
                  type="text"
                  className="form-control"
                  name="zipCode"
                  placeholder="Zip Code"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {renderFormError(formik, "zipCode")}
              </div>
              <div className="form-group">
                <label htmlFor="phone-number">Phone Number</label>
                <input
                  id="phone-number"
                  type="text"
                  className="form-control"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {renderFormError(formik, "phoneNumber")}
              </div>
              <div className="form-group">
                <label htmlFor="url">Url</label>
                <input
                  id="url"
                  type="text"
                  className="form-control"
                  name="url"
                  placeholder="Url"
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </form>
          </div>
          <div className="col">
            <Link
              to="/admin/companies"
              className="btn btn-secondary float-right ml-2"
            >
              Cancel
            </Link>
            <button
              className="btn btn-purple float-right"
              onClick={formik.handleSubmit}
              type="submit"
            >
              Create Company
            </button>
          </div>
        </div>
      );
    } else if (!isLoading && showError) {
      return (
        <div className="row mt-4">
          <div className="col">
            There was an error retrieving this company. Please try again.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="jumbotron bg-white content-body">
      <h1>ADD COMPANY</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../../assets/images/line.png")}
      />
      {renderPageContent()}
    </div>
  );
};

export default AddCompany;
