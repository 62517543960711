import React, { Component } from "react";
import Helper from "../../utilities/Helper";
import { withIntl } from "../../../common/lang";

class HepCPositive extends Component {
  componentDidUpdate = (prv) => {
    if (prv.hepCPositive !== this.props.hepCPositive) {
      this.props.setSelected(
        "hepCPositive",
        this.checkHepCPositive(this.props.hepCPositive)
      );
    }
  };

  handleHepCPositiveInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  hepCPositiveHelper = () => {
    const { formatMessage } = this.props.intl;
    if (this.checkHepCPositive(this.props.hepCPositive)) {
      return (
        <Helper text={formatMessage({ id: "form.hepCPositive.helper" })} />
      );
    }
  };

  checkHepCPositive = (hepCPositive) => {
    if (hepCPositive === "yes_treated" || hepCPositive === "yes_active") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.hepCPositive" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select search-select-md"
              name="hepCPositive"
              onChange={this.handleHepCPositiveInputChange}
              value={this.props.hepCPositive}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="yes_treated">
                {formatMessage({ id: "form.yes.treated" })}
              </option>
              <option value="yes_active">
                {formatMessage({ id: "form.yes.active" })}
              </option>
              <option value="no">{formatMessage({ id: "form.no" })}</option>
              <option value="notSure">
                {formatMessage({ id: "form.notSure" })}
              </option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">{this.hepCPositiveHelper()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(HepCPositive);
