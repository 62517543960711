import React, { Component } from "react";
import Select from "react-select";

import { withIntl } from "../../../common/lang";

class CustomSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: {},
      recipientCriteriaSearchFields: [],
      otherCustomSearchFields: [],
      displayDefault: false,
    };
  }

  customSearch = (e) => {
    this.props.onCustomSearch(e);
  };

  getHeader = () => {
    return (
      <div className="custom-search-header u-pre-line">{this.props.title}</div>
    );
  };

  render() {
    const { formatMessage } = this.props.intl;

    let groupStyles = {
      display: "flex",
      textAlign: "left",
    };

    let groupedOptions = [];
    if (
      !this.props.recipientCriteriaSearchFields ||
      this.props.recipientCriteriaSearchFields.length === 0
    ) {
      groupedOptions = [
        {
          options: this.props.otherCustomSearchFields,
        },
      ];
    } else {
      groupedOptions = [
        {
          label: formatMessage({ id: "search.yourCriteria" }),
          options: this.props.recipientCriteriaSearchFields,
        },
        {
          label: formatMessage({ id: "search.otherCriteria" }),
          options: this.props.otherCustomSearchFields,
        },
      ];
    }

    let formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
      </div>
    );

    let sizeCss = "";
    if (this.props.selectedValue && this.props.selectedValue.label) {
      let labelLength = this.props.selectedValue.label.length;
      if (labelLength > 15 && labelLength <= 25) {
        sizeCss = "md";
      } else if (labelLength > 25) {
        sizeCss = "lg";
      }
    }

    return (
      <div className="custom-search">
        {this.getHeader()}
        <Select
          onChange={this.customSearch}
          value={this.props.selectedValue}
          options={groupedOptions}
          formatGroupLabel={formatGroupLabel}
          className={"custom-search-dropdown " + sizeCss}
          classNamePrefix="field"
          isSearchable={false}
        />
      </div>
    );
  }
}

export default withIntl(CustomSearch);
