import React from "react";
import { useIntl } from "react-intl";

const Perfused = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="row">
      <div className="col-12">
        <div className="title pl-0">
          {formatMessage({ id: "utils.perfused.title" })}:
        </div>
        {formatMessage({ id: "utils.perfused.description" })}
      </div>
    </div>
  );
};

export default Perfused;
