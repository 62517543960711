import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { object, string } from "yup";

import AccountService from "../../services/AccountService";
import AuthService from "../../services/AuthService";
import Loading from "../utilities/Loading";

const forgotPasswordSchema = object({
  email: string().email().required("Email is required"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    const authService = new AuthService();
    if (authService.loggedIn()) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const accountService = new AccountService();
      accountService
        .sendForgotPasswordLink(values.email)
        .then(() => {
          setIsLoading(false);
          setShowError(false);
          setMessageSent(true);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          setShowError(true);
          setMessageSent(false);
        });
    },
  });

  const renderPageContent = () => {
    if (isLoading) {
      return (
        <Loading text="Sending password reset link..." isLoading={isLoading} />
      );
    } else if (!isLoading && !showError && !messageSent) {
      return (
        <div className="row mt-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="col-12 pb-2">
              <div>
                Please enter your email address below and we will send you an
                email to reset your password.
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formik.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-6">
              <button
                className="btn btn-purple"
                type="submit"
                disabled={!formik.isValid}
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      );
    } else if (messageSent) {
      return (
        <div className="row mt-4">
          <div className="col-12">
            <div>
              We have successfully sent you a password reset link. Please check
              your email for further instructions on how to reset your password.
            </div>
            <div>
              <Link to="/login">Sign into your account</Link>
            </div>
          </div>
        </div>
      );
    } else if (showError) {
      return (
        <div>
          We were unable to send you a password resent link. Please try again.
        </div>
      );
    }
  };

  return (
    <div className="jumbotron  bg-white content-body">
      <h1>RESET YOUR PASSWORD</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../assets/images/line.png")}
      />
      {renderPageContent()}
    </div>
  );
};

export default ForgotPassword;
