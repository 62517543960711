import React from "react";
import { Route, Routes } from "react-router-dom";

import MyAccount from "../../components/account/MyAccount";
import ConfirmEmail from "../../components/account/ConfirmEmail";
import ForgotPassword from "../../components/account/ForgotPassword";
import ResetPassword from "../../components/account/ResetPassword";
import PrivateRoute from "../../components/auth/PrivateRoute";

const Account = () => {
  return (
    <div>
      <Routes>
        <Route exact path="confirmemail" element={<ConfirmEmail />} />
        <Route exact path="forgotpassword" element={<ForgotPassword />} />
        <Route path="resetpassword" element={<ResetPassword />} />
        <Route path="*" element={<PrivateRoute />}>
          <Route path="*" element={<MyAccount />} />
        </Route>
      </Routes>
    </div>
  );
};

export default Account;
