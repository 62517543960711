import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const LAST_DATA_IMPORT_DATE = "06/01/2023";

const Footer = () => {
  const { formatMessage, formatDate } = useIntl();

  const importDate = formatDate(new Date(LAST_DATA_IMPORT_DATE), {
    year: "numeric",
    month: "long",
  });

  return (
    <div className="footer">
      <div className="container">
        <div className="row w-100">
          <div className="col-lg-6 col-xs-6 about-company">
            <h4>{formatMessage({ id: "app.about.title" })}</h4>
            <div className="text-white-50">
              {formatMessage({ id: "app.about" })}
            </div>
            <div className="text-white-50 mt-4">
              {formatMessage({ id: "app.lastImport" }, { date: importDate })}
            </div>
          </div>
          <div className="col-lg-3 col-xs-0 links"></div>
          <div className="col-lg-3 col-xs-6 links">
            <h4 className="mt-lg-0 mt-sm-3">
              {formatMessage({ id: "app.links.title" })}
            </h4>
            <ul className="m-0 p-0">
              <li>
                - <Link to="/">{formatMessage({ id: "navigation.home" })}</Link>
              </li>
              <li>
                -{" "}
                <Link to="/search/basic">
                  {formatMessage({ id: "navigation.basicSearch" })}
                </Link>
              </li>
              <li>
                -{" "}
                <Link to="/">
                  {formatMessage({ id: "navigation.personalizedSearch" })}
                </Link>
              </li>
              <li>
                -{" "}
                <Link to="/feedback">
                  {formatMessage({ id: "navigation.feedback" })}
                </Link>
              </li>
              <li>
                -{" "}
                <Link to="/faq">{formatMessage({ id: "navigation.faq" })}</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col copyright">
            <p className="">
              <small className="text-white-50">
                © {new Date().getFullYear()}.{" "}
                {formatMessage({ id: "app.copyright" })}
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
