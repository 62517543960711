import React from "react";
import { useIntl } from "react-intl";

function Center(props) {
  const { formatMessage } = useIntl();

  if (process.env.REACT_APP_ENV_NAME === "production") {
    return (
      <>
        <button
          type="button"
          className="btn btn-link center-name"
          data-toggle="modal"
          data-target={`#centerModal${props.row.original.centerId}`}
        >
          {props.value}
        </button>

        <div
          className="modal fade"
          id={`centerModal${props.row.original.centerId}`}
          tabIndex={`${props.row.original.centerId}`}
          role="dialog"
          aria-labelledby="centerModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="centerModalLabel">
                  {formatMessage({ id: "results.center.title" })}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label={formatMessage({ id: "ui.label.close" })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body center-modal">
                <div className="name">{props.row.original.centerName}</div>
                <div className="address">
                  {props.row.original.centerAddress1}
                </div>
                <div className="city-state-zip">
                  {props.row.original.centerCity},{" "}
                  {props.row.original.centerState}{" "}
                  {props.row.original.centerZipCode}
                </div>
                <div className="phone">
                  {props.row.original.centerPhoneNumber}
                </div>
                <div className="website">
                  <a
                    href={`${props.row.original.centerUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage({ id: "results.center.link" })}
                  </a>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {formatMessage({ id: "ui.label.close" })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="center-name">Hospital {props.row.original.centerId}</div>
    );
  }
}

export default Center;
