import React from "react";
import UserLogin from "../../components/account/UserLogin";

const Login = () => {
  return (
    <div className="white-block">
      <h1>LOGIN</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../assets/images/line.png")}
      />
      <div className="mb-2">Enter your username and password below.</div>
      <UserLogin />
    </div>
  );
};

export default Login;
