import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { number, object, string } from "yup";

import { renderFormError } from "../../../common/utils/form";
import UserService from "../../../services/UserService";
import CompanyService from "../../../services/CompanyService";
import Loading from "../../utilities/Loading";

const addUserSchema = object({
  companyId: number().required(),
  firstName: string().required("First Name is required"),
  lastName: string().required("Last Name is required"),
  email: string()
    .email("Email address must be a valid email")
    .required("Email address is required"),
});

const userService = new UserService();
const companyService = new CompanyService();

const AddUser = () => {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [initialCompanyId, setInitialCompanyId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState([]);

  const formik = useFormik({
    initialValues: {
      companyId: 0,
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: addUserSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      setLoadingText("Saving user...");

      const isActive = true;

      try {
        userService
          .addUser(
            values.firstName,
            values.lastName,
            values.email,
            values.companyId,
            isActive
          )
          .then((data) => {
            if (data.status === "succeeded") {
              setShowError(false);
              setIsLoading(false);

              navigate("/admin/users");
            } else if (data.status === "failed") {
              setIsLoading(false);
              setErrors(data.body);
            }
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
            showError(true);
          });
      } catch (e) {
        console.error(e);
      }
    },
  });

  useEffect(() => {
    let defaultCompanyId = 0;

    companyService.getCompanies().then((data) => {
      if (data.length > 0) {
        defaultCompanyId = data[0].id;
      }

      setCompanies(data);
      setInitialCompanyId(defaultCompanyId);
    });
  }, []);

  useEffect(() => {
    formik.setFieldValue("companyId", initialCompanyId);
    // eslint-disable-next-line
  }, [initialCompanyId]);

  const getCompanies = () => {
    return companies.map((company) => (
      <option key={company.id} value={company.id}>
        {company.name}
      </option>
    ));
  };

  const getErrors = () => {
    return errors.map((error, index) => (
      <div className="text-danger pb-2" key={index}>
        {error.description}
      </div>
    ));
  };

  const renderPageContent = () => {
    if (isLoading) {
      return <Loading text={loadingText} isLoading={isLoading} />;
    } else if (!isLoading && !showError) {
      return (
        <div className="row mt-4">
          <div className="col-12">{getErrors()}</div>
          <div className="col-12">
            <form>
              <div className="form-group">
                <label htmlFor="company-id">Company</label>
                <select
                  id="company-id"
                  className="form-control"
                  name="companyId"
                  value={formik.values.companyId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {getCompanies()}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="first-name">First Name</label>
                <input
                  id="first-name"
                  className="form-control"
                  name="firstName"
                  placeholder="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {renderFormError(formik, "firstName")}
              </div>
              <div className="form-group">
                <label htmlFor="last-name">Last Name</label>
                <input
                  id="last-name"
                  type="text"
                  className="form-control"
                  name="lastName"
                  placeholder="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {renderFormError(formik, "lastName")}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="name@example.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {renderFormError(formik, "email")}
              </div>
            </form>
          </div>
          <div className="col">
            <Link
              to="/admin/users"
              className="btn btn-secondary float-right ml-2"
            >
              Cancel
            </Link>
            <button
              className="btn btn-purple float-right"
              onClick={formik.handleSubmit}
              type="submit"
            >
              Create User
            </button>
          </div>
        </div>
      );
    } else if (!this.state.isLoading && this.state.showError) {
      return (
        <div className="row mt-4">
          <div className="col">
            There was an error retrieving this user. Please try again.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="inner-container">
      <div className="white-block">
        <h1>CREATE USER</h1>
        <img
          alt="Line separator"
          className="img-fluid line"
          src={require("../../../assets/images/line.png")}
        />
        {renderPageContent()}
      </div>
    </div>
  );
};

export default AddUser;
