import React from "react";
import { Link } from "react-router-dom";

import AuthService from "../../services/AuthService";

const MyAccount = () => {
  const getLinks = () => {
    const authService = new AuthService();

    const links = (
      <ul>
        <li>
          <Link to="/account/edit">Edit My Information</Link>
        </li>
        <li>
          <Link to="/account/changepassword">Change My Password</Link>
        </li>
        {!authService.isAdmin() && (
          <li>
            <Link to="/account/savedsearches">View Saved Searches</Link>
          </li>
        )}
      </ul>
    );

    return links;
  };

  return (
    <div className="jumbotron bg-white content-body">
      <h1>MY ACCOUNT</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../assets/images/line.png")}
      />
      <div className="row mt-2">
        <div className="col">{getLinks()}</div>
      </div>
    </div>
  );
};

export default MyAccount;
