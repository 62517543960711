import AuthService from './AuthService';
const Host = process.env.REACT_APP_API_HOST;

export default class OrganService {
    constructor(domain) {
        this.domain = domain || Host;
        this.Auth = new AuthService();
    }
    
    setOrgan(organ) {
        try {
            sessionStorage.setItem('organ', JSON.stringify(organ));
        }
        catch (error) {
            console.error(error);
        }
    }

    getOrgan() {
        try {
            let organ = JSON.parse(sessionStorage.getItem('organ'));
            if (organ === null || typeof organ === 'undefined') {
                return '';
            }
            return organ;
        }
        catch (error) {
            console.error(error);
            return "";
        }
    }

    async getOrganData() {
        return await this.Auth.fetchWithCreds(`${this.domain}/data/latest`, {
            method: 'GET',
        });
    }
}
