import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import CompanyService from "../../../services/CompanyService";
import Loading from "../../utilities/Loading";

const companyService = new CompanyService();

const DeleteCompany = () => {
  const navigate = useNavigate();
  const { id: companyId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [showError, setShowError] = useState(false);
  const [company, setCompany] = useState({});

  useEffect(() => {
    setIsLoading(true);
    setLoadingText("Getting company...");

    companyService
      .getCompany(companyId)
      .then((data) => {
        setIsLoading(false);
        setShowError(false);
        setCompany({
          companyName: data.name,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zipCode: data.zipCode,
          phoneNumber: data.phoneNumber,
          url: data.url,
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setShowError(true);
      });
  }, [companyId]);

  const handleDeleteClick = () => {
    setIsLoading(true);
    setLoadingText("Deleting company...");

    companyService.deleteCompany(companyId).then((data) => {
      if (data.status === "succeeded") {
        setIsLoading(false);
        navigate("/admin/companies");
      } else if (data.status === "failed") {
        setIsLoading(false);
        setShowError(true);
      }
    });
  };

  const renderPageContent = () => {
    if (isLoading) {
      return <Loading text={loadingText} isLoading={isLoading} />;
    } else if (!isLoading && !showError) {
      return (
        <div className="row mt-4">
          <div className="col">
            <p>Are you sure you want to delete this company?</p>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>Name: &nbsp;</label>
              {company.companyName}
            </div>
            <div className="form-group">
              <label>Address 1: &nbsp;</label>
              {company.address1}
            </div>
            <div className="form-group">
              <label>Address 2: &nbsp;</label>
              {company.address2}
            </div>
            <div className="form-group">
              <label>City: &nbsp;</label>
              {company.city}
            </div>
            <div className="form-group">
              <label>State: &nbsp;</label>
              {company.state}
            </div>
            <div className="form-group">
              <label>Zip Code: &nbsp;</label>
              {company.zipCode}
            </div>
            <div className="form-group">
              <label>Phone Number: &nbsp;</label>
              {company.phoneNumber}
            </div>
            <div className="form-group">
              <label>Url: &nbsp;</label>
              {company.url}
            </div>
          </div>
          <div className="col">
            <Link
              to="/admin/companies"
              className="btn btn-secondary float-right ml-2"
            >
              Cancel
            </Link>
            <button
              className="btn btn-purple float-right"
              onClick={handleDeleteClick}
            >
              Delete Company
            </button>
          </div>
        </div>
      );
    } else if (!isLoading && showError) {
      return (
        <div className="row mt-4">
          <div className="col">
            There was an error retrieving this company. Please try again.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="jumbotron bg-white content-body">
      <h1>DELETE COMPANY</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../../assets/images/line.png")}
      />
      {renderPageContent()}
    </div>
  );
};

export default DeleteCompany;
