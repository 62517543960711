import React, { Component } from "react";
import Helper from "../../utilities/Helper";
import { withIntl } from "../../../common/lang";

class MultiOrgan extends Component {
  componentDidUpdate = (prv) => {
    if (prv.multiOrgan !== this.props.multiOrgan) {
      this.props.setSelected(
        "multiOrgan",
        this.checkMultiOrgan(this.props.multiOrgan)
      );
    }
  };

  handleMultiOrganInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  multiOrganHelper = () => {
    const { formatMessage } = this.props.intl;
    if (this.checkMultiOrgan(this.props.multiOrgan)) {
      return <Helper text={formatMessage({ id: "form.multiOrgan.helper" })} />;
    }
  };

  checkMultiOrgan = (multiOrgan) => {
    if (multiOrgan === "yes") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.multiOrgan" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select-lg"
              name="multiOrgan"
              onChange={this.handleMultiOrganInputChange}
              value={this.props.multiOrgan}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="no">{formatMessage({ id: "form.no" })}</option>
              <option value="yes_spk">
                {formatMessage({ id: "form.yes.spk" })}
              </option>
              <option value="yes_other">
                {formatMessage({ id: "form.yes.other" })}
              </option>
              <option value="notSure">
                {formatMessage({ id: "form.notSure" })}
              </option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">{this.multiOrganHelper()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(MultiOrgan);
