import React from "react";
import { Route, Routes } from "react-router-dom";

import { ScrollToTop } from "react-router-scroll-to-top";
import Navigation from "./components/utilities/Navigation";
import Home from "./screens/Home";
import Account from "./screens/account/Account";
import Feedback from "./screens/Feedback";
import SearchRouter from "./screens/search/SearchRouter";
import FAQ from "./screens/FAQ";
import DecisionGuideFAQ from "./screens/DecisionGuideFAQ";
import Admin from "./screens/admin/Admin";
import AuthService from "./services/AuthService";

import Login from "./screens/account/Login";
import Footer from "./components/utilities/Footer";
import "./styles/index.css";
import "./styles/utils.css";

const App = () => {
  const handleLogout = () => {
    const Auth = new AuthService();
    Auth.logout();
  };

  return (
    <>
      <ScrollToTop />
      <Navigation onLogout={handleLogout} />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route exact path="login" element={<Login />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/feedback" element={<Feedback />} />
          <Route
            exact
            path="/decision-guide-faq"
            element={<DecisionGuideFAQ />}
          />
          <Route path="/account/*" element={<Account />} />
          <Route path="/search/*" element={<SearchRouter />} />
          <Route path="/admin/*" element={<Admin />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
