import React from "react";
import { useIntl } from "react-intl";

const RecipientsHeader = () => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <div className="custom-search-header">
        <div className="row title">
          <div className="col pr-0">
            {formatMessage({ id: "results.column.recipientCriteria.title" })}
          </div>
        </div>
        <div className="text">
          {formatMessage({ id: "results.column.recipientCriteria.info" })}
        </div>
      </div>
    </div>
  );
};

export default RecipientsHeader;
