import React, { Component } from "react";

import { calculateBMI } from "../../../common/utils/metrics";
import Helper from "../../utilities/Helper";
import { formatTwoDecimals } from "../../utilities/formatter";
import { withIntl } from "../../../common/lang";

class BMI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bmi: "",
    };
  }

  componentDidUpdate = (prv) => {
    if (
      prv.heightFeet !== this.props.heightFeet ||
      prv.heightInches !== this.props.heightInches ||
      prv.weight !== this.props.weight
    ) {
      this.props.setSelected(
        "bmi",
        this.checkBMI(
          this.props.heightFeet,
          this.props.heightInches,
          this.props.weight
        )
      );
    }
  };

  handleHeightFeetChange = (event) => {
    this.props.handleOnChange(event);
  };

  handleHeightInchesChange = (event) => {
    this.props.handleOnChange(event);
  };

  handleWeightChange = (event) => {
    this.props.handleOnChange(event);
  };

  bMIHelper = () => {
    const { formatMessage } = this.props.intl;
    if (
      this.checkBMI(
        this.props.heightFeet,
        this.props.heightInches,
        this.props.weight
      )
    ) {
      return <Helper text={formatMessage({ id: "form.bmi.helper" })} />;
    }
  };

  checkBMI = (heightFeet, heightInches, weight) => {
    const bmi = calculateBMI(heightFeet, heightInches, weight);
    if (bmi >= 30 && this.props.organ === "lung") {
      return true;
    } else if (bmi >= 35 && this.props.organ !== "lung") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className="search-label">
              {formatMessage({ id: "ui.label.height" })}
            </div>
            <input
              className="form-control inline-search-height"
              name="heightFeet"
              onChange={this.handleHeightFeetChange}
              value={this.props.heightFeet}
            />
            {formatMessage({ id: "form.text.ft" })}
            <input
              className="form-control inline-search-height"
              name="heightInches"
              onChange={this.handleHeightInchesChange}
              value={this.props.heightInches}
            />
            {formatMessage({ id: "form.text.in" })}
          </div>
          <div className="col-sm-12 col-md-2 weight">
            <div className="search-label">
              {formatMessage({ id: "ui.label.weight" })}
            </div>
            <input
              className="form-control inline-search-height"
              name="weight"
              onChange={this.handleWeightChange}
              value={this.props.weight}
            />
            {formatMessage({ id: "form.text.lbs" })}
          </div>
          <div className="col-sm-12 col-md-6 bmi">
            <div className="search-label">
              {formatMessage({ id: "form.bmi" })}
            </div>
            <input
              className="form-control inline-search-bmi"
              name="bmi"
              placeholder={formatMessage({ id: "form.bmi.placeholder" })}
              onChange={this.handleBMIChange}
              value={formatTwoDecimals(
                calculateBMI(
                  this.props.heightFeet,
                  this.props.heightInches,
                  this.props.weight
                )
              )}
              readOnly={true}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">{this.bMIHelper()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(BMI);
