import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeedbackService from "../../../services/FeedbackService";

import Loading from "../../utilities/Loading";
import { format } from "date-fns";
import { CSVLink } from "react-csv";

import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../utilities/Table";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("id", {
    header: "Message",
    cell: (info) => {
      const { row } = info;
      return (
        <div className="row mt-4">
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>
                  1) What organ type are you searching for on this site?
                </label>
              </div>
              <div className="display-field" name="organs">
                {row.original.organs}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>
                  2) How useful is the information in the report card in helping
                  you choose a transplant center for you or your family?
                </label>
              </div>
              <div className="display-field" name="choosingTransplantCenter">
                {row.original.choosingTransplantCenter}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>3) Are you a:</label>
              </div>
              <div className="display-field" name="whoAreYou">
                {row.original.whoAreYou}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>
                  4) If you are a patient or a family member/friend of a patient
                  seeking a solid organ transplant, please indicate the wait
                  list status of the patient:
                </label>
              </div>
              <div className="display-field" name="status">
                {row.original.status}
              </div>
            </div>
          </div>
          <div className="col-12 text-left">
            <Link
              to={`/admin/feedback/${info.getValue()}`}
              className="btn btn-purple mr-2"
            >
              View Feedback
            </Link>
            <Link
              to={`/admin/feedback/delete/${info.getValue()}`}
              className="btn btn-secondary"
            >
              Delete
            </Link>
          </div>
        </div>
      );
    },
  }),
  columnHelper.accessor("dateCreated", {
    header: "Date",
    enableSorting: true,
    cell: (info) => {
      let formattedDate;
      try {
        formattedDate = format(new Date(info.getValue()), "MM/dd/yyyy h:mm a");
      } catch (error) {
        formattedDate = "Invalid Date";
      }

      return <div className="cell">{formattedDate}</div>;
    },
    width: 500,
    maxWidth: 200,
  }),
];

const FeedbackList = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const feedbackService = new FeedbackService();
    feedbackService
      .getFeedbacks()
      .then((data) => {
        setIsLoading(false);
        setShowError(false);
        setFeedbacks(data);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setShowError(true);
      });
  }, []);

  const renderPageContent = () => {
    if (isLoading) {
      return <Loading text="Getting feedback..." isLoading={isLoading} />;
    } else if (!isLoading && !showError && feedbacks.length > 0) {
      return (
        <div className="row mt-4">
          <div className="col-12">
            <Table
              data={feedbacks}
              columns={columns}
              resizable={false}
              defaultPageSize={5}
              minRows={1}
              showPagination={feedbacks.length > 5}
              defaultSorted={[
                {
                  id: "dateCreated",
                  desc: true,
                },
              ]}
            />
          </div>
        </div>
      );
    } else if (!isLoading && !showError && feedbacks.length === 0) {
      return (
        <div className="row mt-4">
          <div className="col-12">There is no feedback to display.</div>
        </div>
      );
    } else {
      return (
        <div className="row mt-4">
          <div className="col">
            There was an error retrieving feedback. Please try again.
          </div>
        </div>
      );
    }
  };

  const getExportButton = () => {
    if (feedbacks && feedbacks.length > 0) {
      const headers = [
        {
          label: "What organ type are you searching for on this site",
          key: "organs",
        },
        {
          label:
            "How useful is the information in the report card in helping you choose a transplant center for you or your family?",
          key: "choosingTransplantCenter",
        },
        { label: "Are you a", key: "whoAreYou" },
        {
          label:
            "If you are a patient or a family member/friend of a patient seeking an organ transplant, please indicate the wait list status of the patient:",
          key: "status",
        },
        {
          label:
            "Have you discussed the information shown on this website with a medical provider?",
          key: "discussed",
        },
        {
          label:
            "Will you allow a qualified researcher conducting this study to contact you in the future to ask if you want to provide more information about the usefulness of this website?",
          key: "canContact",
        },
        { label: "Phone Number", key: "phoneNumber" },
        { label: "Email Address", key: "emailAddress" },
        { label: "Other comments", key: "comments" },
        { label: "Date Created", key: "dateCreated" },
      ];

      return (
        <CSVLink
          data={feedbacks}
          headers={headers}
          className="btn btn-purple float-right ml-2"
          filename="feedback.csv"
        >
          Export Feedback List
        </CSVLink>
      );
    }
  };

  return (
    <div className="white-block">
      <Link to="/admin" className="btn btn-secondary float-right ml-2">
        Back
      </Link>
      {getExportButton()}
      <h1>MANAGE FEEDBACK</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../../assets/images/line.png")}
      />
      {renderPageContent()}
    </div>
  );
};

export default FeedbackList;
