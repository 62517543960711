import React from "react";
import { useIntl } from "react-intl";

const FAQs = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="faq-body">
      <h2>{formatMessage({ id: "faq.question1" })}</h2>
      <div className="faq-content">
        {formatMessage({ id: "faq.question1.answer" })}
      </div>

      <h2>{formatMessage({ id: "faq.question2" })}</h2>
      <div className="faq-content">
        <div className="pb-3">
          {formatMessage(
            { id: "faq.question2.answer1" },
            {
              link: (c) => (
                <a
                  href="https://www.srtr.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {c}
                </a>
              ),
            }
          )}
        </div>
        <div className="pb-3">
          {formatMessage({ id: "faq.question2.answer2" })}
        </div>
        <div>{formatMessage({ id: "faq.question2.answer3" })}</div>
      </div>

      <h2>{formatMessage({ id: "faq.question3" })}</h2>
      <div className="faq-content">
        <div className="pb-3"></div>
        <div>{formatMessage({ id: "faq.question3.answer" })}</div>
      </div>

      <h2>{formatMessage({ id: "faq.question4" })}</h2>
      <div className="faq-content">
        <div className="pb-3">
          {formatMessage({ id: "faq.question4.answer1" })}
        </div>
        <div className="pb-3">
          {formatMessage({ id: "faq.question4.answer2" })}
        </div>
        <div>{formatMessage({ id: "faq.question4.answer3" })}</div>
      </div>

      <h2>{formatMessage({ id: "faq.question5" })}</h2>
      <div className="faq-content">
        {formatMessage({ id: "faq.question5.answer" })}
      </div>

      <h2>{formatMessage({ id: "faq.question6" })}</h2>
      <div className="faq-content">
        {formatMessage({ id: "faq.question6.answer" })}
      </div>

      <h2>{formatMessage({ id: "faq.question7" })}</h2>

      <div className="faq-content">
        {formatMessage({ id: "faq.question7.answer" })}{" "}
        <a
          href="https://optn.transplant.hrsa.gov/media/1200/optn_policies.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://optn.transplant.hrsa.gov/media/1200/optn_policies.pdf
        </a>
      </div>

      <h2>{formatMessage({ id: "faq.question8" })}</h2>
      <div className="faq-content">
        {formatMessage({ id: "faq.question8.answer1" })}
      </div>
      <div className="faq-content">
        {formatMessage({ id: "faq.question8.answer2" })}
      </div>
    </div>
  );
};

export default FAQs;
