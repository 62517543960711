import AuthService from './AuthService';
const Host = process.env.REACT_APP_API_HOST;

export default class FeedbackService {
    constructor(domain) {
        this.domain = domain || Host;
        this.Auth = new AuthService();
    }

    async getFeedbacks() {
        return await this.Auth.fetchWithCreds(`${this.domain}/feedback/`, {
            method: 'GET',
        });
    }

    async getFeedback(feedbackId) {
        return await this.Auth.fetchWithCreds(`${this.domain}/feedback/${feedbackId}`, {
            method: 'GET',
        });
    }

    async deleteFeedback(id) 
        {
        return await this.Auth.fetchWithCreds(`${this.domain}/feedback/delete`, {
            method: 'POST',
            body: JSON.stringify({
                id
            })
        });
    }
}