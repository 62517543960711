export const calculateBMI = (heightFeet, heightInches, weight) => {
  const totalHeightInches = +heightFeet * 12 + +heightInches;
  const BMI = (+weight / totalHeightInches / totalHeightInches) * 703 || 0;

  if (typeof BMI === "number" && isFinite(BMI)) {
    return BMI;
  } else {
    return 0;
  }
};
