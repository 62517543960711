import { createColumnHelper } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import Distance from "../../utilities/Distance";
import TransplantsInYear from "../../utilities/TransplantsInYear";

import Rank from "../../utilities/Rank";
import ReceivingADonorOrganHeader from "../../utilities/ReceivingADonorOrganHeader";
import HeaderInfoIcon from "../../utilities/HeaderInfoIcon";
import CustomSearch from "../custom-search/CustomSearch";
import CustomSearchField from "../custom-search/CustomSearchField";
import Loading from "../../utilities/Loading";
import Compare from "../../utilities/Compare";
import RecipientsHeader from "../custom-search/RecipientsHeader";
import DeceasedDonorHeader from "../../utilities/DeceasedDonorHeader";
import Center from "./Center";
import Table from "../../utilities/Table";
import EmailSearchResults from "./EmailSearchResults";

const columnHelper = createColumnHelper();

const SearchResults = (props) => {
  const { formatMessage } = useIntl();
  const [isPrinting, setIsPrinting] = useState();

  const [recipientsCriteriaSearchField, setRecipientsCriteriaSearchField] =
    useState();
  const [
    recipientsCriteriaSearchSelectedValue,
    setRecipientsCriteriaSearchSelectedValue,
  ] = useState({
    value: "",
    label: "Select Criteria",
  });
  const [
    deceasedDonorCriteriaSearchField,
    setDeceasedDonorCriteriaSearchField,
  ] = useState();
  const [
    deceasedDonorCriteriaSearchSelectedValue,
    setDeceasedDonorCriteriaSearchSelectedValue,
  ] = useState({});

  useEffect(() => {
    if (
      props.recipientCriteriaSearchFields &&
      props.recipientCriteriaSearchFields.length > 0
    ) {
      setRecipientsCriteriaSearchField(
        props.recipientCriteriaSearchFields[0].value
      );
      setRecipientsCriteriaSearchSelectedValue(
        props.recipientCriteriaSearchFields[0]
      );
    }

    if (props.deceasedDonorCriteriaSearch) {
      setDeceasedDonorCriteriaSearchField(
        props.deceasedDonorCriteriaSearch[0].value
      );
      setDeceasedDonorCriteriaSearchSelectedValue(
        props.deceasedDonorCriteriaSearch[0]
      );
    }
  }, [props.recipientCriteriaSearchFields, props.deceasedDonorCriteriaSearch]);

  useEffect(() => {
    const beforePrintEventListener = () => {
      setIsPrinting(true);
    };
    const afterPrintEventListener = () => {
      setIsPrinting(false);
    };

    window.addEventListener("beforeprint", beforePrintEventListener);
    window.addEventListener("afterprint", afterPrintEventListener);

    return () => {
      window.removeEventListener("beforeprint", beforePrintEventListener);
      window.removeEventListener("afterprint", afterPrintEventListener);
    };
  }, []);

  const handleRecipientsCriteriaSearch = (customSearchField) => {
    setRecipientsCriteriaSearchField(customSearchField.value);
    setRecipientsCriteriaSearchSelectedValue(customSearchField);
  };

  const handleDeceasedDonorCriteriaSearch = (customSearchField) => {
    setDeceasedDonorCriteriaSearchField(customSearchField.value);
    setDeceasedDonorCriteriaSearchSelectedValue(customSearchField);
  };

  const openPrintWindow = () => {
    window.print();
  };

  const getColumns = () => {
    const basicSearchColumns = [
      columnHelper.group({
        id: "compareSection",
        width: 350,
        header: () => (
          <div>
            <Compare />
          </div>
        ),
        columns: [
          columnHelper.accessor("centerName", {
            header: () => <div />,
            cell: (info) => {
              const { row } = info;
              return (
                <div className="center-name cell">
                  <Center row={row} value={info.getValue()} />
                </div>
              );
            },
            enableSorting: false,
            width: 250,
            maxWidth: 250,
          }),
          columnHelper.accessor("distance", {
            header: () => (
              <div>{formatMessage({ id: "ui.label.distance" })}</div>
            ),
            cell: (info) => (
              <div className="cell">
                <Distance cellValue={info.getValue()} />
              </div>
            ),
          }),
        ],
      }),

      columnHelper.group({
        id: "deceasedDonorSection",
        width: props.organ === "liver" ? 500 : 400,
        styles: {
          flexGrow: props.organ === "liver" ? 500 : 400,
        },
        header: () => <DeceasedDonorHeader organ={props.organ} />,
        columns: [
          columnHelper.accessor(
            props.organ !== "heart" && props.organ !== "lung"
              ? "deceasedDonor"
              : "transplantsInOneYear",
            {
              id: "donorDetails",
              header: () => {
                const headerText =
                  props.organ !== "heart" && props.organ !== "lung"
                    ? "results.column.transplantsInYear.deceased"
                    : "results.column.transplantsInYear";
                return (
                  <div className="u-pre-line">
                    {formatMessage({ id: headerText })}
                  </div>
                );
              },
              cell: (info) => (
                <div className="cell">
                  <TransplantsInYear cellValue={info.getValue()} />
                </div>
              ),
            }
          ),
          columnHelper.accessor("livingDonor", {
            header: () => {
              const headerText = formatMessage({
                id: "results.column.transplantsInYear.living",
              });
              return <div>{headerText}</div>;
            },
            cell: (info) => (
              <div className="cell">
                <TransplantsInYear cellValue={info.getValue()} />
              </div>
            ),
          }),
          columnHelper.accessor("survivalOnWaitlistAdult", {
            header: () => {
              const headerText = formatMessage({
                id: "results.column.survivalWaitList",
              });
              return <div>{headerText}</div>;
            },
            cell: (info) => (
              <div className="cell">
                <Rank cellValue={info.getValue()} />
              </div>
            ),
          }),
          columnHelper.accessor("transplantRateAdult", {
            header: () => {
              const headerText = formatMessage({
                id: "results.column.transplantRate",
              });
              if (props.organ !== "lung") {
                return (
                  <div>
                    <HeaderInfoIcon headerText={headerText} />
                  </div>
                );
              } else {
                return <div>{headerText}</div>;
              }
            },
            cell: (info) => (
              <div className="cell">
                <Rank cellValue={info.getValue()} />
              </div>
            ),
            enableSorting: props.organ !== "lung",
            sortDescFirst: props.organ.toLowerCase() !== "lung",
          }),
          columnHelper.accessor("oneYearOrganSurvivalAdult", {
            header: () => {
              const headerText = formatMessage({
                id: `results.column.survivalAdult.${props.organ}`,
              });
              if (props.organ === "lung") {
                return <HeaderInfoIcon headerText={headerText} />;
              } else {
                return <div className="u-pre-line">{headerText}</div>;
              }
            },
            cell: (info) => (
              <div className="cell">
                <Rank cellValue={info.getValue()} />
              </div>
            ),
            enableSorting: props.organ === "lung",
            sortDescFirst: props.organ === "lung",
          }),
        ],
      }),
    ];

    const nonBasicSearchColumns = [
      columnHelper.group({
        id: "compareSection",
        header: () => <Compare />,
        width: 220,
        columns: [
          columnHelper.accessor("centerName", {
            enableSorting: false,
            header: () => <div />,
            cell: (info) => {
              const { row } = info;
              return (
                <div className="center-name cell">
                  <Center row={row} value={info.getValue()} />
                </div>
              );
            },
          }),

          columnHelper.accessor("distance", {
            header: () => (
              <div>{formatMessage({ id: "ui.label.distance" })}</div>
            ),
            cell: (info) => (
              <div className="cell">
                <Distance cellValue={info.getValue()} />
              </div>
            ),
            width: 120,
            maxWidth: 120,
          }),
        ],
      }),
      // column 2
      columnHelper.group({
        //Getting deceased donor faster
        id: "deceasedDonorSection",
        width: 120,
        maxWidth: 120,
        header: "",
        columns: [
          columnHelper.accessor("transplantRateAdult", {
            header: () => {
              const headerText = formatMessage({
                id: "results.column.transplantRate",
              });
              return (
                <div>
                  <HeaderInfoIcon headerText={headerText} />
                </div>
              );
            },
            cell: (info) => (
              <div className="cell">
                <Rank cellValue={info.getValue()} />
              </div>
            ),
            width: 120,
            maxWidth: 120,
            enableSorting: true,
          }),
          columnHelper.accessor("oneYearOrganSurvivalAdult", {
            header: () => {
              const headerText = formatMessage({
                id: "results.column.survivalAdult.lung",
              });
              return (
                <div>
                  <HeaderInfoIcon headerText={headerText} />
                </div>
              );
            },

            cell: (info) => (
              <div className="cell">
                <Rank cellValue={info.getValue()} />
              </div>
            ),
            enableSorting: props.organ !== "lung",
            sortDescFirst: props.organ.toLowerCase() !== "lung",
          }),
        ],
      }),
      // column 3
      columnHelper.group({
        id: "receivingADonorOrganSection",
        header: () => {
          return <ReceivingADonorOrganHeader organ={props.organ} />;
        },
        width: props.organ === "kidney" || props.organ === "liver" ? 455 : 377,
        maxWidth:
          props.organ === "kidney" || props.organ === "liver" ? 455 : 377,
        columns: [
          columnHelper.accessor("livingDonor", {
            header: () => {
              const headerText = formatMessage({
                id: "results.column.transplantsInYear.living",
              });
              return <div>{headerText}</div>;
            },
            cell: (info) => {
              return (
                <div className="cell">
                  <TransplantsInYear cellValue={info.getValue()} />
                </div>
              );
            },
            width: 140,
            maxWidth: 140,
          }),
          columnHelper.accessor(
            props.organ !== "heart" && props.organ !== "lung"
              ? "deceasedDonor"
              : "transplantsInOneYear",
            {
              id: "donorDetails",
              header: () => {
                const headerText =
                  props.organ !== "heart" && props.organ !== "lung"
                    ? formatMessage({
                        id: "results.column.transplantsInYear.deceased",
                      })
                    : formatMessage({
                        id: "results.column.transplantsInYear.noBreak",
                      });
                return <div>{headerText}</div>;
              },
              cell: (info) => {
                return (
                  <div className="cell">
                    <TransplantsInYear cellValue={info.getValue()} />
                  </div>
                );
              },
              width:
                props.organ === "heart" || props.organ === "lung" ? 140 : 140,
              maxWidth:
                props.organ === "heart" || props.organ === "lung" ? 140 : 140,
            }
          ),
          columnHelper.accessor("customSearchField", {
            header: () => {
              return (
                <div>
                  <CustomSearch
                    otherCustomSearchFields={props.deceasedDonorCriteriaSearch}
                    onCustomSearch={handleDeceasedDonorCriteriaSearch}
                    title={
                      props.organ !== "heart" && props.organ !== "lung"
                        ? formatMessage({ id: "results.column.donorCriteria" })
                        : formatMessage({
                            id: "results.column.donorCriteria.lastTwoYears",
                          })
                    }
                    donorType={props.donorType}
                    selectedValue={deceasedDonorCriteriaSearchSelectedValue}
                  />
                </div>
              );
            },
            cell: ({ row }) => (
              <div id="searchResults" className="cell custom-search-result">
                <CustomSearchField
                  field={deceasedDonorCriteriaSearchField}
                  row={row.original}
                  cssclassName="recipients-criteria deceased-donor-criteria"
                />
              </div>
            ),
            enableSorting: false,
            width:
              props.organ === "heart" || props.organ === "lung" ? 237 : 175,
            maxWidth:
              props.organ === "heart" || props.organ === "lung" ? 237 : 175,
          }),
        ],
      }),
      // column 4
      columnHelper.group({
        id: "recipientsSection",
        headerClassName: "z-100",
        header: () => {
          return (
            <div>
              <RecipientsHeader
                hasParams={props.recipientCriteriaSearchFields.length > 0}
              />
            </div>
          );
        },
        width: props.organ === "heart" || props.organ === "lung" ? 246 : 200,
        maxWidth: props.organ === "heart" || props.organ === "lung" ? 246 : 200,
        columns: [
          columnHelper.accessor("recipientsCriteria", {
            header: () => {
              return (
                <div>
                  <CustomSearch
                    otherCustomSearchFields={props.otherCustomSearchFields}
                    recipientCriteriaSearchFields={
                      props.recipientCriteriaSearchFields
                    }
                    onCustomSearch={handleRecipientsCriteriaSearch}
                    title={
                      props.organ !== "heart" && props.organ !== "lung"
                        ? formatMessage({
                            id: "results.column.recipientCriteria",
                          })
                        : formatMessage({
                            id: "results.column.recipientCriteria.lastTwoYears",
                          })
                    }
                    selectedValue={recipientsCriteriaSearchSelectedValue}
                  />
                </div>
              );
            },
            cell: ({ row }) => (
              <div id="searchResults" className="cell custom-search-result">
                <CustomSearchField
                  field={recipientsCriteriaSearchField}
                  row={row.original}
                  cssclassName="recipients-criteria"
                />
              </div>
            ),
            enableSorting: false,
            width:
              props.organ === "heart" || props.organ === "lung" ? 246 : 200,
            maxWidth:
              props.organ === "heart" || props.organ === "lung" ? 246 : 200,
          }),
        ],
      }),
    ];

    return props.searchType === "basic"
      ? basicSearchColumns
      : nonBasicSearchColumns;
  };

  const getColumnVisibility = () => {
    const basicSearchColumnVisibility = {
      livingDonor: props.organ !== "heart" && props.organ !== "lung",
      survivalOnWaitlistAdult: props.organ !== "kidney",
    };

    const nonBasicSearchColumnVisibility = {
      livingDonor: props.organ !== "heart" && props.organ !== "lung",
      transplantRateAdult: props.organ !== "lung",
      oneYearOrganSurvivalAdult: props.organ === "lung",
    };

    return props.searchType === "basic"
      ? basicSearchColumnVisibility
      : nonBasicSearchColumnVisibility;
  };

  const getDefaultSort = () => {
    if (props.organ.toLowerCase() !== "lung") {
      return [
        {
          id: "transplantRateAdult",
          desc: true,
        },
      ];
    } else {
      return [
        {
          id: "oneYearOrganSurvivalAdult",
          desc: true,
        },
      ];
    }
  };

  const getFooterButtons = () => {
    return (
      <div className="row no-gutters justify-content-end d-print-none mt-2">
        <button
          className="btn btn-no-radius btn-outline-purple-background-white rounded-top mr-lg-1"
          type="button"
          data-toggle="modal"
          data-target="#emailModal"
        >
          <div className="d-flex align-items-center justify-content-around">
            <div className="text-left u-pre-line">
              {formatMessage({ id: "results.button.email" })}
            </div>
            <div className="col-2 btn-right-text">
              <img
                alt="Email search results"
                src={require("../../../assets/images/arrow_purple.png")}
              />
            </div>
          </div>
        </button>

        <button
          className="btn btn-no-radius btn-outline-purple-background-white rounded-top  mr-lg-1"
          type="button"
          onClick={() => openPrintWindow()}
        >
          <div className="d-flex align-items-center justify-content-around">
            <div className="text-left u-pre-line">
              {formatMessage({ id: "results.button.print" })}
            </div>
            <div className="col-2 btn-right-text">
              <img
                alt="Print search results"
                src={require("../../../assets/images/arrow_purple.png")}
              />
            </div>
          </div>
        </button>

        <Link
          className="btn btn-no-radius btn-outline-purple-background-white rounded-top"
          to="/feedback"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="d-flex align-items-center justify-content-around">
            <div className="text-left u-pre-line">
              {formatMessage({ id: "results.button.feedback" })}
            </div>
            <div className="col-2 btn-right-text">
              <img
                alt="Provide feedback for this website"
                src={require("../../../assets/images/arrow_purple.png")}
              />
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const getResults = () => {
    if (props.isLoading) {
      return (
        <div>
          <div className="row">
            <div className="col-12 mt-3">
              <Loading
                text={formatMessage("results.loading")}
                isLoading={props.isLoading}
              />
            </div>
          </div>
        </div>
      );
    } else if (props.results.length > 0) {
      return (
        <div>
          <div className="row">
            <div className="col-12 search-results">
              <Table
                data={props.results}
                columns={getColumns()}
                defaultSorted={getDefaultSort()}
                resizable={false}
                defaultPageSize={isPrinting ? props.results.length : 10}
                showPagination={!isPrinting && props.results.length > 10}
                minRows={4}
                columnVisibility={getColumnVisibility()}
              />
            </div>
          </div>
          {getFooterButtons()}
          <EmailSearchResults />
        </div>
      );
    } else {
      return (
        <div className="row mt-4">{formatMessage({ id: "results.error" })}</div>
      );
    }
  };

  return (
    <div
      className={
        "col-12 pb-2 search-results-container " +
        props.searchType +
        " " +
        props.organ
      }
    >
      {getResults()}
    </div>
  );
};

export default SearchResults;
