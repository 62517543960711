import React from "react";
import { useIntl } from "react-intl";

import DeceasedDonorHeader from "./DeceasedDonorHeader";

const ReceivingADonorOrganHeader = ({ organ }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="receiving-donor-header">
      <div className="icon-text">
        <DeceasedDonorHeader organ={organ} />
      </div>
      <div className="header-text">
        {formatMessage({ id: "search.section.title.receivingADonor" })}
      </div>
    </div>
  );
};

export default ReceivingADonorOrganHeader;
