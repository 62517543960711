import React, { Component } from "react";
import Helper from "../../utilities/Helper";
import { withIntl } from "../../../common/lang";

class HeartRetransplant extends Component {
  componentDidUpdate = (prv) => {
    if (prv.heartRetransplant !== this.props.heartRetransplant) {
      this.props.setSelected(
        "heartRetransplant",
        this.checkPreviousHeartTransplant(this.props.heartRetransplant)
      );
    }
  };

  handlePreviousHeartTransplantInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  previousHeartTransplantHelper = () => {
    const { formatMessage } = this.props.intl;
    if (this.checkPreviousHeartTransplant(this.props.heartRetransplant)) {
      return (
        <Helper
          text={formatMessage({ id: "form.previousHeartTransplant.helper" })}
        />
      );
    }
  };

  checkPreviousHeartTransplant = (heartRetransplant) => {
    if (heartRetransplant === "yes") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.previousHeartTransplant" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select search-select-md"
              name="heartRetransplant"
              onChange={this.handlePreviousHeartTransplantInputChange}
              value={this.props.heartRetransplant}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="no">{formatMessage({ id: "form.no" })}</option>
              <option value="yes">{formatMessage({ id: "form.yes" })}</option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">
              {this.previousHeartTransplantHelper()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(HeartRetransplant);
