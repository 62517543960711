import React from "react";

const HeaderInfoIcon = (props) => {
  return (
    <div className="header-info-icon">
      <div className="text">
        <img
          alt="Information"
          className="img-fluid info-header"
          src={require("../../assets/images/i.png")}
        />
        {props.description}
      </div>
      <div className="header u-pre-line">{props.headerText}</div>
    </div>
  );
};

export default HeaderInfoIcon;
