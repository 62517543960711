import React from "react";
import { useIntl } from "react-intl";

import Check from "../../utilities/Check";

function OtherFactors(props) {
  const { formatMessage } = useIntl();
  const otherFactor =
    props.organ === "lung"
      ? "search.otherFactors.description2.lung"
      : "search.otherFactors.description2";

  return (
    <div className="row no-gutters highlight">
      <div className="col-sm-12 col-md-2 left-column border-right-orange ">
        <div className="left-column-inner d-flex align-items-center h-100 ">
          {formatMessage({ id: "search.otherFactors.title" })}
        </div>
      </div>
      <div className="col-sm-12 col-md-2 middle-column border-right-orange">
        <div className="middle-column-inner d-flex flex-column justify-content-center align-items-center h-100">
          <div className="check">
            <Check />
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-8 right-column">
        <div className="right-column-inner">
          <ul className=" d-flex flex-column justify-content-center h-100">
            <li>{formatMessage({ id: "search.otherFactors.description" })}</li>
            <li>{formatMessage({ id: otherFactor })}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OtherFactors;
