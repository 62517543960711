import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";

import SearchService from "../../../services/SearchService";
import { getCustomSearchHelpers } from "../custom-search/CustomSearchHelper";

import Distance from "../inputs/Distance";
import Age from "../inputs/Age";
import BMI from "../inputs/BMI";
import CauseOfLungDisease from "../inputs/CauseOfLungDisease";
import InsuranceProvider from "../inputs/InsuranceProvider";
import HIVPositive from "../inputs/HIVPositive";
import HepCPositiveLung from "../inputs/HepCPositiveLung";
import MultiOrgan from "../inputs/MultiOrgan";
import PreviousLungTransplant from "../inputs/PreviousLungTransplant";

import DCDDescriptionLung from "../../utilities/DCDDescriptionLung";
import IncreasedInfectiousRiskDescriptionLung from "../../utilities/IncreasedInfectiousRiskDescriptionLung";
import HepatiticCPositiveDescription from "../../utilities/HepatiticCPositiveDescription";
import Perfused from "../../utilities/Perfused";
import EmailMedicalProfile from "./EmailMedicalProfile";
import { setDistanceMiles, setOrgan } from "../../../actions";
import { store } from "../../../store";
import { getAllSearchParams } from "../../../common/utils/searchParams";

const LungProfile = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [searchParams] = useSearchParams();
  const [isValidUrl, setIsValidUrl] = useState(true);
  const navigate = useNavigate();

  const customSearchHelper = getCustomSearchHelpers(intl);

  const [form, setForm] = useState({
    organ: "lung",
    distanceZipCode: "",
    distanceState: "",
    age: "",
    ageSelected: false,
    heightFeet: "",
    heightInches: "",
    weight: "",
    bmiSelected: false,
    causeOfLungDisease: "",
    causeOfLungDiseaseSelected: false,
    insuranceProvider: "",
    insuranceProviderSelected: false,
    donorType: "",
    hivPositive: "",
    hivPositiveSelected: false,
    hepCPositiveLung: "",
    hepCPositiveLungSelected: false,
    multiOrgan: "",
    multiOrganSelected: false,
    previousLungTransplant: "",
    previousLungTransplantSelected: false,
    isValidUrl: true,
  });

  useEffect(() => {
    const values = getAllSearchParams(searchParams);
    const searchService = new SearchService();

    if (values.donorType) {
      const distanceMiles =
        typeof values.distanceMiles !== "undefined"
          ? values.distanceMiles
          : searchService.getDistanceMiles();
      const distanceZipCode =
        typeof values.distanceZipCode !== "undefined"
          ? values.distanceZipCode
          : searchService.getSearchParameter("distanceZipCode");
      const distanceState =
        typeof values.distanceState !== "undefined"
          ? values.distanceState
          : searchService.getSearchParameter("distanceState");
      const age =
        typeof values.age !== "undefined"
          ? values.age
          : searchService.getSearchParameter("age");
      const weight =
        typeof values.weight !== "undefined"
          ? values.weight
          : searchService.getSearchParameter("weight");
      const heightFeet =
        typeof values.heightFeet !== "undefined"
          ? values.heightFeet
          : searchService.getSearchParameter("heightFeet");
      const heightInches =
        typeof values.heightInches !== "undefined"
          ? values.heightInches
          : searchService.getSearchParameter("heightInches");
      const causeOfLungDisease =
        typeof values.causeOfLungDisease !== "undefined"
          ? values.causeOfLungDisease
          : searchService.getSearchParameter("causeOfLungDisease");
      const insuranceProvider =
        typeof values.insuranceProvider !== "undefined"
          ? values.insuranceProvider
          : searchService.getSearchParameter("insuranceProvider");
      const donorType =
        typeof values.donorType !== "undefined"
          ? values.donorType
          : searchService.getSearchParameter("donorType");
      const hivPositive =
        typeof values.hivPositive !== "undefined"
          ? values.hivPositive
          : searchService.getSearchParameter("hivPositive");
      const hepCPositiveLung =
        typeof values.hepCPositiveLung !== "undefined"
          ? values.hepCPositiveLung
          : searchService.getSearchParameter("hepCPositiveLung");
      const multiOrgan =
        typeof values.multiOrgan !== "undefined"
          ? values.multiOrgan
          : searchService.getSearchParameter("multiOrgan");
      const previousLungTransplant =
        typeof values.previousLungTransplant !== "undefined"
          ? values.previousLungTransplant
          : searchService.getSearchParameter("previousLungTransplant");

      if (!props.distanceMiles) {
        store.dispatch(setDistanceMiles(distanceMiles));
      }

      if (!props.organ) {
        store.dispatch(setOrgan("lung", formatMessage({ id: "organs.lung" })));
      }

      setForm((prevFormValue) => ({
        ...prevFormValue,
        distanceZipCode,
        distanceState,
        age,
        weight,
        heightFeet,
        heightInches,
        causeOfLungDisease,
        insuranceProvider,
        donorType,
        hivPositive,
        hepCPositiveLung,
        multiOrgan,
        previousLungTransplant,
      }));
    } else {
      setIsValidUrl(false);
    }
  }, [searchParams, props.distanceMiles, props.organ, formatMessage]);

  const getHighlightedFields = () => {
    let recipientCriteriaSearchFields = [];

    if (form.ageSelected) {
      recipientCriteriaSearchFields.push(
        customSearchHelper.PatientsOverAgeSeventy
      );
    }

    if (form.bmiSelected) {
      recipientCriteriaSearchFields.push(customSearchHelper.BMIOverThirty);
    }

    if (form.insuranceProviderSelected) {
      recipientCriteriaSearchFields.push(customSearchHelper.InsuranceMedicaid);
    }

    if (form.hivPositiveSelected) {
      recipientCriteriaSearchFields.push(customSearchHelper.HIVPositiveDonor);
      recipientCriteriaSearchFields.push(
        customSearchHelper.HIVPositiveRecipient
      );
    }

    if (form.hepCPositiveLungSelected && form.hepCPositiveLung !== "no") {
      recipientCriteriaSearchFields.push(customSearchHelper.HepCPositive);
    }

    if (form.multiOrganSelected) {
      recipientCriteriaSearchFields.push(customSearchHelper.MultiOrgan);
    }

    if (form.previousLungTransplantSelected) {
      recipientCriteriaSearchFields.push(customSearchHelper.LungRetransplant);
    }

    return recipientCriteriaSearchFields;
  };

  const doSearch = () => {
    const params = getHighlightedFields()
      .map(function (field) {
        return field.value;
      })
      .join(",");

    let mainUrl = `/search?organ=${props.organ}`;
    mainUrl += `&distanceZipCode=${form.distanceZipCode}`;
    mainUrl += `&distanceMiles=${props.distanceMiles}`;
    mainUrl += `&distanceState=${form.distanceState}`;
    mainUrl += `&donorType=${form.donorType}`;
    mainUrl += `&showAll=true`;

    navigate(`${mainUrl}&params=${params}`);
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevFormValue) => ({
      ...prevFormValue,
      [name]: value,
    }));

    const searchService = new SearchService();
    searchService.setSearchParameter(name, value);
    if (name === "distanceMiles") {
      store.dispatch(setDistanceMiles(value));
    }
  };

  const setSelected = (name, selected) => {
    name = name + "Selected";
    setForm((prevFormValue) => ({
      ...prevFormValue,
      [name]: selected,
    }));
  };

  const print = () => {
    window.print();
  };

  const getDonorType = () => {
    if (form.donorType === "both") {
      return formatMessage({ id: "donorTypes.both" });
    } else if (form.donorType === "living") {
      return formatMessage({ id: "donorTypes.living" });
    } else {
      return formatMessage({ id: "donorTypes.deceased" });
    }
  };

  const getUrl = () => {
    var location = window.location;
    var path = location.pathname;
    var url = `${location.protocol}//${
      location.hostname + (location.port ? ":" + location.port : "")
    }${path}`;

    url += `?distanceMiles=${props.distanceMiles}`;
    url += `&distanceZipCode=${form.distanceZipCode}`;
    url += `&distanceState=${form.distanceState}`;
    url += `&age=${form.age}`;
    url += `&weight=${form.weight}`;
    url += `&heightFeet=${form.heightFeet}`;
    url += `&heightInches=${form.heightInches}`;
    url += `&causeOfLungDisease=${form.causeOfLungDisease}`;
    url += `&insuranceProvider=${form.insuranceProvider}`;
    url += `&donorType=${form.donorType}`;
    url += `&hivPositive=${form.hivPositive}`;
    url += `&hepCPositiveLung=${form.hepCPositiveLung}`;
    url += `&multiOrgan=${form.multiOrgan}`;
    url += `&previousLungTransplant=${form.previousLungTransplant}`;

    return url;
  };

  if (isValidUrl) {
    return (
      <div className="inner-container">
        <div className="white-block rounded content-body">
          <div className="row">
            <div className="col-12">
              <h1>{formatMessage({ id: "medicalProfile.title" })}</h1>
              <div className="pb-2">
                {formatMessage({ id: "medicalProfile.subtitle" })}
              </div>
              <div className="pb-1">
                <Link to="/faq" target="_blank" rel="noopener noreferrer">
                  {formatMessage({ id: "medicalProfile.link.why" })}
                </Link>
              </div>
              <img
                alt="Line separator"
                className="img-fluid line"
                src={require("../../../assets/images/line.png")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <Distance
                  distanceMiles={props.distanceMiles}
                  distanceZipCode={form.distanceZipCode}
                  distanceState={form.distanceState}
                  handleOnChange={handleOnChange}
                />
                <Age
                  age={form.age}
                  organ={props.organ}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <BMI
                  organ={props.organ}
                  heightFeet={form.heightFeet}
                  heightInches={form.heightInches}
                  weight={form.weight}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <CauseOfLungDisease
                  causeOfLungDisease={form.causeOfLungDisease}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <InsuranceProvider
                  insuranceProvider={form.insuranceProvider}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <HIVPositive
                  hivPositive={form.hivPositive}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <HepCPositiveLung
                  hepCPositive={form.hepCPositiveLung}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <MultiOrgan
                  multiOrgan={form.multiOrgan}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <PreviousLungTransplant
                  previousLungTransplant={form.previousLungTransplant}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <img
                    alt="Line separator"
                    className="img-fluid line"
                    src={require("../../../assets/images/line.png")}
                  />
                </div>
                <div className="col-12">
                  <div className="search-label">
                    {formatMessage({ id: "ui.label.donorType" })}:{" "}
                    {getDonorType()}
                  </div>
                </div>
                <div className="col-12">
                  {formatMessage({ id: "donorTypes.text.lung" })}
                </div>
                <div className="col-12">
                  <div className="helper-text">
                    <div className="jumbotron">
                      <img
                        alt="Information"
                        className="img-fluid float-left pr-2 help"
                        src={require("../../../assets/images/i.png")}
                      />
                      <DCDDescriptionLung />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="helper-text">
                    <div className="jumbotron">
                      <img
                        alt="Information"
                        className="img-fluid float-left pr-2 help"
                        src={require("../../../assets/images/i.png")}
                      />
                      <IncreasedInfectiousRiskDescriptionLung />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="helper-text">
                    <div className="jumbotron">
                      <img
                        alt="Information"
                        className="img-fluid float-left pr-2 help"
                        src={require("../../../assets/images/i.png")}
                      />
                      <HepatiticCPositiveDescription />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="helper-text">
                    <div className="jumbotron">
                      <img
                        alt="Information"
                        className="img-fluid float-left pr-2 help"
                        src={require("../../../assets/images/i.png")}
                      />
                      <Perfused />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters justify-content-end d-print-none mt-3">
                <button
                  className="btn btn-no-radius btn-outline-purple rounded-top w-190"
                  type="button"
                  onClick={() => doSearch()}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-left u-pre-line">
                      {formatMessage({
                        id: "medicalProfile.button.decisionGuide",
                      })}
                    </div>
                    <div className="col-2 btn-right-text">
                      <img
                        alt="Search Transplant Centers"
                        src={require("../../../assets/images/arrow.png")}
                      />
                    </div>
                  </div>
                </button>
              </div>
              <div className="row no-gutters justify-content-end d-print-none mt-2">
                <button
                  className="btn btn-no-radius btn-outline-purple-background-white rounded-top mr-lg-1"
                  type="button"
                  data-toggle="modal"
                  data-target="#emailModal"
                >
                  <div className=" d-flex align-items-center justify-content-between">
                    <div className="text-left u-pre-line">
                      {formatMessage({ id: "medicalProfile.button.email" })}
                    </div>
                    <div className="col-2 btn-right-text">
                      <img
                        alt="Search Transplant Centers"
                        src={require("../../../assets/images/arrow_purple.png")}
                      />
                    </div>
                  </div>
                </button>

                <button
                  className="btn btn-no-radius btn-outline-purple-background-white rounded-top"
                  type="button"
                  onClick={() => print()}
                >
                  <div className=" d-flex align-items-center justify-content-between">
                    <div className="text-left u-pre-line">
                      {formatMessage({ id: "medicalProfile.button.print" })}
                    </div>
                    <div className="col-2 btn-right-text">
                      <img
                        alt="Search Transplant Centers"
                        src={require("../../../assets/images/arrow_purple.png")}
                      />
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <EmailMedicalProfile url={getUrl()} />
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
};

function mapStateToProps(state) {
  return {
    distanceMiles: state.distanceMiles,
    organ: state.organ,
  };
}

export default connect(mapStateToProps)(LungProfile);
