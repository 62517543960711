import React from "react";

const EmptyRow = (props) => {
  return (
    <div className="row no-gutters empty-row">
      <div className="col-sm-4 col-lg-2 left-column border-right-orange ">
        <div className="empty-row h-100">{props.text}</div>
      </div>
      <div className="col-sm-4 col-lg-2 middle-column border-right-orange">
        <div className="empty-row h-100"></div>
      </div>
      <div className="col-sm-4 col-lg-8 right-column empty-row"></div>
    </div>
  );
};

export default EmptyRow;
