import React from "react";
import { useIntl } from "react-intl";

const DeceasedDonorHeader = ({ organ }) => {
  const { formatMessage } = useIntl();
  const getHeader = () => {
    if (organ === "lung") {
      return (
        <div className="deceased-donor-bubble">
          <img
            alt="Information"
            className="img-fluid info-header"
            src={require("../../assets/images/i.png")}
          />
          <div className="text">
            {formatMessage({ id: "search.deceasedDonorHeader.lung" })}
          </div>
        </div>
      );
    } else {
      return (
        <div className="deceased-donor-bubble">
          <img
            alt="Information"
            className="img-fluid info-header"
            src={require("../../assets/images/i.png")}
          />
          <div className="text">
            {formatMessage({ id: `search.deceasedDonorHeader.${organ}` })}
          </div>
        </div>
      );
    }
  };

  return <div>{getHeader()}</div>;
};

export default DeceasedDonorHeader;
