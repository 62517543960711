import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { object, string } from "yup";

import { renderFormError } from "../../common/utils/form";
import AuthService from "../../services/AuthService";
import Loading from "../utilities/Loading";

const loginSchema = object({
  username: string().required("User Name is required"),
  password: string().required("Password is required"),
});

const UserLogin = ({ signInText, size }) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authService = new AuthService();
    if (authService.loggedIn()) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validateOnMount: true,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      try {
        const authService = new AuthService();
        const loginResponse = await authService.login(
          values.username,
          values.password
        );
        setIsLoading(false);

        if (loginResponse === true) {
          navigate("/admin");
        } else {
          setError(
            "Unable to log you in. Please check your username and password then try again."
          );
        }
      } catch (e) {
        console.error(e);
        setError(
          "Unable to log you in. Please check your username and password then try again."
        );
      }
    },
  });

  const getLoginError = () => {
    if (error !== "") {
      return <div className="text-danger mb-3">{error}</div>;
    }
  };

  const getRender = () => {
    if (isLoading) {
      return <Loading text="Logging in..." isLoading={isLoading} />;
    } else {
      return (
        <form onSubmit={formik.handleSubmit}>
          {getLoginError()}
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12 col-md-6 form-group">
              <input
                autoFocus
                type="email"
                className="form-control"
                id="username"
                placeholder="User Name"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {renderFormError(formik, "username")}
            </div>
            <div className="col-xs-12 col-md-6 form-group">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {renderFormError(formik, "password")}
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-purple"
                disabled={!formik.isValid}
              >
                SUBMIT
              </button>
            </div>
            <div className="col-12 mt-2">
              <Link to="/account/forgotpassword">Forgot your password?</Link>
            </div>
          </div>
        </form>
      );
    }
  };

  if (size === "small") {
    return (
      <div className="dropdown">
        <button
          className="btn btn-purple dropdown-toggle"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {signInText}
        </button>
        <div className="dropdown-menu grey-background dropdown-menu-right w-200">
          <form className="px-4" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <input
                autoFocus
                type="email"
                className="form-control drop-down-input"
                id="username"
                placeholder="User Name"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control drop-down-input"
                id="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <button
              type="submit"
              className="btn btn-purple w-100"
              disabled={!formik.isValid}
            >
              SUBMIT
            </button>
          </form>
          <div className="dropdown-divider"></div>
        </div>
      </div>
    );
  } else {
    return getRender();
  }
};

export default UserLogin;
