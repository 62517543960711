import React, { Component } from "react";
import Helper from "../../utilities/Helper";
import { withIntl } from "../../../common/lang";

class BloodType extends Component {
  componentDidUpdate = (prv) => {
    if (prv.bloodType !== this.props.bloodType) {
      this.props.setSelected(
        "bloodType",
        this.checkBloodType(this.props.bloodType)
      );
    }
  };

  handleBloodTypeInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  bloodTypeHelper = () => {
    const { formatMessage } = this.props.intl;
    if (this.checkBloodType(this.props.bloodType)) {
      return <Helper text={formatMessage({ id: "form.bloodType.helper" })} />;
    }
  };

  checkBloodType = (bloodType) => {
    if (bloodType === "b") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.bloodType" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select search-select-md"
              name="bloodType"
              onChange={this.handleBloodTypeInputChange}
              value={this.props.bloodType}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="o">
                {formatMessage({ id: "form.bloodType.option.o" })}
              </option>
              <option value="a">
                {formatMessage({ id: "form.bloodType.option.a" })}
              </option>
              <option value="b">
                {formatMessage({ id: "form.bloodType.option.b" })}
              </option>
              <option value="ab">
                {formatMessage({ id: "form.bloodType.option.ab" })}
              </option>
              <option value="notsure">
                {formatMessage({ id: "form.notSure" })}
              </option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">{this.bloodTypeHelper()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(BloodType);
