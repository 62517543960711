import { createStore } from "redux";
import reducer from "../reducers";

const initialState = {
  organ: "",
  organDisplay: "",
  distanceMiles: "",
  organData: [],
};

export const store = createStore(reducer, initialState);
