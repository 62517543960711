import AuthService from './AuthService';
const Host = process.env.REACT_APP_API_HOST;

export default class CompanyService {
    constructor(domain) {
        this.domain = domain || Host;
        this.Auth = new AuthService();
    }

    async getCompanies() {
        return await this.Auth.fetchWithCreds(`${this.domain}/companies/`, {
            method: 'GET',
        });
    }

    async getCompany(companyId) {
        return await this.Auth.fetchWithCreds(`${this.domain}/companies/${companyId}`, {
            method: 'GET',
        });
    }

    async addCompany(
        name,
        address1,
        address2,
        city,
        state,
        zipCode,
        phoneNumber,
        url) 
        {
        return await this.Auth.fetchWithCreds(`${this.domain}/companies/add`, {
            method: 'POST',
            body: JSON.stringify({
                name,
                address1,
                address2,
                city,
                state,
                zipCode,
                phoneNumber,
                url
            })
        });
    }

    async updateCompany(
        id, 
        name,
        address1,
        address2,
        city,
        state,
        zipCode,
        phoneNumber,
        url) 
        {
        return await this.Auth.fetchWithCreds(`${this.domain}/companies/update`, {
            method: 'POST',
            body: JSON.stringify({
                id,
                name,
                address1,
                address2,
                city,
                state,
                zipCode,
                phoneNumber,
                url
            })
        });
    }

    async deleteCompany(id) 
        {
        return await this.Auth.fetchWithCreds(`${this.domain}/companies/delete`, {
            method: 'POST',
            body: JSON.stringify({
                id
            })
        });
    }
}