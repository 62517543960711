import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import FeedbackService from "../../../services/FeedbackService";
import Loading from "../../utilities/Loading";

const DeleteFeedback = () => {
  const navigate = useNavigate();
  const { id: feedbackId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [showError, setShowError] = useState(false);
  const [feedback, setFeedback] = useState({});

  useEffect(() => {
    setIsLoading(true);
    setLoadingText("Getting feedback...");

    const feedbackService = new FeedbackService();
    feedbackService
      .getFeedback(feedbackId)
      .then((data) => {
        setIsLoading(false);
        setShowError(false);
        setFeedback({
          organs: data.organs,
          choosingTransplantCenter: data.choosingTransplantCenter,
          whoAreYou: data.whoAreYou,
          status: data.status,
          dateCreated: data.dateCreated,
          email: data.emailAddress,
          phoneNumber: data.phoneNumber,
          comments: data.comments,
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setShowError(true);
      });
  }, [feedbackId]);

  const handleDeleteClick = () => {
    setIsLoading(true);
    setLoadingText("Deleting feedback...");

    const feedbackService = new FeedbackService();
    feedbackService.deleteFeedback(feedbackId).then(() => {
      navigate("/admin/feedback");
    });
  };

  const renderPageContent = () => {
    if (isLoading) {
      return <Loading text={loadingText} isLoading={isLoading} />;
    } else if (!isLoading && !showError) {
      return (
        <div className="row mt-4">
          <div className="col">
            <p>Are you sure you want to delete this feedback?</p>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>
                  1) What organ type are you searching for on this site?
                </label>
              </div>
              <div className="display-field" name="choosingTransplantCenter">
                {feedback.organs}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>
                  2) How useful is the information in the report card in helping
                  you choose a transplant center for you or your family?
                </label>
              </div>
              <div className="display-field" name="choosingTransplantCenter">
                {feedback.choosingTransplantCenter}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>3) Are you a:</label>
              </div>
              <div className="display-field" name="whoAreYou">
                {feedback.whoAreYou}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>
                  4) If you are a patient or a family member/friend of a patient
                  seeking a solid organ transplant, please indicate the wait
                  list status of the patient:
                </label>
              </div>
              <div className="display-field" name="status">
                {feedback.status}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>Phone Number:</label>
              </div>
              <div className="display-field" name="phoneNumber">
                {feedback.phoneNumber}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>Email Address:</label>
              </div>
              <div className="display-field" name="email">
                {feedback.email}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div>
                <label>Comments:</label>
              </div>
              <div className="display-field" name="comments">
                {feedback.comments}
              </div>
            </div>
          </div>
          <div className="col">
            <Link
              to="/admin/feedback"
              className="btn btn-secondary float-right ml-2"
            >
              Cancel
            </Link>
            <button
              className="btn btn-purple float-right"
              onClick={handleDeleteClick}
            >
              Delete Feedback
            </button>
          </div>
        </div>
      );
    } else if (!isLoading && showError) {
      return (
        <div className="row mt-4">
          <div className="col">
            There was an error retrieving this feedback. Please try again.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="jumbotron bg-white content-body">
      <h1>DELETE FEEDBACK</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../../assets/images/line.png")}
      />
      {renderPageContent()}
    </div>
  );
};

export default DeleteFeedback;
