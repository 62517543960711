import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { object, string } from "yup";

import { renderFormError } from "../../../common/utils/form";
import UserService from "../../../services/UserService";
import Loading from "../../utilities/Loading";

const editUserSchema = object({
  firstName: string().required("First Name is required"),
  lastName: string().required("Last Name is required"),
  email: string()
    .email("Email address must be a valid email")
    .required("Email address is required"),
});

const userService = new UserService();

const EditUser = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    setIsLoading(true);
    setLoadingText("Getting user...");

    userService
      .getUser(userId)
      .then((data) => {
        setIsLoading(false);
        setShowError(false);

        setUser({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          companyName: data.companyName,
          isActive: data.isActive,
          isAdmin: data.isAdmin,
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setShowError(true);
      });
  }, [userId]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: editUserSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      setLoadingText("Updating user...");

      userService
        .updateUser(
          userId,
          values.firstName,
          values.lastName,
          values.email,
          user.isActive
        )
        .then((data) => {
          if (data.status === "succeeded") {
            setIsLoading(false);
            setShowError(false);
          } else if (data.status === "failed") {
            setIsLoading(false);
            setErrors(data.body);
          }

          navigate("/admin/users");
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          setShowError(true);
        });
    },
  });

  useEffect(() => {
    if (!Object.values(user).length) {
      return;
    }

    formik.setFieldValue("firstName", user.firstName);
    formik.setFieldValue("lastName", user.lastName);
    formik.setFieldValue("email", user.email);

    // eslint-disable-next-line
  }, [user]);

  const renderErrors = () => {
    return errors.map((error, index) => (
      <div className="text-danger pb-2" key={index}>
        {error.description}
      </div>
    ));
  };

  const displayCompany = () => {
    if (!user.isAdmin) {
      return (
        <div className="form-group">
          <label>Company Name</label>
          <br />
          {user.companyName}
        </div>
      );
    }
  };

  const renderPageContent = () => {
    if (isLoading) {
      return <Loading text={loadingText} isLoading={isLoading} />;
    } else if (!isLoading && !showError) {
      return (
        <div className="row mt-4">
          <div className="col-12">{renderErrors()}</div>
          <div className="col-12">
            <form>
              {displayCompany()}
              <div className="form-group">
                <label htmlFor="first-name">First Name</label>
                <input
                  id="first-name"
                  className="form-control"
                  name="firstName"
                  placeholder="First Name"
                  value={formik.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                />
                {renderFormError(formik, "firstName")}
              </div>
              <div className="form-group">
                <label htmlFor="last-name">Last Name</label>
                <input
                  id="last-name"
                  className="form-control"
                  name="lastName"
                  placeholder="Last Name"
                  value={formik.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                />
                {renderFormError(formik, "lastName")}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="name@example.com"
                  value={formik.email}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                />
                {renderFormError(formik, "email")}
              </div>
            </form>
          </div>
          <div className="col">
            <Link to="/admin" className="btn btn-secondary float-right ml-2">
              Cancel
            </Link>
            <button
              className="btn btn-purple float-right"
              onClick={formik.handleSubmit}
            >
              Update User
            </button>
          </div>
        </div>
      );
    } else if (!isLoading && showError) {
      return (
        <div className="row mt-4">
          <div className="col">
            There was an error retrieving this user. Please try again.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="jumbotron bg-white content-body">
      <h1>EDIT USER</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../../assets/images/line.png")}
      />
      {renderPageContent()}
    </div>
  );
};

export default EditUser;
