import React from "react";

const Helper = ({ text }) => {
  return (
    <div className="jumbotron">
      <img
        alt="Information"
        className="img-fluid float-left pr-2 help"
        src={require("../../assets/images/i.png")}
      />
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  );
};

export default Helper;
