import React, { Component } from "react";
import { withIntl } from "../../../common/lang";

class CauseOfLungDisease extends Component {
  componentDidUpdate = (prv) => {
    if (prv.causeOfLungDisease !== this.props.causeOfLungDisease) {
      this.props.setSelected(
        "causeOfLungDisease",
        this.checkCauseOfLungDisease(this.props.causeOfLungDisease)
      );
    }
  };

  handleLungDiseaseInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  causeOfLungDiseaseHelper = () => {
    if (this.checkCauseOfLungDisease(this.props.causeOfLungDisease)) {
      return <div></div>;
    }
  };

  checkCauseOfLungDisease(causeOfLungDisease) {
    //TODO: Not implemented
    return false;
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.causeOfLungDisease" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select-lg"
              name="causeOfLungDisease"
              onChange={this.handleLungDiseaseInputChange}
              value={this.props.causeOfLungDisease}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="copd">
                {formatMessage({ id: "form.option.copd" })}
              </option>
              <option value="cysticFibrosis">
                {formatMessage({ id: "form.option.cysticFibrosis" })}
              </option>
              <option value="interstitialLungDisease">
                {formatMessage({ id: "form.option.interstitialLungDisease" })}
              </option>
              <option value="pulmonaryHypertension">
                {formatMessage({ id: "form.option.pulmonaryHypertension" })}
              </option>
              <option value="other">
                {formatMessage({ id: "form.other" })}
              </option>
              <option value="dontKnow">
                {formatMessage({ id: "form.dontKnow" })}
              </option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">{this.causeOfLungDiseaseHelper()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(CauseOfLungDisease);
