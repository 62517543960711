import { useIntl } from "react-intl";

export const Pagination = ({ table }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="pagination-bottom d-print-none">
      <div className="-pagination">
        <div className="-previous">
          <button
            type="button"
            className="-btn"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {formatMessage({ id: "table.pagination.button.previous" })}
          </button>
        </div>
        <div className="-center">
          <span className="-pageInfo">
            {formatMessage({ id: "table.pagination.text.page" })}{" "}
            <div className="-pageJump">
              <input
                aria-label="jump to page"
                type="number"
                value={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const pageSize = table.getState().pagination.pageSize;

                  let updatedPage;
                  if (e.target.value) {
                    const nextSelectedPage = Number(e.target.value);
                    const newPage =
                      nextSelectedPage <= pageSize
                        ? nextSelectedPage
                        : pageSize;
                    updatedPage = newPage - 1;
                  } else {
                    updatedPage = 0;
                  }
                  table.setPageIndex(updatedPage);
                }}
              />
            </div>{" "}
            {formatMessage({ id: "table.pagination.text.of" })}{" "}
            <span className="-totalPages">{table.getPageCount()}</span>
          </span>
          <span className="select-wrap -pageSizeOptions">
            <select
              aria-label="rows per page"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {formatMessage(
                    { id: "table.pagination.option.rows" },
                    { pageSize }
                  )}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="-next">
          <button
            type="button"
            className="-btn"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {formatMessage({ id: "table.pagination.button.next" })}
          </button>
        </div>
      </div>
    </div>
  );
};
