import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { object, ref, string } from "yup";

import AccountService from "../../services/AccountService";
import AuthService from "../../services/AuthService";
import Loading from "../utilities/Loading";

const resetPasswordSchema = object({
  email: string().email().required("Email  is required"),
  password: string().required("Password is required"),
  confirmPassword: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [userDetails, setUserDetails] = useState({ userId: null, code: null });

  useEffect(() => {
    const authService = new AuthService();
    if (authService.loggedIn()) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    const userId = searchParams.get("userId");
    const code = searchParams.get("code");

    setUserDetails({
      userId,
      code,
    });
  }, [searchParams]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validateOnMount: true,
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      setIsLoading(true);

      const accountService = new AccountService();
      accountService
        .resetPassword(
          userDetails.userId,
          values.email,
          userDetails.code,
          values.password,
          values.confirmPassword
        )
        .then((data) => {
          if (data.status === "succeeded") {
            setIsLoading(false);
            setError("");
            setShowError(false);
            setResetSuccess(true);
          } else if (data.status === "failed") {
            setIsLoading(false);
            setShowError(true);
            setError(
              "We are unable to reset your password. Please check your password meets all the requirements and try again."
            );
            setResetSuccess(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          setShowError(true);
          setError(
            "We are unable to reset your password. Please check your password meets all the requirements and try again."
          );
          setResetSuccess(false);
        });
    },
  });

  const handleLogin = () => {
    navigate("/login", { replace: true });
  };

  const renderErrorMessage = () => {
    if (error !== "") {
      return <div className="col-12 text-danger mb-2">{error}</div>;
    }
  };

  const renderPageContent = () => {
    if (isLoading) {
      return (
        <Loading text="Resetting your password..." isLoading={isLoading} />
      );
    } else if (!isLoading && !showError && !resetSuccess) {
      return (
        <div className="row mt-4">
          <div className="col-xs-12 col-md-6">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-12 pb-2">
                  <div>
                    Please fill out the below form to reset your password.
                  </div>
                </div>
                {renderErrorMessage()}
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                      id="email"
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      value={formik.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      id="password"
                      className="form-control"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={formik.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input
                      id="confirm-password"
                      className="form-control"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={formik.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="col">
                  <button
                    className="btn btn-purple float-right"
                    type="submit"
                    disabled={!formik.isValid}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="col">
              <span>
                <div>
                  Passwords requirements:
                  <ul>
                    <li>At least 8 characters</li>
                    <li>Contain at least one digit</li>
                    <li>Contain at least one special character(! @ % #)</li>
                    <li>Contain at least four unique characters</li>
                  </ul>
                </div>
              </span>
            </div>
          </div>
        </div>
      );
    } else if (resetSuccess) {
      return (
        <div className="row mt-4">
          <div className="col-12 pb-2">
            <div>
              We have successfully reset your password. You can now log in using
              your new password.
            </div>
            <button
              className="btn btn-purple"
              type="button"
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
        </div>
      );
    } else if (showError) {
      return (
        <div>We were unable to reset your password. Please try again.</div>
      );
    }
  };

  return (
    <div className="jumbotron  bg-white content-body">
      <h1>RESET YOUR PASSWORD</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../assets/images/line.png")}
      />
      {renderPageContent()}
    </div>
  );
};

export default ResetPassword;
