import React from "react";
import { useIntl } from "react-intl";

const HighKDPIDescription = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="title pl-0">
          {formatMessage({ id: "utils.highKDPI.title" })}:
        </div>
        {formatMessage(
          { id: "utils.highKDPI.description" },
          {
            button: (c) => (
              <a
                data-toggle="collapse"
                href="#collapseKDPI"
                role="button"
                aria-expanded="false"
                aria-controls="collapseKDPI"
              >
                {c}
              </a>
            ),
          }
        )}
      </div>
      <div className="collapse w-100 kdpi-example" id="collapseKDPI">
        <div className="card-body list">
          <ul>
            <li>{formatMessage({ id: "ui.label.age" })}</li>
            <li>{formatMessage({ id: "ui.label.height" })}</li>
            <li>{formatMessage({ id: "ui.label.weight" })}</li>
            <li>{formatMessage({ id: "ui.label.ethnicity" })}</li>
            <li>{formatMessage({ id: "utils.highKDPI.hypertension" })}</li>
            <li>{formatMessage({ id: "utils.highKDPI.diabetes" })}</li>
            <li>{formatMessage({ id: "utils.highKDPI.causeOfDeath" })}</li>
            <li>{formatMessage({ id: "utils.highKDPI.serumCreatinine" })}</li>
            <li>{formatMessage({ id: "utils.highKDPI.hcv" })}</li>
            <li>{formatMessage({ id: "utils.highKDPI.dcd" })}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HighKDPIDescription;
