import React from "react";

import { useIntl } from "react-intl";
import Check from "../../utilities/Check";
import HighKDPIDescription from "../../utilities/HighKDPIDescription";
import IncreasedInfectiousRiskDescription from "../../utilities/IncreasedInfectiousRiskDescription";
import HepatiticCPositiveDescription from "../../utilities/HepatiticCPositiveDescription";
import DCDDescriptionLiver from "../../utilities/DCDDescriptionLiver";
import DCDDescriptionLung from "../../utilities/DCDDescriptionLung";
import IncreasedInfectiousRiskDescriptionLiver from "../../utilities/IncreasedInfectiousRiskDescriptionLiver";
import IncreasedInfectiousRiskDescriptionHeart from "../../utilities/IncreasedInfectiousRiskDescriptionHeart";
import IncreasedInfectiousRiskDescriptionLung from "../../utilities/IncreasedInfectiousRiskDescriptionLung";
import Perfused from "../../utilities/Perfused";

const ReceivingADonorOrgan = (props) => {
  const { formatMessage } = useIntl();

  const getCheck = (donorType) => {
    if (props.donorType === donorType || props.donorType === "both") {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <div className="check">
            <Check />
          </div>
          <div className="donor-type">
            {formatMessage({
              id: `donorTypes.${donorType}`,
            }).toLocaleLowerCase()}
          </div>
        </div>
      );
    }
  };

  const getLivingDonorRisks = () => {
    if (props.organ === "liver") {
      return formatMessage({ id: "decisionGuide.risks.livingDonor.liver" });
    } else {
      return formatMessage({ id: "decisionGuide.risks.livingDonor" });
    }
  };

  const getLivingDonorPerformed = () => {
    if (props.organ !== "kidney") {
      return (
        <li>
          {formatMessage({ id: "decisionGuide.text.livingDonorExchange" })}
        </li>
      );
    }
  };

  const getReceivingADeceasedDonorOrgan = () => {
    if (props.organ === "liver") {
      return (
        <div className="right-column-inner ">
          <div
            className={
              "title orange-border " + (props.organ === "heart" ? "d-none" : "")
            }
          >
            {formatMessage({ id: "decisionGuide.content.title.deceased" })}
          </div>
          <ul className="d-flex flex-column justify-content-center h-100">
            <li>
              {formatMessage({ id: "decisionGuide.text.reduceWaitTime" })}
            </li>
            <li className="title">
              {formatMessage(
                { id: "decisionGuide.button.moreInfo.liver" },
                {
                  button: (c) => (
                    <button
                      className="btn btn-link btn-inline-link"
                      data-toggle="modal"
                      href="#"
                      data-target="#donorTypeModal"
                    >
                      {c}
                    </button>
                  ),
                }
              )}
            </li>
          </ul>
          <div
            className="modal fade"
            id="donorTypeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="donorTypeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="donorTypeModalLabel">
                    {formatMessage({ id: "donorTypes.title" })}
                  </h3>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label={formatMessage({ id: "ui.label.close" })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row mb-4">
                    <div className="col-12">
                      {formatMessage({ id: "donorTypes.text.liver" })}
                    </div>
                  </div>
                  <DCDDescriptionLiver />
                  <IncreasedInfectiousRiskDescriptionLiver />
                  <HepatiticCPositiveDescription />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-purple"
                      data-dismiss="modal"
                    >
                      {formatMessage({ id: "ui.label.close" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (props.organ === "kidney") {
      return (
        <div className="right-column-inner ">
          <div
            className={
              "title orange-border " + (props.organ === "heart" ? "d-none" : "")
            }
          >
            {formatMessage({ id: "decisionGuide.content.title.deceased" })}
          </div>
          <ul className="d-flex flex-column justify-content-center h-100">
            <li>
              {formatMessage({ id: "decisionGuide.text.reduceWaitTime" })}
            </li>
            <li className="title">
              {formatMessage(
                { id: "decisionGuide.button.moreInfo.kidney" },
                {
                  button: (c) => (
                    <button
                      className="btn btn-link btn-inline-link"
                      data-toggle="modal"
                      href="#"
                      data-target="#donorTypeModal"
                    >
                      {c}
                    </button>
                  ),
                }
              )}
            </li>
          </ul>
          <div
            className="modal fade"
            id="donorTypeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="donorTypeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="donorTypeModalLabel">
                    {formatMessage({ id: "donorTypes.title" })}
                  </h3>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label={formatMessage({ id: "ui.label.close" })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row mb-4">
                    <div className="col-12">
                      {formatMessage({ id: "donorTypes.text.kidney" })}
                    </div>
                  </div>
                  <HighKDPIDescription />
                  <IncreasedInfectiousRiskDescription />
                  <HepatiticCPositiveDescription />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-purple"
                      data-dismiss="modal"
                    >
                      {formatMessage({ id: "ui.label.close" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (props.organ === "heart") {
      return (
        <div className="right-column-inner ">
          <ul className="d-flex flex-column justify-content-center h-100">
            <li>
              {formatMessage({ id: "decisionGuide.text.reduceWaitTime" })}
            </li>
            <li className="title">
              {formatMessage(
                { id: "decisionGuide.button.moreInfo.heart" },
                {
                  button: (c) => (
                    <button
                      className="btn btn-link btn-inline-link"
                      data-toggle="modal"
                      href="#"
                      data-target="#donorTypeModal"
                    >
                      {c}
                    </button>
                  ),
                }
              )}
            </li>
          </ul>
          <div
            className="modal fade"
            id="donorTypeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="donorTypeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="donorTypeModalLabel">
                    {formatMessage({ id: "donorTypes.title" })}
                  </h3>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label={formatMessage({ id: "ui.label.close" })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row mb-4">
                    <div className="col-12">
                      {formatMessage({ id: "donorTypes.text.heart" })}
                    </div>
                  </div>
                  <IncreasedInfectiousRiskDescriptionHeart />
                  <HepatiticCPositiveDescription />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-purple"
                      data-dismiss="modal"
                    >
                      {formatMessage({ id: "ui.label.close" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (props.organ === "lung") {
      return (
        <div className="right-column-inner ">
          <ul className="d-flex flex-column justify-content-center h-100">
            <li>
              {formatMessage({ id: "decisionGuide.text.reduceWaitTime" })}
            </li>
            <li className="title">
              {formatMessage(
                { id: "decisionGuide.button.moreInfo.lung" },
                {
                  button: (c) => (
                    <button
                      className="btn btn-link btn-inline-link"
                      data-toggle="modal"
                      href="#"
                      data-target="#donorTypeModal"
                    >
                      {c}
                    </button>
                  ),
                }
              )}
            </li>
          </ul>
          <div
            className="modal fade"
            id="donorTypeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="donorTypeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title" id="donorTypeModalLabel">
                    {formatMessage({ id: "donorTypes.title" })}
                  </h3>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label={formatMessage({ id: "ui.label.close" })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row mb-4">
                    <div className="col-12">
                      {formatMessage({ id: "donorTypes.text.lung" })}
                    </div>
                  </div>
                  <DCDDescriptionLung />
                  <IncreasedInfectiousRiskDescriptionLung />
                  <HepatiticCPositiveDescription />
                  <Perfused />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-purple"
                      data-dismiss="modal"
                    >
                      {formatMessage({ id: "ui.label.close" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const receivingALivingDonorOrgan = () => {
    if (props.donorType === "living" || props.donorType === "both") {
      return (
        <div className="right-column-inner">
          <div className="title">
            {formatMessage({ id: "decisionGuide.content.title.living" })}
          </div>
          <ul className="d-flex flex-column justify-content-center h-100">
            <li>{getLivingDonorRisks()}</li>
            {getLivingDonorPerformed()}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="right-column-inner">
          <div className="title">
            {formatMessage({ id: "decisionGuide.content.title.nonLiving" })}
          </div>
          <ul className="d-flex flex-column justify-content-center h-100">
            <li>{getLivingDonorRisks()}</li>
          </ul>
        </div>
      );
    }
  };

  const receivingADeceasedDonorOrgan = () => {
    if (props.donorType === "deceased" || props.donorType === "both") {
      return getReceivingADeceasedDonorOrgan();
    } else {
      return (
        <div className="right-column-inner">
          <div className="title">
            {formatMessage({ id: "decisionGuide.content.title.nonLiving" })}
          </div>
          <ul className="d-flex flex-column justify-content-center h-100">
            <li>
              {formatMessage({ id: "decisionGuide.content.deceasedDonorPool" })}
            </li>
          </ul>
        </div>
      );
    }
  };

  if (props.organ === "kidney" || props.organ === "liver") {
    return (
      <div className="row no-gutters highlight">
        <div className="col-sm-12 col-md-2 left-column border-right-orange ">
          <div className="left-column-inner d-flex justify-content-center align-items-center h-100">
            {formatMessage({ id: "decisionGuide.section.title.receiving" })}
          </div>
        </div>
        <div className="col-sm-12 col-md-2 middle-column border-right-orange">
          <div className="middle-column-inner d-flex justify-content-center align-items-center h-100">
            {getCheck("living")}
          </div>
        </div>
        <div className="col-sm-12 col-md-8 right-column">
          {receivingALivingDonorOrgan()}
        </div>
        <div className="w-100"></div>
        <div className="col-sm-12 col-md-2 left-column border-right-orange ">
          <div className="left-column-inner d-flex justify-content-center align-items-center h-100 "></div>
        </div>
        <div className="col-sm-12 col-md-2 middle-column border-right-orange">
          <div className="middle-column-inner d-flex justify-content-center align-items-center h-100">
            {getCheck("deceased")}
          </div>
        </div>
        <div className="col-sm-12 col-md-8 right-column bottom-right-radius">
          {receivingADeceasedDonorOrgan()}
        </div>
      </div>
    );
  } else {
    return (
      <div className="row no-gutters highlight">
        <div className="col-sm-12 col-md-2 left-column border-right-orange ">
          <div className="left-column-inner d-flex justify-content-center align-items-center h-100">
            {formatMessage({ id: "decisionGuide.section.title.receiving" })}
          </div>
        </div>
        <div className="col-sm-12 col-md-2 middle-column border-right-orange">
          <div className="middle-column-inner d-flex justify-content-center align-items-center h-100">
            {getCheck("deceased")}
          </div>
        </div>
        <div className="col-sm-12 col-md-8 right-column bottom-right-radius">
          {receivingADeceasedDonorOrgan()}
        </div>
      </div>
    );
  }
};

export default ReceivingADonorOrgan;
