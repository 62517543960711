import React from "react";
import { useIntl } from "react-intl";

const TransplantsInYear = (props) => {
  const { formatMessage } = useIntl();
  let cell = props.cellValue;

  let transplants = 0;
  if (cell !== null || cell !== 0) {
    transplants = Math.round(cell).toLocaleString("en");
  }

  return (
    <div className="transplants">
      <div className="results-number">{transplants}</div>
      <div className="results-text">
        {formatMessage({ id: "ui.label.adults" })}
      </div>
    </div>
  );
};

export default TransplantsInYear;
