import React from "react";
import DecisionGuideFAQs from "../components/faq/DecisionGuideFAQs";
import { useIntl } from "react-intl";

const DecisionGuideFAQ = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="inner-container">
      <div className="white-block rounded content-body">
        <img
          alt="Transplant Center Search"
          className="hand"
          src={require("../assets/images/hand.png")}
        />
        <h1 className="heading">
          {formatMessage({ id: "decisionGuideFAQ.title" })}
        </h1>
        <img
          alt="Line separator"
          className="img-fluid line"
          src={require("../assets/images/line.png")}
        />
        <DecisionGuideFAQs />
      </div>
    </div>
  );
};

export default DecisionGuideFAQ;
