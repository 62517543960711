import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  IntlProvider as LangIntlProvider,
  useIntl as useIntlLang,
} from "react-intl";

import en from "./messages/en.json";
import es from "./messages/es.json";

const LANG_LOCALE = "LANG_LOCALE";

const initialLocale = localStorage.getItem(LANG_LOCALE) || "en";
const htmlElements = document.getElementsByTagName("html");
const html = htmlElements.length ? htmlElements[0] : null;

const mergeLang = (otherLang, english) => ({
  ...english,
  ...Object.fromEntries(
    Object.entries(otherLang).map(([key, translation]) => [
      key,
      translation || english[key],
    ])
  ),
});

const LOCALES = {
  en,
  es: mergeLang(es, en),
};

const IntlContext = createContext({
  getLocale: () => initialLocale,
  updateLocale: () => {},
});

export const IntlProvider = ({ children }) => {
  const [selectedLocale, setSelectedLocale] = useState(initialLocale);
  const ref = useRef(html);

  useEffect(() => {
    if (ref.current && ref.current.getAttribute("lang") !== selectedLocale) {
      ref.current.setAttribute("lang", selectedLocale);
    }
  }, [selectedLocale]);

  return (
    <IntlContext.Provider
      value={{
        getLocale: () => selectedLocale,
        updateLocale: (newLocale) => {
          setSelectedLocale(newLocale);
          localStorage.setItem(LANG_LOCALE, newLocale);
        },
      }}
    >
      <LangIntlProvider
        defaultLocale={initialLocale}
        locale={selectedLocale}
        messages={LOCALES[selectedLocale]}
      >
        {children}
      </LangIntlProvider>
    </IntlContext.Provider>
  );
};

export const useIntl = () => {
  return useContext(IntlContext);
};

export const withIntl = (Component) => {
  return (props) => {
    const intl = useIntlLang();
    return <Component {...props} intl={intl} />;
  };
};
