import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";

import SearchService from "../../../services/SearchService";
import { getCustomSearchHelpers } from "../custom-search/CustomSearchHelper";

import Distance from "../inputs/Distance";
import Age from "../inputs/Age";
import BMI from "../inputs/BMI";
import CauseOfHeartDisease from "../inputs/CauseOfHeartDisease";
import InsuranceProvider from "../inputs/InsuranceProvider";
import HIVPositive from "../inputs/HIVPositive";
import HepCPositiveHeart from "../inputs/HepCPositiveHeart";
import MultiOrgan from "../inputs/MultiOrgan";
import HeartRetransplant from "../inputs/HeartRetransplant";
import PreviousHeartSurgery from "../inputs/PreviousHeartSurgery";

import IncreasedInfectiousRiskDescriptionHeart from "../../utilities/IncreasedInfectiousRiskDescriptionHeart";
import HepatiticCPositiveDescription from "../../utilities/HepatiticCPositiveDescription";
import EmailMedicalProfile from "./EmailMedicalProfile";
import { setDistanceMiles, setOrgan } from "../../../actions";
import { store } from "../../../store";
import Diabetes from "../inputs/Diabetes";
import { getAllSearchParams } from "../../../common/utils/searchParams";

const HeartProfile = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isValidUrl, setIsValidUrl] = useState(true);

  const { formatMessage } = intl;
  const customSearchHelper = getCustomSearchHelpers(intl);

  const [form, setForm] = useState({
    distanceZipCode: "",
    distanceState: "",
    age: "",
    ageSelected: false,
    heightFeet: "",
    heightInches: "",
    weight: "",
    bmiSelected: false,
    causeOfHeartDisease: "",
    causeOfHeartDiseaseSelected: false,
    diabetes: "",
    diabetesSelected: false,
    insuranceProvider: "",
    insuranceProviderSelected: false,
    donorType: "",
    hivPositive: "",
    hivPositiveSelected: false,
    hepCPositiveHeart: "",
    hepCPositiveHeartSelected: false,
    multiOrgan: "",
    multiOrganSelected: false,
    heartRetransplant: "",
    heartRetransplantSelected: false,
    previousHeartSurgery: "",
    previousHeartSurgerySelected: false,
  });

  useEffect(() => {
    const values = getAllSearchParams(searchParams);
    const searchService = new SearchService();

    if (values.donorType) {
      const distanceMiles =
        typeof values.distanceMiles !== "undefined"
          ? values.distanceMiles
          : searchService.getDistanceMiles();
      const distanceZipCode =
        typeof values.distanceZipCode !== "undefined"
          ? values.distanceZipCode
          : searchService.getSearchParameter("distanceZipCode");
      const distanceState =
        typeof values.distanceState !== "undefined"
          ? values.distanceState
          : searchService.getSearchParameter("distanceState");
      const age =
        typeof values.age !== "undefined"
          ? values.age
          : searchService.getSearchParameter("age");
      const weight =
        typeof values.weight !== "undefined"
          ? values.weight
          : searchService.getSearchParameter("weight");
      const heightFeet =
        typeof values.heightFeet !== "undefined"
          ? values.heightFeet
          : searchService.getSearchParameter("heightFeet");
      const heightInches =
        typeof values.heightInches !== "undefined"
          ? values.heightInches
          : searchService.getSearchParameter("heightInches");
      const causeOfHeartDisease =
        typeof values.causeOfHeartDisease !== "undefined"
          ? values.causeOfHeartDisease
          : searchService.getSearchParameter("causeOfHeartDisease");
      const diabetes =
        typeof values.diabetes !== "undefined"
          ? values.diabetes
          : searchService.getSearchParameter("diabetes");
      const insuranceProvider =
        typeof values.insuranceProvider !== "undefined"
          ? values.insuranceProvider
          : searchService.getSearchParameter("insuranceProvider");
      const donorType =
        typeof values.donorType !== "undefined"
          ? values.donorType
          : searchService.getSearchParameter("donorType");
      const hivPositive =
        typeof values.hivPositive !== "undefined"
          ? values.hivPositive
          : searchService.getSearchParameter("hivPositive");
      const hepCPositiveHeart =
        typeof values.hepCPositiveHeart !== "undefined"
          ? values.hepCPositiveHeart
          : searchService.getSearchParameter("hepCPositiveHeart");
      const multiOrgan =
        typeof values.multiOrgan !== "undefined"
          ? values.multiOrgan
          : searchService.getSearchParameter("multiOrgan");
      const heartRetransplant =
        typeof values.heartRetransplant !== "undefined"
          ? values.heartRetransplant
          : searchService.getSearchParameter("heartRetransplant");
      const previousHeartSurgery =
        typeof values.previousHeartSurgery !== "undefined"
          ? values.previousHeartSurgery
          : searchService.getSearchParameter("previousHeartSurgery");

      if (!props.distanceMiles) {
        store.dispatch(setDistanceMiles(distanceMiles));
      }

      if (!props.organ) {
        store.dispatch(
          setOrgan("heart", formatMessage({ id: "organs.heart" }))
        );
      }

      setForm((prevFormValue) => ({
        ...prevFormValue,
        distanceZipCode,
        distanceState,
        age,
        weight,
        heightFeet,
        heightInches,
        causeOfHeartDisease,
        diabetes,
        insuranceProvider,
        donorType,
        hivPositive,
        hepCPositiveHeart,
        multiOrgan,
        heartRetransplant,
        previousHeartSurgery,
      }));
    } else {
      setIsValidUrl(false);
    }
  }, [searchParams, props.distanceMiles, props.organ, formatMessage]);

  const getHighlightedFields = () => {
    let recipientCriteriaSearchFields = [];

    if (form.ageSelected) {
      recipientCriteriaSearchFields.push(
        customSearchHelper.PatientsOverAgeSixtyFive
      );
    }

    if (form.bmiSelected) {
      recipientCriteriaSearchFields.push(customSearchHelper.BMIOverThirtyFive);
    }

    if (form.diabetesSelected) {
      recipientCriteriaSearchFields.push(customSearchHelper.Diabetes);
    }

    if (form.insuranceProviderSelected) {
      recipientCriteriaSearchFields.push(customSearchHelper.InsuranceMedicaid);
    }

    if (form.hivPositiveSelected) {
      recipientCriteriaSearchFields.push(customSearchHelper.HIVPositiveDonor);
      recipientCriteriaSearchFields.push(
        customSearchHelper.HIVPositiveRecipient
      );
    }

    if (form.hepCPositiveHeartSelected && form.hepCPositiveHeart !== "no") {
      recipientCriteriaSearchFields.push(customSearchHelper.HepCPositive);
    }

    if (form.heartRetransplantSelected) {
      recipientCriteriaSearchFields.push(customSearchHelper.HeartRetransplant);
    }

    if (form.previousHeartSurgerySelected) {
      recipientCriteriaSearchFields.push(
        customSearchHelper.PreviousHeartSurgery
      );
    }

    return recipientCriteriaSearchFields;
  };

  const doSearch = () => {
    const params = getHighlightedFields()
      .map(function (field) {
        return field.value;
      })
      .join(",");

    let mainUrl = `/search?organ=${props.organ}`;
    mainUrl += `&distanceZipCode=${form.distanceZipCode}`;
    mainUrl += `&distanceMiles=${props.distanceMiles}`;
    mainUrl += `&distanceState=${form.distanceState}`;
    mainUrl += `&donorType=${form.donorType}`;
    mainUrl += `&showAll=true`;

    navigate(`${mainUrl}&params=${params}`);
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevFormValue) => ({
      ...prevFormValue,
      [name]: value,
    }));

    const searchService = new SearchService();
    searchService.setSearchParameter(name, value);
    if (name === "distanceMiles") {
      store.dispatch(setDistanceMiles(value));
    }
  };

  const setSelected = (name, selected) => {
    name = name + "Selected";

    setForm((prevFormValue) => ({
      ...prevFormValue,
      [name]: selected,
    }));
  };

  const print = () => {
    window.print();
  };

  const getDonorType = () => {
    return formatMessage({ id: "donorTypes.deceased" });
  };

  const getUrl = () => {
    var location = window.location;
    var path = location.pathname;
    var url = `${location.protocol}//${
      location.hostname + (location.port ? ":" + location.port : "")
    }${path}`;

    url += `?distanceMiles=${props.distanceMiles}`;
    url += `&distanceZipCode=${form.distanceZipCode}`;
    url += `&distanceState=${form.distanceState}`;
    url += `&age=${form.age}`;
    url += `&weight=${form.weight}`;
    url += `&heightFeet=${form.heightFeet}`;
    url += `&heightInches=${form.heightInches}`;
    url += `&causeOfHeartDisease=${form.causeOfHeartDisease}`;
    url += `&diabetes=${form.diabetes}`;
    url += `&insuranceProvider=${form.insuranceProvider}`;
    url += `&donorType=${form.donorType}`;
    url += `&hivPositive=${form.hivPositive}`;
    url += `&hepCPositiveHeart=${form.hepCPositiveHeart}`;
    url += `&multiOrgan=${form.multiOrgan}`;
    url += `&heartRetransplant=${form.heartRetransplant}`;
    url += `&previousHeartSurgery=${form.previousHeartSurgery}`;

    return url;
  };

  if (isValidUrl) {
    return (
      <div className="inner-container">
        <div className="white-block rounded content-body">
          <div className="row">
            <div className="col-12">
              <h1>{formatMessage({ id: "medicalProfile.title" })}</h1>
              <div className="pb-2">
                {formatMessage({ id: "medicalProfile.subtitle" })}
              </div>
              <div className="pb-1">
                <Link to="/faq" target="_blank" rel="noopener noreferrer">
                  {formatMessage({ id: "medicalProfile.link.why" })}
                </Link>
              </div>
              <img
                alt="Line separator"
                className="img-fluid line"
                src={require("../../../assets/images/line.png")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <Distance
                  distanceMiles={props.distanceMiles}
                  distanceZipCode={form.distanceZipCode}
                  distanceState={form.distanceState}
                  handleOnChange={handleOnChange}
                />
                <Age
                  age={form.age}
                  organ={form.organ}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <BMI
                  heightFeet={form.heightFeet}
                  heightInches={form.heightInches}
                  weight={form.weight}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <CauseOfHeartDisease
                  causeOfHeartDisease={form.causeOfHeartDisease}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <Diabetes
                  diabetes={form.diabetes}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <InsuranceProvider
                  insuranceProvider={form.insuranceProvider}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <HIVPositive
                  hivPositive={form.hivPositive}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <HepCPositiveHeart
                  hepCPositive={form.hepCPositiveHeart}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <MultiOrgan
                  multiOrgan={form.multiOrgan}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <HeartRetransplant
                  heartRetransplant={form.heartRetransplant}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
                <PreviousHeartSurgery
                  previousHeartSurgery={form.previousHeartSurgery}
                  handleOnChange={handleOnChange}
                  setSelected={setSelected}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <img
                    alt="Line separator"
                    className="img-fluid line"
                    src={require("../../../assets/images/line.png")}
                  />
                </div>
                <div className="col-12">
                  <div className="search-label">
                    {formatMessage({ id: "ui.label.donorType" })}:{" "}
                    {getDonorType()}
                  </div>
                </div>
                <div className="col-12">
                  {formatMessage({ id: "donorTypes.text.heart" })}
                </div>
                <div className="col-12">
                  <div className="helper-text">
                    <div className="jumbotron">
                      <img
                        alt="Information"
                        className="img-fluid float-left pr-2 help"
                        src={require("../../../assets/images/i.png")}
                      />
                      <IncreasedInfectiousRiskDescriptionHeart />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="helper-text">
                    <div className="jumbotron">
                      <img
                        alt="Information"
                        className="img-fluid float-left pr-2 help"
                        src={require("../../../assets/images/i.png")}
                      />
                      <HepatiticCPositiveDescription />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters justify-content-end d-print-none mt-3">
                <button
                  className="btn btn-no-radius btn-outline-purple rounded-top w-190"
                  type="button"
                  onClick={() => doSearch()}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-left u-pre-line">
                      {formatMessage({
                        id: "medicalProfile.button.decisionGuide",
                      })}
                    </div>
                    <div className="col-2 btn-right-text">
                      <img
                        alt="Search Transplant Centers"
                        src={require("../../../assets/images/arrow.png")}
                      />
                    </div>
                  </div>
                </button>
              </div>
              <div className="row no-gutters justify-content-end d-print-none mt-2">
                <button
                  className="btn btn-no-radius btn-outline-purple-background-white rounded-top mr-lg-1"
                  type="button"
                  data-toggle="modal"
                  data-target="#emailModal"
                >
                  <div className=" d-flex align-items-center justify-content-between">
                    <div className="text-left u-pre-line">
                      {formatMessage({ id: "medicalProfile.button.email" })}
                    </div>
                    <div className="col-2 btn-right-text">
                      <img
                        alt="Search Transplant Centers"
                        src={require("../../../assets/images/arrow_purple.png")}
                      />
                    </div>
                  </div>
                </button>

                <button
                  className="btn btn-no-radius btn-outline-purple-background-white rounded-top"
                  type="button"
                  onClick={() => print()}
                >
                  <div className=" d-flex align-items-center justify-content-between">
                    <div className="text-left u-pre-line">
                      {formatMessage({ id: "medicalProfile.button.print" })}
                    </div>
                    <div className="col-2 btn-right-text">
                      <img
                        alt="Search Transplant Centers"
                        src={require("../../../assets/images/arrow_purple.png")}
                      />
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <EmailMedicalProfile url={getUrl()} />
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
};

function mapStateToProps(state) {
  return {
    distanceMiles: state.distanceMiles,
    organ: state.organ,
  };
}

export default connect(mapStateToProps)(HeartProfile);
