import React from "react";
import { useIntl } from "react-intl";

const Rank = (props) => {
  const { formatMessage } = useIntl();
  const cell = props.cellValue;

  if (cell === 1) {
    return (
      <img
        alt={formatMessage({ id: "utils.rank.1" })}
        className="img-fluid rank"
        src={require(`../../assets/images/bars1.png`)}
      />
    );
  } else if (cell === 2) {
    return (
      <img
        alt={formatMessage({ id: "utils.rank.2" })}
        className="img-fluid rank"
        src={require(`../../assets/images/bars2.png`)}
      />
    );
  } else if (cell === 3) {
    return (
      <img
        alt={formatMessage({ id: "utils.rank.3" })}
        className="img-fluid rank"
        src={require(`../../assets/images/bars3.png`)}
      />
    );
  } else if (cell === 4) {
    return (
      <img
        alt={formatMessage({ id: "utils.rank.4" })}
        className="img-fluid rank"
        src={require(`../../assets/images/bars4.png`)}
      />
    );
  } else if (cell === 5) {
    return (
      <img
        alt={formatMessage({ id: "utils.rank.5" })}
        className="img-fluid rank"
        src={require(`../../assets/images/bars5.png`)}
      />
    );
  } else
    return (
      <img
        alt={formatMessage({ id: "utils.rank.noData" })}
        className="img-fluid rank"
        src={require(`../../assets/images/nodata.png`)}
      />
    );
};

export default Rank;
