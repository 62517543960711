import React, { Component } from "react";
import Helper from "../../utilities/Helper";
import { withIntl } from "../../../common/lang";

class CauseOfLiverDisease extends Component {
  componentDidUpdate = (prv) => {
    if (prv.causeOfLiverDisease !== this.props.causeOfLiverDisease) {
      this.props.setSelected(
        "causeOfLiverDisease",
        this.checkCauseOfLiverDisease(this.props.causeOfLiverDisease)
      );
    }
  };

  handleLiverDiseaseInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  causeOfLiverDiseaseHelper = () => {
    const { formatMessage } = this.props.intl;
    if (this.checkCauseOfLiverDisease(this.props.causeOfLiverDisease)) {
      return (
        <Helper
          text={formatMessage({ id: "form.causeOfLiverDisease.helper" })}
        />
      );
    }
  };

  checkCauseOfLiverDisease(causeOfLiverDisease) {
    //TODO: Not implemented
    return false;
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.causeOfLiverDisease" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select-lg"
              name="causeOfLiverDisease"
              onChange={this.handleLiverDiseaseInputChange}
              value={this.props.causeOfLiverDisease}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="acuteLiverFailure">
                {formatMessage({ id: "form.option.acuteLiverFailure" })}
              </option>
              <option value="alcoholicLiverDisease">
                {formatMessage({ id: "form.option.alcoholicLiverDisease" })}
              </option>
              <option value="cholestaticLiverDisease">
                {formatMessage({ id: "form.option.cholestaticLiverDisease" })}
              </option>
              <option value="hepatitisB">
                {formatMessage({ id: "form.option.hepatitisB" })}
              </option>
              <option value="hepatitisC">
                {formatMessage({ id: "form.option.hepatitisC" })}
              </option>
              <option value="liverCancer">
                {formatMessage({ id: "form.option.liverCancer" })}
              </option>
              <option value="other">
                {formatMessage({ id: "form.other" })}
              </option>
              <option value="dontKnow">
                {formatMessage({ id: "form.dontKnow" })}
              </option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">
              {this.causeOfLiverDiseaseHelper()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(CauseOfLiverDisease);
