import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mixed, object, string } from "yup";

import { renderFormError } from "../../../common/utils/form";
import OrganService from "../../../services/OrganService";
import SearchService from "../../../services/SearchService";
import { store } from "../../../store";
import { setOrgan, setDistanceMiles } from "../../../actions";

const basicSearchSchema = object({
  organ: mixed()
    .oneOf(["heart", "kidney", "liver", "lung"])
    .required("validation.organ.required"),
  distanceZipCode: string().required("validation.zipCode.required"),
});

const BasicSearch = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      organ: props.organ || "",
      distanceZipCode: "",
      distanceMiles: props.distanceMiles || "",
    },
    initialTouched: {
      organ: false,
      distanceZipCode: false,
    },
    validationSchema: basicSearchSchema,
    onSubmit: (values) => {
      navigate(
        `/search?type=basic&organ=${values.organ}&distanceZipCode=${values.distanceZipCode}&distanceMiles=${values.distanceMiles}`
      );
    },
  });

  useEffect(() => {
    const organService = new OrganService();
    const searchService = new SearchService();

    const organ = organService.getOrgan();

    if (organ) {
      store.dispatch(setOrgan(organ.name, organ.display));
      formik.setFieldValue("organ", organ.name);
    }

    const distanceZipCode = searchService.getSearchParameter("distanceZipCode");
    let distanceMiles = searchService.getDistanceMiles();

    if (distanceMiles === "" && organ.name === "kidney") {
      distanceMiles = "50";
    } else if (distanceMiles === "" && organ.name === "liver") {
      distanceMiles = "100";
    }

    store.dispatch(setDistanceMiles(distanceMiles));
    searchService.setSearchParameter("distanceMiles", distanceMiles);

    formik.setFieldValue("distanceZipCode", distanceZipCode);
    // eslint-disable-next-line
  }, [props.organ]);

  const handleInputChange = (event) => {
    const organService = new OrganService();
    const searchService = new SearchService();

    if (event.currentTarget.name === "organ") {
      let display =
        event.currentTarget.options[event.currentTarget.selectedIndex].text;
      if (event.currentTarget.selectedIndex === 0) {
        display = "";
      }

      const organ = { name: event.currentTarget.value, display: display };
      organService.setOrgan(organ);
      store.dispatch(setOrgan(organ.name, organ.display));
      formik.setFieldValue("organ", organ.name);

      let distanceMiles = "";
      if (organ.name === "kidney") {
        distanceMiles = "50";
      } else {
        distanceMiles = "100";
      }

      store.dispatch(setDistanceMiles(distanceMiles));
      searchService.setSearchParameter("distanceMiles", distanceMiles);
      formik.setFieldValue("distanceMiles", distanceMiles);
    } else if (event.target.name === "distanceZipCode") {
      searchService.setSearchParameter("distanceZipCode", event.target.value);
      formik.setFieldValue("distanceZipCode", event.target.value);
    } else if (event.target.name === "distanceMiles") {
      const distanceMiles = event.target.value;
      store.dispatch(setDistanceMiles(distanceMiles));
      searchService.setSearchParameter("distanceMiles", distanceMiles);
      formik.setFieldValue("distanceMiles", distanceMiles);
    }
  };

  return (
    <div className="inner-container">
      <div className="white-block">
        <h1>{formatMessage({ id: "basicSearch.title" })}</h1>
        <img
          alt="Line separator"
          className="img-fluid line"
          src={require("../../../assets/images/line.png")}
        />
        <h2>{formatMessage({ id: "basicSearch.subtitle" })}</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="row mt-4">
            <div className="col-xs-12 col-md-4">
              <select
                className="form-control"
                name="organ"
                onChange={handleInputChange}
                value={formik.values.organ}
                onBlur={formik.handleBlur}
              >
                <option value="">
                  {formatMessage({ id: "basicSearch.select.organ" })}
                </option>
                <option value="heart">
                  {formatMessage({ id: "organs.heart" })}
                </option>
                <option value="kidney">
                  {formatMessage({ id: "organs.kidney" })}
                </option>
                <option value="liver">
                  {formatMessage({ id: "organs.liver" })}
                </option>
                <option value="lung">
                  {formatMessage({ id: "organs.lung" })}
                </option>
              </select>
              {renderFormError(formik, "organ", intl)}
            </div>
            <div className="col-xs-12 col-md-4">
              <input
                type="text"
                name="distanceZipCode"
                className="form-control orange-placeholder"
                placeholder={formatMessage({ id: "basicSearch.input.zipCode" })}
                aria-label={formatMessage({ id: "basicSearch.input.zipCode" })}
                aria-describedby="basic-addon2"
                value={formik.values.distanceZipCode}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                maxLength="5"
              />
              {renderFormError(formik, "distanceZipCode", intl)}
            </div>
            <div className="col-xs-12 col-md-4">
              <select
                className="form-control"
                name="distanceMiles"
                value={formik.values.distanceMiles}
                onChange={handleInputChange}
                onBlur={formik.onBlur}
              >
                <option value="">
                  {formatMessage({ id: "form.distance.any" })}
                </option>
                <option value="50">
                  {formatMessage({ id: "form.distance.50" })}
                </option>
                <option value="100">
                  {formatMessage({ id: "form.distance.100" })}
                </option>
                <option value="250">
                  {formatMessage({ id: "form.distance.250" })}
                </option>
                <option value="500">
                  {formatMessage({ id: "form.distance.500" })}
                </option>
                <option value="700">
                  {formatMessage({ id: "form.distance.700" })}
                </option>
              </select>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <button
                className="btn btn-no-radius btn-outline-purple rounded-top float-right ml-2 step u-pre-line"
                type="submit"
              >
                <div className="row align-items-center justify-content-around">
                  <div className="text-left u-pre-line">
                    {formatMessage({ id: "basicSearch.button.search" })}
                  </div>
                  <div className="btn-right-text">
                    <img
                      alt="Basic Search"
                      src={require("../../../assets/images/arrow.png")}
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    organ: state.organ,
    distanceMiles: state.distanceMiles,
  };
}

export default connect(mapStateToProps)(BasicSearch);
