import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

import GettingADeceasedDonor from "./GettingADeceasedDonor";
import GettingOnATransplantList from "./GettingOnATransplantList";
import OtherFactors from "./OtherFactors";
import ReceivingADonorOrgan from "./ReceivingADonorOrgan";
import EmptyRow from "./EmptyRow";

const DecisionGuide = (props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const nodeRef = useRef();

  useEffect(() => {
    if (props.isShowAll) {
      smoothscroll.polyfill();
      nodeRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.isShowAll]);

  const relevantCriteria = () => {
    const donorType = props.donorType;
    const recipientCriteriaSearchFields = props.recipientCriteriaSearchFields;
    const livingDonor = formatMessage({ id: "donorTypes.living" });
    const deceasedDonor = formatMessage({ id: "donorTypes.deceased" });

    let returnCriteria = "";
    if (donorType === "both") {
      returnCriteria = `${livingDonor}\\${deceasedDonor}`;
    } else if (donorType === "living") {
      returnCriteria = livingDonor;
    } else if (donorType === "deceased") {
      returnCriteria = deceasedDonor;
    }

    const selectedProps = recipientCriteriaSearchFields.reduce(
      (previousVal, currentVal, currentIndex, arr) => {
        previousVal += currentVal.label;
        if (currentIndex < arr.length - 1) {
          previousVal += ", ";
        }
        return previousVal;
      },
      ""
    );

    if (selectedProps.length > 0) {
      return returnCriteria + ", " + selectedProps;
    } else {
      return returnCriteria;
    }
  };

  const toMedicalProfile = () => {
    const url = `/search/${props.organ}?distanceMiles=${props.distanceMiles}&distanceState=${props.distanceState}&distanceZipCode=${props.distanceZipCode}&donorType=${props.donorType}`;
    navigate(url);
  };

  const getSearchButton = () => {
    if (!props.isShowAll) {
      return (
        <button
          className="d-print-none btn btn-no-radius btn-outline-purple rounded-top pr-0 step"
          type="button"
          onClick={toMedicalProfile}
        >
          <div className="d-flex align-items-center justify-content-around">
            <div className="text-left u-pre-line">
              {formatMessage({ id: "decisionGuide.button.medicalProfile" })}
            </div>
            <div className="btn-right-text">
              <img
                alt="Search Transplant Centers"
                src={require("../../../assets/images/arrow.png")}
              />
            </div>
          </div>
        </button>
      );
    } else {
      return (
        <button
          className="d-print-none btn btn-no-radius btn-outline-purple rounded-top step"
          type="button"
          onClick={props.onPersonalizedSearchClick}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-left u-pre-line">
              {formatMessage({
                id: "decisionGuide.button.personalizedResults",
              })}
            </div>
            <div className="btn-right-text">
              <img
                alt="Search Transplant Centers"
                src={require("../../../assets/images/arrow.png")}
              />
            </div>
          </div>
        </button>
      );
    }
  };

  const getPageContent = () => {
    if (!props.isShowAll) {
      return (
        <div>
          <GettingADeceasedDonor
            organ={props.organ}
            donorType={props.donorType}
            isAdvanced={false}
          />
          <EmptyRow />
          <ReceivingADonorOrgan
            donorType={props.donorType}
            organ={props.organ}
          />
          <div className="row">
            <div className="col-12 to-profile">{getSearchButton()}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="all">
          <GettingADeceasedDonor
            organ={props.organ}
            donorType={props.donorType}
            isAdvanced={true}
          />
          <EmptyRow />
          <ReceivingADonorOrgan
            donorType={props.donorType}
            organ={props.organ}
          />
          <EmptyRow
            text={
              <div
                ref={(node) => (nodeRef.current = node)}
                className="current-step"
              >
                <h2 id="step2">{formatMessage({ id: "ui.label.step2" })}:</h2>
              </div>
            }
          />
          <GettingOnATransplantList
            organ={props.organ}
            donorType={props.donorType}
            recipientCriteriaSearchFields={props.recipientCriteriaSearchFields}
          />
          <EmptyRow />
          <OtherFactors organ={props.organ} />
          <div className="row">
            <div className="col-12 to-profile">{getSearchButton()}</div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="decision-guide">
      <div className="row">
        <div className="col-12 summary">
          <div>{formatMessage({ id: "decisionGuide.info1" })}</div>
          <div>
            {formatMessage({ id: "decisionGuide.info2" })} -{" "}
            {relevantCriteria()}
          </div>
          <div>
            {formatMessage(
              { id: "decisionGuide.info3" },
              {
                link: (c) => (
                  <Link
                    to="/decision-guide-faq"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {c}
                  </Link>
                ),
              }
            )}
          </div>
        </div>
        <div className="col-12 current-step">
          <h2>{formatMessage({ id: "ui.label.step1" })}:</h2>
        </div>
      </div>
      {getPageContent()}
    </div>
  );
};

export default DecisionGuide;
