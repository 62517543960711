import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link, Navigate, useLocation, useSearchParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import smoothscroll from "smoothscroll-polyfill";
import "react-tabs/style/react-tabs.css";

import { getAllSearchParams } from "../../common/utils/searchParams";
import DecisionGuide from "../../components/search/decision-guide/DecisionGuide";
import SearchResults from "../../components/search/results/SearchResults";
import OrganService from "../../services/OrganService";
import SearchService from "../../services/SearchService";
import {
  getCustomSearchFields,
  getDonorOptionsSearchFields,
} from "./custom-search/CustomSearchHelper";
import Loading from "../utilities/Loading";

const Search = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [searchValues, setSearchValues] = useState({});
  const [searchResults, setSearchResults] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const nodeRef = useRef();
  const scrollRef = useRef();

  useEffect(() => {
    const values = getAllSearchParams(searchParams);

    const searchService = new SearchService();
    const organService = new OrganService();

    const searchType = typeof values.type !== "undefined" ? values.type : "";

    if (values.organ) {
      const distanceMiles =
        typeof values.distanceMiles !== "undefined"
          ? values.distanceMiles
          : searchService.getSearchParameter("distanceMiles");
      const distanceZipCode =
        typeof values.distanceZipCode !== "undefined"
          ? values.distanceZipCode
          : searchService.getSearchParameter("distanceZipCode");
      const distanceState =
        typeof values.distanceState !== "undefined"
          ? values.distanceState
          : searchService.getSearchParameter("distanceState");
      const organ =
        typeof values.organ !== "undefined"
          ? values.organ
          : organService.getOrgan().name;
      const showAll =
        typeof values.showAll !== "undefined" ? values.showAll : false;
      //handled a bit differently
      const donorType =
        typeof values.donorType !== "undefined"
          ? values.donorType
          : searchService.getDonorType().name;
      let params = [];
      if (values.params) {
        params = values.params.split(",");
      }
      const allCustomSearchFields = getCustomSearchFields(organ, intl);
      //Trim the array for the recipient custom search fields
      const recipientCriteriaSearchFields = allCustomSearchFields.filter(
        function (el) {
          return params.some(function (f) {
            return f === el.value;
          });
        }
      );
      //Trim the array for the other custom search fields
      const otherCustomSearchFields = allCustomSearchFields.filter(function (
        item
      ) {
        return params.indexOf(item.value) === -1;
      });
      const donorOptionsSearchFields = getDonorOptionsSearchFields(organ, intl);

      setSearchValues({
        organ,
        distanceMiles,
        distanceZipCode,
        distanceState,
        donorType,
        recipientCriteriaSearchFields,
        otherCustomSearchFields,
        donorOptionsSearchFields,
        searchType,
        isShowAll: showAll,
      });

      searchService
        .getSearch(organ, distanceZipCode, distanceMiles, distanceState)
        .then((data) => {
          setIsLoading(false);
          setSearchResults(data);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setIsValidUrl(false);
    }
  }, [searchParams, location.search, intl]);

  const handlePersonalizedSearch = () => {
    setTabIndex(1);

    smoothscroll.polyfill();
    nodeRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const getPersonalizedText = () => {
    const width = window.innerWidth;
    if (width > 768) {
      return formatMessage({ id: "search.tab.personalized" });
    } else {
      return formatMessage({ id: "search.tab.personalized.small" });
    }
  };

  const getBasicText = () => {
    const width = window.innerWidth;
    if (width > 768) {
      return formatMessage({ id: "search.tab.basic" });
    } else {
      return formatMessage({ id: "search.tab.basic.small" });
    }
  };

  const showResults = () => {
    if (!isLoading) {
      if (searchValues.searchType !== "basic") {
        return (
          <div className="col-12">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(newTabIndex) => setTabIndex(newTabIndex)}
            >
              <TabList>
                <Tab>{formatMessage({ id: "search.tab.decisionGuide" })}</Tab>
                <Tab>{getPersonalizedText()}</Tab>
                <Tab>{getBasicText()}</Tab>
              </TabList>
              <TabPanel>
                <DecisionGuide
                  onPersonalizedSearchClick={handlePersonalizedSearch}
                  isShowAll={searchValues.isShowAll}
                  organ={searchValues.organ}
                  donorType={searchValues.donorType}
                  distanceMiles={searchValues.distanceMiles}
                  distanceState={searchValues.distanceState}
                  distanceZipCode={searchValues.distanceZipCode}
                  recipientCriteriaSearchFields={
                    searchValues.recipientCriteriaSearchFields
                  }
                />
              </TabPanel>
              <TabPanel>
                <SearchResults
                  searchType="advanced"
                  organ={searchValues.organ}
                  recipientCriteriaSearchFields={
                    searchValues.recipientCriteriaSearchFields
                  }
                  otherCustomSearchFields={searchValues.otherCustomSearchFields}
                  deceasedDonorCriteriaSearch={
                    searchValues.donorOptionsSearchFields
                  }
                  results={searchResults}
                  isLoading={isLoading}
                  donorType={searchValues.donorType}
                />
              </TabPanel>
              <TabPanel>
                <SearchResults
                  searchType="basic"
                  organ={searchValues.organ}
                  results={searchResults}
                  isLoading={isLoading}
                />
              </TabPanel>
            </Tabs>
          </div>
        );
      } else {
        return (
          <div className="col-12 basic-search-page">
            <Tabs defaultIndex={0}>
              <TabList>
                <Tab>{formatMessage({ id: "search.tab.searchResults" })}</Tab>
              </TabList>
              <TabPanel>
                <SearchResults
                  searchType="basic"
                  organ={searchValues.organ}
                  results={searchResults}
                  isLoading={isLoading}
                />
              </TabPanel>
            </Tabs>
          </div>
        );
      }
    } else {
      return (
        <div className="col-12 mt-3">
          <Loading
            isLoading={isLoading}
            text={formatMessage({ id: "search.loading" })}
          />
        </div>
      );
    }
  };

  if (isValidUrl) {
    return (
      <div className="search">
        <div
          className="white-block rounded advanced-search row pb-3"
          ref={scrollRef}
        >
          <div className="col-12">
            <h1>{formatMessage({ id: "search.title" })}</h1>
          </div>
          <div className="col-12">
            <img
              alt="Line separator"
              className="img-fluid line"
              src={require("../../assets/images/line.png")}
            />
          </div>
          <div className="col-sm-12 col-md-8">
            {formatMessage(
              { id: "search.information" },
              {
                link: () => (
                  <Link to="/faq" target="_blank" rel="noopener noreferrer">
                    {formatMessage({ id: "navigation.faq" })}
                  </Link>
                ),
              }
            )}
          </div>
          <div className="col-sm-12 col-md-4 text-right">
            <Link
              to="/"
              className="d-print-none btn btn-no-radius btn-outline-purple rounded-top search-again"
            >
              <div className="row align-items-center justify-content-around">
                <div className="text-left">
                  {formatMessage({ id: "search.button.searchAgain" })}
                </div>
                <div className="btn-right-text">
                  <img
                    alt="Search Transplant Centers"
                    src={require("../../assets/images/arrow.png")}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row" ref={(node) => (nodeRef.current = node)}>
          {showResults()}
        </div>
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default Search;
