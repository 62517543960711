import React, { Component } from "react";
import { Link } from "react-router-dom";
import Check from "../../utilities/Check";
import { withIntl } from "../../../common/lang";

class GettingOnATransplantList extends Component {
  getCheck = () => {
    if (this.props.recipientCriteriaSearchFields.length > 0) {
      return (
        <div className="check">
          <Check />
        </div>
      );
    }
  };

  displayParams = () => {
    const { formatMessage } = this.props.intl;
    let recipientCriteriaSearchFields =
      this.props.recipientCriteriaSearchFields;

    return (
      <span>
        {recipientCriteriaSearchFields
          .map((selectedCustomSearchField) => selectedCustomSearchField.label)
          .join(` ${formatMessage({ id: "ui.label.and" })} `)}
      </span>
    );
  };

  getGettingOnATransplantList = () => {
    const { formatMessage } = this.props.intl;

    if (this.props.recipientCriteriaSearchFields.length > 0) {
      return (
        <div className="right-column-inner">
          <ul className=" d-flex flex-column justify-content-center h-100">
            <li>
              {formatMessage(
                {
                  id: "decisionGuide.section.transplantList.params",
                },
                {
                  params: () => this.displayParams(),
                }
              )}
            </li>
            <li>
              {formatMessage({
                id: "decisionGuide.section.transplantList.contact",
              })}
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className="right-column-inner">
          <ul className="d-flex flex-column justify-content-center h-100">
            <li>
              {formatMessage({
                id: "decisionGuide.section.transplantList.results",
              })}
            </li>
            <li>
              {formatMessage(
                {
                  id: "decisionGuide.section.transplantList.evaluation",
                },
                {
                  link: (c) => (
                    <Link to="/faq" target="_blank" rel="noopener noreferrer">
                      {c}
                    </Link>
                  ),
                }
              )}
            </li>
          </ul>
        </div>
      );
    }
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className="row no-gutters highlight">
        <div className="col-xm-12 col-md-2 left-column border-right-orange ">
          <div className="left-column-inner d-flex align-items-center h-100 ">
            {formatMessage({
              id: "decisionGuide.section.title.transplantList",
            })}
          </div>
        </div>
        <div className="col-sm-12 col-md-2 middle-column border-right-orange">
          <div className="middle-column-inner d-flex flex-column justify-content-center align-items-center h-100 ">
            {this.getCheck()}
          </div>
        </div>
        <div className="col-sm-12 col-md-8 right-column">
          {this.getGettingOnATransplantList()}
        </div>
      </div>
    );
  }
}

export default withIntl(GettingOnATransplantList);
