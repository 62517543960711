import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import DataService from "../../../services/DataService";

import Loading from "../../utilities/Loading";
import Select from "react-select";
import { useFormik } from "formik";
import { object, string } from "yup";

const importSchema = object({
  organ: string(),
  versionName: string(),
  versionDescription: string(),
});

const dataService = new DataService();

const Import = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [showError, setShowError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const uploadRef = useRef();

  const formik = useFormik({
    initialValues: {
      organ: "",
      versionName: "",
      versionDescription: "",
    },
    validationSchema: importSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      setLoadingText("Importing new data...");

      try {
        const formData = new FormData();
        formData.append("dataFile", uploadRef.current.files[0]);
        formData.append("organ", values.organ);
        formData.append("versionName", values.versionName);
        formData.append("versionDescription", values.versionDescription);

        dataService
          .importOrganData(formData)
          .then((data) => {
            if (data.status === "succeeded") {
              setIsLoading(false);
              setSuccess(true);
              setShowError(false);
            } else if (data.status === "failed") {
              setIsLoading(false);
              setErrors(data.body);
            }
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
            setSuccess(false);
            setShowError(true);
          });
      } catch (e) {
        console.error(e);
      }
    },
  });

  const renderErrors = () => {
    return errors.map((error, index) => (
      <div className="text-danger pb-2" key={index}>
        {error.description}
      </div>
    ));
  };

  const getPageContent = () => {
    if (isLoading) {
      return <Loading text={loadingText} isLoading={isLoading} />;
    } else if (success) {
      return (
        <div className="row mt-4">
          <div className="col-12">Successfully imported new data.</div>
        </div>
      );
    } else if (!isLoading && !showError) {
      const options = [
        { value: "heart", label: "Heart" },
        { value: "kidney", label: "Kidney" },
        { value: "liver", label: "Liver" },
        { value: "lung", label: "Lung" },
      ];

      return (
        <form onSubmit={formik.handleSubmit}>
          <div className="row mt-4">
            <div className="col-12">{renderErrors()}</div>
            <div className="col-6">
              <div className="form-group">
                <label id="organ">Organ</label>
                <Select
                  id="organ"
                  name="organ"
                  onChange={(e) => {
                    formik.setFieldValue("organ", e.value);
                  }}
                  onBlur={formik.handleBlur}
                  options={options}
                  classNamePrefix="field"
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="version-name">Version Name</label>
                <input
                  id="version-name"
                  type="text"
                  className="form-control"
                  name="versionName"
                  value={formik.versionName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="version-description">Version Description</label>
                <input
                  id="version-description"
                  type="text"
                  className="form-control"
                  name="versionDescription"
                  value={formik.versionDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="upload-input">Select a file</label>
                <input
                  type="file"
                  className="form-control-file"
                  id="upload-input"
                  name="uploadInput"
                  accept=".csv"
                  ref={(ref) => {
                    uploadRef.current = ref;
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <Link to="/admin/" className="btn btn-secondary float-right ml-2">
                Cancel
              </Link>
              <button className="btn btn-purple float-right" type="submit">
                Start Import
              </button>
            </div>
          </div>
        </form>
      );
    } else if (!isLoading && showError) {
      return (
        <div className="row mt-4">
          <div className="col">
            There was an error retrieving importing this data. Please try again.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="jumbotron  bg-white content-body">
      <h1>IMPORT NEW DATA SET</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../../assets/images/line.png")}
      />
      {getPageContent()}
    </div>
  );
};

export default Import;
