import React, { Component } from "react";
import Helper from "../../utilities/Helper";
import { withIntl } from "../../../common/lang";

class HIVPositive extends Component {
  componentDidUpdate = (prv) => {
    if (prv.hivPositive !== this.props.hivPositive) {
      this.props.setSelected(
        "hivPositive",
        this.checkHIVPositive(this.props.hivPositive)
      );
    }
  };

  handleHIVPositiveInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  hivPositiveHelper = () => {
    const { formatMessage } = this.props.intl;
    if (this.checkHIVPositive(this.props.hivPositive)) {
      return <Helper text={formatMessage({ id: "form.hivPositive.helper" })} />;
    }
  };

  checkHIVPositive = (hivPositive) => {
    if (hivPositive === "yes") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.hivPositive" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select search-select-md"
              name="hivPositive"
              onChange={this.handleHIVPositiveInputChange}
              value={this.props.hivPositive}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="yes">{formatMessage({ id: "form.yes" })}</option>
              <option value="no">{formatMessage({ id: "form.no" })}</option>
              <option value="notSure">
                {formatMessage({ id: "form.notSure" })}
              </option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">{this.hivPositiveHelper()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(HIVPositive);
