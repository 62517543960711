import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserService from "../../../services/UserService";
import Loading from "../../utilities/Loading";

const userService = new UserService();

const DeleteUser = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [showError, setShowError] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    setIsLoading(true);
    setLoadingText("Getting user...");

    userService
      .getUser(userId)
      .then((data) => {
        setIsLoading(false);
        setShowError(false);

        setUser({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          companyName: data.companyName,
          isActive: data.isActive,
          isAdmin: data.isAdmin,
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setShowError(true);
      });
  }, [userId]);

  const handleDeleteClick = () => {
    setIsLoading(true);
    setLoadingText("Deleting user...");

    userService
      .deleteUser(userId)
      .then(() => {
        setIsLoading(false);

        navigate("/admin/users");
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const isActive = () => {
    if (user.isActive) {
      return "True";
    } else {
      return "False";
    }
  };

  const isAdmin = () => {
    if (user.isAdmin) {
      return "Administrator";
    } else {
      return "User";
    }
  };

  const displayCompany = () => {
    if (!user.isAdmin) {
      return (
        <div className="form-group">
          <label>Company Name</label>
          <br />
          {user.companyName}
        </div>
      );
    }
  };

  const renderPageContent = () => {
    if (isLoading) {
      return <Loading text={loadingText} isLoading={isLoading} />;
    } else if (!isLoading && !showError) {
      return (
        <div className="row mt-4">
          <div className="col">
            <p>Are you sure you want to delete this user?</p>
          </div>
          <div className="col-12">
            {displayCompany()}
            <div className="form-group">
              <label>First Name:&nbsp; </label>
              {user.firstName}
            </div>
            <div className="form-group">
              <label>Last Name: &nbsp;</label>
              {user.lastName}
            </div>
            <div className="form-group">
              <label>Email address:&nbsp; </label>
              {user.email}
            </div>
            <div className="form-group">
              <label>Active:&nbsp; </label>
              {isActive()}
            </div>
            <div className="form-group">
              <label>User Type:&nbsp; </label>
              {isAdmin()}
            </div>
          </div>
          <div className="col">
            <Link
              to="/admin/users"
              className="btn btn-secondary float-right ml-2"
            >
              Cancel
            </Link>
            <button
              className="btn btn-purple float-right"
              onClick={handleDeleteClick}
            >
              Delete User
            </button>
          </div>
        </div>
      );
    } else if (!this.state.isLoading && this.state.showError) {
      return (
        <div className="row mt-4">
          <div className="col">
            There was an error retrieving this user. Please try again.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="jumbotron bg-white content-body">
      <h1>DELETE USER</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../../assets/images/line.png")}
      />
      {renderPageContent()}
    </div>
  );
};

export default DeleteUser;
