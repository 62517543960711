import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setOrgan, setDonorType, setDistanceMiles } from "../actions";
import State from "../components/utilities/State";
import OrganService from "../services/OrganService";
import SearchService from "../services/SearchService";
import { store } from "../store";

const Home = (props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    organSelected: true,
    donorTypeSelected: true,
    distanceZipCode: "",
    distanceState: "",
  });

  const getOrganDisplay = () => {
    let organDisplay = formatMessage({ id: "ui.label.organ" });

    if (props.organ && props.organDisplay) {
      organDisplay = formatMessage({ id: `organs.${props.organ}` });
    }

    return organDisplay;
  };

  const getDonorTypeDisplay = () => {
    let donorTypeDisplay = formatMessage({ id: "donorTypes.default" });

    if (props.donorType && props.organDisplay) {
      donorTypeDisplay = formatMessage({ id: `donorTypes.${props.donorType}` });
    }

    return donorTypeDisplay;
  };

  useEffect(() => {
    const organService = new OrganService();
    const searchService = new SearchService();

    const organ = organService.getOrgan();
    const distanceMiles = searchService.getDistanceMiles();
    const donorType = searchService.getDonorType();

    if (organ) {
      store.dispatch(setOrgan(organ.name, organ.display));
      setForm((prevFormValue) => ({
        ...prevFormValue,
        organSelected: true,
      }));
    }

    if (donorType) {
      store.dispatch(setDonorType(donorType.name, donorType.display));
      setForm((prevFormValue) => ({
        ...prevFormValue,
        donorTypeSelected: true,
      }));
    }

    const distanceZipCode = searchService.getSearchParameter("distanceZipCode");
    let distanceState = searchService.getSearchParameter("distanceState");

    store.dispatch(setDistanceMiles(distanceMiles));

    setForm((prevFormValue) => ({
      ...prevFormValue,
      distanceState,
      distanceZipCode,
    }));
  }, []);

  const handleOrganClick = (e) => {
    const searchService = new SearchService();
    const organService = new OrganService();

    const organ = {
      name: e.currentTarget.dataset.id,
      display: e.currentTarget.innerText,
    };
    organService.setOrgan(organ);
    setForm((prevFormValue) => ({
      ...prevFormValue,
      organSelected: true,
    }));

    store.dispatch(setOrgan(organ.name, organ.display));
    let distanceMiles = "";
    if (organ.name === "kidney") {
      distanceMiles = "50";
    } else {
      distanceMiles = "100";
    }

    if (organ.name === "heart" || organ.name === "lung") {
      const donorType = {
        name: "deceased",
        display: "Deceased Donor",
      };
      store.dispatch(setDonorType(donorType.name, donorType.display));
      setForm((prevFormValue) => ({
        ...prevFormValue,
        donorTypeSelected: true,
      }));
      searchService.setDonorType(donorType);
    }

    store.dispatch(setDistanceMiles(distanceMiles));
    searchService.setSearchParameter("distanceMiles", distanceMiles);
  };

  const handleDonorTypeClick = (e) => {
    const searchService = new SearchService();

    const donorType = {
      name: e.currentTarget.dataset.id,
      display: e.currentTarget.innerText,
    };
    setForm((prevFormValue) => ({
      ...prevFormValue,
      donorTypeSelected: true,
    }));

    store.dispatch(setDonorType(donorType.name, donorType.display));
    searchService.setDonorType(donorType);
  };

  const doSearch = () => {
    if (props.organ && props.donorType) {
      let mainUrl = `/search?organ=${props.organ}`;
      if (form.distanceZipCode !== "") {
        mainUrl += `&distanceZipCode=${form.distanceZipCode}`;
      }
      if (props.distanceMiles !== "") {
        mainUrl += `&distanceMiles=${props.distanceMiles}`;
      }
      if (form.distanceState !== "") {
        mainUrl += `&distanceState=${form.distanceState}`;
      }
      if (props.donorType !== "") {
        mainUrl += `&donorType=${props.donorType}`;
      }
      navigate(mainUrl);
    } else {
      if (!props.organ) {
        setForm((prevFormValue) => ({
          ...prevFormValue,
          organSelected: false,
        }));
      }
      if (!props.donorType) {
        setForm((prevFormValue) => ({
          ...prevFormValue,
          donorTypeSelected: false,
        }));
      }
    }
  };

  const handleDistanceMilesInputChange = (event) => {
    const searchService = new SearchService();
    const distanceMiles = event.target.value;
    store.dispatch(setDistanceMiles(distanceMiles));
    searchService.setSearchParameter("distanceMiles", distanceMiles);
  };

  const handleDistanceStateInputChange = (event) => {
    const searchService = new SearchService();
    const value = event.target.value;
    setForm((prevFormValue) => ({
      ...prevFormValue,
      distanceState: event.target.value,
    }));

    searchService.setSearchParameter("distanceState", value);
  };

  const handleDistanceZipCodeInputChange = (event) => {
    const searchService = new SearchService();
    const value = event.target.value;
    setForm((prevFormValue) => ({
      ...prevFormValue,
      distanceZipCode: value,
    }));

    searchService.setSearchParameter("distanceZipCode", value);
  };

  const renderOrgans = () => {
    return (
      <div className="dropdown-menu" aria-labelledby="organDropDownButton">
        <div
          className="dropdown-item"
          data-id="heart"
          onClick={handleOrganClick}
        >
          {formatMessage({ id: "organs.heart" })}
        </div>
        <div className="dropdown-divider" />
        <div
          className="dropdown-item"
          data-id="kidney"
          onClick={handleOrganClick}
        >
          {formatMessage({ id: "organs.kidney" })}
        </div>
        <div className="dropdown-divider" />
        <div
          className="dropdown-item"
          data-id="liver"
          onClick={handleOrganClick}
        >
          {formatMessage({ id: "organs.liver" })}
        </div>
        <div className="dropdown-divider" />
        <div
          className="dropdown-item"
          data-id="lung"
          onClick={handleOrganClick}
        >
          {formatMessage({ id: "organs.lung" })}
        </div>
      </div>
    );
  };

  return (
    <div className="inner-container">
      <div className="row">
        <div className="col-12 white-block rounded home">
          <img
            alt="Transplant Center Search"
            className="hand"
            src={require("../assets/images/hand.png")}
          />
          <h1 className="heading">{formatMessage({ id: "home.title" })}</h1>
          <img
            alt="Line separator"
            className="img-fluid line"
            src={require("../assets/images/line.png")}
          />
          <div className="home-container">
            <div className="home-text">
              {formatMessage({ id: "home.info" })}
            </div>
            <div
              className={
                "home-text required-fields text-danger pt-2 " +
                (form.donorTypeSelected && form.organSelected ? " d-none" : "")
              }
            >
              {formatMessage({ id: "home.form.requiredFields" })}
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 col-md-auto action-row dropdown-row">
                <div className="dropdown h-100 select-organ">
                  <div className="btn-group h-100">
                    <button
                      className="d-print-none btn btn-no-radius btn-split-grey rounded-top pt-0 pb-0 h-100"
                      type="button"
                      data-toggle="dropdown"
                      id="organDropDownButton"
                    >
                      <div className="row align-items-center justify-content-between h-100">
                        <div
                          className={
                            "btn-split-left" +
                            (!form.organSelected ? " text-danger" : "")
                          }
                        >
                          {getOrganDisplay()}
                        </div>
                        <div className="btn-split-right h-100">
                          <img
                            className="mx-auto d-block"
                            alt="Select Organ"
                            src={require("../assets/images/arrow_down.png")}
                          />
                        </div>
                      </div>
                    </button>
                    {renderOrgans()}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-auto action-row dropdown-row">
                <div className="dropdown h-100 select-organ">
                  <div className="btn-group h-100">
                    <button
                      className="d-print-none btn btn-no-radius btn-split-grey rounded-top pt-0 pb-0 h-100"
                      type="button"
                      data-toggle="dropdown"
                      id="donorTypeDropDownButton"
                    >
                      <div className="row align-items-center justify-content-between h-100">
                        <div
                          className={
                            "btn-split-left-donor" +
                            (!form.donorTypeSelected ? " text-danger" : "")
                          }
                        >
                          {getDonorTypeDisplay()}
                        </div>
                        <div className="btn-split-right h-100">
                          <img
                            className="mx-auto d-block"
                            alt="Select Donor Type"
                            src={require("../assets/images/arrow_down.png")}
                          />
                        </div>
                      </div>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="donorTypeDropDownButton"
                    >
                      <div
                        className={
                          "dropdown-item" +
                          (props.organ === "heart" || props.organ === "lung"
                            ? " disabled"
                            : "")
                        }
                        data-id="living"
                        onClick={handleDonorTypeClick}
                      >
                        {formatMessage({ id: "donorTypes.living" })}
                      </div>
                      <div className="dropdown-divider" />
                      <div
                        className="dropdown-item"
                        data-id="deceased"
                        onClick={handleDonorTypeClick}
                      >
                        {formatMessage({ id: "donorTypes.deceased" })}
                      </div>
                      <div className="dropdown-divider" />
                      <div
                        className={
                          "dropdown-item" +
                          (props.organ === "heart" || props.organ === "lung"
                            ? " disabled"
                            : "")
                        }
                        data-id="both"
                        onClick={handleDonorTypeClick}
                      >
                        {formatMessage({ id: "donorTypes.both" })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-4">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="search-label">
                  {formatMessage({ id: "form.distance.title" })}
                </div>
              </div>
              <div className="col-12">
                <div className="row travel">
                  <div className="col">
                    <div className="travel-row">
                      <div className="travel-row">
                        {formatMessage({ id: "form.text.within" })}
                      </div>
                      <select
                        className="form-control inline-select"
                        name="distanceMiles"
                        onChange={handleDistanceMilesInputChange}
                        value={props.distanceMiles}
                      >
                        <option value="">
                          {formatMessage({ id: "form.distance.any" })}
                        </option>
                        <option value="50">
                          {formatMessage({ id: "form.distance.50" })}
                        </option>
                        <option value="100">
                          {formatMessage({ id: "form.distance.100" })}
                        </option>
                        <option value="250">
                          {formatMessage({ id: "form.distance.250" })}
                        </option>
                        <option value="500">
                          {formatMessage({ id: "form.distance.500" })}
                        </option>
                        <option value="700">
                          {formatMessage({ id: "form.distance.700" })}
                        </option>
                      </select>
                    </div>
                    <div className="travel-row">
                      {formatMessage({ id: "form.text.of" })}{" "}
                      <input
                        className="form-control inline-search-zipcode"
                        name="distanceZipCode"
                        placeholder={formatMessage({
                          id: "form.zipCode.placeholder",
                        })}
                        onChange={handleDistanceZipCodeInputChange}
                        value={form.distanceZipCode}
                        maxLength="5"
                      />
                    </div>
                    <div className="travel-row">
                      {formatMessage({ id: "form.text.or" })}{" "}
                      <State
                        onChange={handleDistanceStateInputChange}
                        value={form.distanceState}
                        additionalClass="inline-select-state"
                      />
                    </div>
                    <div className="travel-row">
                      <button
                        className="d-print-none btn btn-no-radius btn-outline-purple rounded-top pr-0 step"
                        type="button"
                        onClick={() => doSearch()}
                      >
                        <div className="row align-items-center justify-content-around">
                          <div className="text-left u-pre-line">
                            {formatMessage({ id: "home.button.submit" })}
                          </div>
                          <div className="btn-right-text pr-3">
                            <img
                              alt="Search Transplant Centers"
                              src={require("../assets/images/arrow.png")}
                            />
                          </div>
                        </div>
                      </button>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="sponsor">{formatMessage({ id: "home.sponsor" })}</div>
        </div>
        <div className="col-12">
          <div className="disclaimer">
            * {formatMessage({ id: "home.disclaimer" })}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  let donorTypeDisplay = "Donor Type";
  let donorType = state.donorType;

  if (state.organ === "heart" || state.organ === "lung") {
    donorType = "deceased";
    donorTypeDisplay = "Deceased Donor";
  } else if (state.donorTypeDisplay) {
    donorTypeDisplay = state.donorTypeDisplay;
  }

  return {
    organ: state.organ,
    organDisplay: state.organDisplay,
    donorType: donorType,
    donorTypeDisplay: donorTypeDisplay,
    distanceMiles: state.distanceMiles,
  };
}

export default connect(mapStateToProps)(Home);
