import React from "react";
import { useIntl } from "react-intl";

import Check from "../../utilities/Check";

const GettingADeceasedDonor = (props) => {
  const { formatMessage } = useIntl();

  const getCheck = (donorType) => {
    if (props.donorType === donorType || props.donorType === "both") {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <div className="check">
            <Check />
          </div>
          <div className="donor-type">
            {formatMessage({
              id: `donorTypes.${donorType}`,
            }).toLocaleLowerCase()}
          </div>
        </div>
      );
    }
  };

  const getSectionTitle = () => {
    if (props.organ === "heart") {
      return formatMessage({ id: "decisionGuide.section.title.heart" });
    } else if (props.organ === "lung") {
      return formatMessage({ id: "decisionGuide.section.title.lung" });
    } else {
      return formatMessage({ id: "decisionGuide.section.title" });
    }
  };

  const getGettingTransplantFasterText = () => {
    if (props.organ === "lung") {
      return (
        <div className="right-column-inner ">
          <div className="title"></div>
          <ul className="d-flex flex-column justify-content-center h-100">
            <li>{formatMessage({ id: "decisionGuide.text.lung" })}</li>
          </ul>
        </div>
      );
    } else {
      if (props.donorType === "deceased" || props.donorType === "both") {
        return (
          <div className="right-column-inner ">
            <div
              className={"title " + (props.organ === "heart" ? "d-none" : "")}
            >
              {formatMessage({ id: "decisionGuide.text.deceased1" })}
            </div>
            <ul className=" d-flex flex-column justify-content-center h-100">
              <li>
                {formatMessage({
                  id: `decisionGuide.text.deceased2.${props.organ}`,
                })}
              </li>
            </ul>
          </div>
        );
      } else {
        return (
          <div className="right-column-inner ">
            <div className="title">
              {formatMessage({ id: "decisionGuide.text.living1" })}
            </div>
            <ul className="d-flex flex-column justify-content-center h-100">
              <li>{formatMessage({ id: "decisionGuide.text.living2" })}</li>
            </ul>
          </div>
        );
      }
    }
  };

  return (
    <div className="row no-gutters highlight border-top-orange highlight no-top-radius">
      <div className="col-sm-10 col-md-2 left-column border-right-orange ">
        <div className="left-column-inner d-flex align-items-center h-100 ">
          {getSectionTitle()}
        </div>
      </div>
      <div className="col-sm-2 col-md-2 middle-column border-right-orange">
        <div className="middle-column-inner d-flex flex-column justify-content-center align-items-center h-100">
          {getCheck("deceased")}
        </div>
      </div>
      <div className="col-sm-12 col-md-8 right-column">
        {getGettingTransplantFasterText()}
      </div>
    </div>
  );
};

export default GettingADeceasedDonor;
