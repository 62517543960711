import React from "react";
import { useIntl } from "react-intl";

const IncreasedInfectiousRiskDescriptionHeart = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="title pl-0">
          {formatMessage({ id: "utils.infectionRisk.title" })}:
        </div>
        {formatMessage({ id: "utils.infectionRisk.heart.description" })}
      </div>
    </div>
  );
};

export default IncreasedInfectiousRiskDescriptionHeart;
