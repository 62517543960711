import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { renderFormError } from "../../common/utils/form";
import AccountService from "../../services/AccountService";
import AuthService from "../../services/AuthService";
import Loading from "../utilities/Loading";
import { object, ref, string } from "yup";

const confirmEmailSchema = object({
  password: string()
    .min(8, "Password should be at least 8 characters")
    .required("Password is required"),
  confirmPassword: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [showError, setShowError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [user, setUser] = useState({
    userId: "",
    code: "",
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: confirmEmailSchema,
    onSubmit: (values) => {
      const { userId, code } = user;
      const { password } = values;

      setIsLoading(true);
      setLoadingText("Saving your password...");

      const accountService = new AccountService();

      accountService
        .setInitialPassword(userId, code, password)
        .then((data) => {
          if (data.status === "succeeded") {
            setIsLoading(false);
            setShowError(false);
            setIsSuccess(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          setShowError(true);
        });
    },
  });

  useEffect(() => {
    const authService = new AuthService();
    if (authService.loggedIn()) {
      navigate("/", { replace: true });
    } else {
      setIsLoading(true);
      setLoadingText("");
      const userId = searchParams.get("userId");
      const code = searchParams.get("code");

      const accountService = new AccountService();
      accountService
        .confirmUser(userId, code)
        .then((data) => {
          if (data.status === "already-confirmed") {
            navigate("/login", { replace: true });
          } else {
            //After the user is confirmed set up the state to handle the set initial password function
            setIsLoading(false);
            setShowError(false);
            setUser({
              userId: data.userId,
              code: data.code,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          setShowError(true);
        });
    }
    // eslint-disable-next-line
  }, []);

  const renderPageContent = () => {
    if (isLoading) {
      return <Loading text={loadingText} isLoading={isLoading} />;
    } else if (isSuccess) {
      return (
        <div className="row mt-4">
          <div className="col-12">
            <p>
              Your password has been set successfully. You can now log using
              your email and password.
            </p>
          </div>
          <div className="col">
            <Link className="btn btn-purple" to="/admin">
              Log In
            </Link>
          </div>
        </div>
      );
    } else if (!showError) {
      return (
        <div className="row mt-4">
          <div className="col-12">
            <p>
              Your user account has been successfully confirmed. Please create a
              password for your account now.
            </p>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                className="form-control"
                type="password"
                name="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {renderFormError(formik, "password")}
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                id="confirm-password"
                className="form-control"
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {renderFormError(formik, "confirmPassword")}
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <span>
              <div>
                Passwords requirements:
                <ul>
                  <li>At least 8 characters</li>
                  <li>Contain at least one digit</li>
                  <li>Contain at least one special character(! @ % #)</li>
                  <li>Contain at least four unique characters</li>
                </ul>
              </div>
            </span>
          </div>
          <div className="col-12">
            <button
              className="btn btn-purple float-right"
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            >
              Save
            </button>
          </div>
        </div>
      );
    } else if (showError) {
      return (
        <div className="row mt-4">
          <div className="col-12">
            <p>
              There was a problem setting your password. Please request a
              password reset.
            </p>
          </div>
          <div className="col">
            <Link className="btn btn-purple" to="/account/forgotpassword">
              Reset your password
            </Link>
          </div>
        </div>
      );
    } else {
      return <p>We could not confirm your email account</p>;
    }
  };

  return (
    <div className="jumbotron  bg-white content-body">
      <h1>CONFIRM YOUR USER ACCOUNT</h1>
      <img
        alt="Line separator"
        className="img-fluid line"
        src={require("../../assets/images/line.png")}
      />
      {renderPageContent()}
    </div>
  );
};

export default ConfirmEmail;
