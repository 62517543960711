
export function setOrgan(organ, organDisplay) {
   return {
      type: "SET_ORGAN",
      organ: {
         name: organ,
         display: organDisplay
      }
   }
}

export function setDonorType(donorType, donorTypeDisplay) {
   return {
      type: "SET_DONORTYPE",
      donorType: {
         name: donorType,
         display: donorTypeDisplay
      }
   }
}

export function setDistanceMiles(distanceMiles) {
   return {
      type: "SET_DISTANCE",
      distanceMiles: distanceMiles
   }
}
