import React from "react";
import { useIntl } from "react-intl";

const State = (props) => {
  const { formatMessage } = useIntl();

  let additionalClass = "form-control ";
  if (
    typeof props.additionalClass != "undefined" &&
    props.additionalClass.length > 0
  ) {
    additionalClass += props.additionalClass;
  }

  return (
    <select
      className={additionalClass}
      name="state"
      onChange={props.onChange}
      value={props.value}
    >
      <option value="">{formatMessage({ id: "state.option.select" })}</option>
      <option value="AL">{formatMessage({ id: "state.option.AL" })}</option>
      <option value="AK">{formatMessage({ id: "state.option.AK" })}</option>
      <option value="AZ">{formatMessage({ id: "state.option.AZ" })}</option>
      <option value="AR">{formatMessage({ id: "state.option.AR" })}</option>
      <option value="CA">{formatMessage({ id: "state.option.CA" })}</option>
      <option value="CO">{formatMessage({ id: "state.option.CO" })}</option>
      <option value="CT">{formatMessage({ id: "state.option.CT" })}</option>
      <option value="DE">{formatMessage({ id: "state.option.DE" })}</option>
      <option value="DC">{formatMessage({ id: "state.option.DC" })}</option>
      <option value="FL">{formatMessage({ id: "state.option.FL" })}</option>
      <option value="GA">{formatMessage({ id: "state.option.GA" })}</option>
      <option value="HI">{formatMessage({ id: "state.option.HI" })}</option>
      <option value="ID">{formatMessage({ id: "state.option.ID" })}</option>
      <option value="IL">{formatMessage({ id: "state.option.IL" })}</option>
      <option value="IN">{formatMessage({ id: "state.option.IN" })}</option>
      <option value="IA">{formatMessage({ id: "state.option.IA" })}</option>
      <option value="KS">{formatMessage({ id: "state.option.KS" })}</option>
      <option value="KY">{formatMessage({ id: "state.option.KY" })}</option>
      <option value="LA">{formatMessage({ id: "state.option.LA" })}</option>
      <option value="ME">{formatMessage({ id: "state.option.ME" })}</option>
      <option value="MD">{formatMessage({ id: "state.option.MD" })}</option>
      <option value="MA">{formatMessage({ id: "state.option.MA" })}</option>
      <option value="MI">{formatMessage({ id: "state.option.MI" })}</option>
      <option value="MN">{formatMessage({ id: "state.option.MN" })}</option>
      <option value="MS">{formatMessage({ id: "state.option.MS" })}</option>
      <option value="MO">{formatMessage({ id: "state.option.MO" })}</option>
      <option value="MT">{formatMessage({ id: "state.option.MT" })}</option>
      <option value="NE">{formatMessage({ id: "state.option.NE" })}</option>
      <option value="NV">{formatMessage({ id: "state.option.NV" })}</option>
      <option value="NH">{formatMessage({ id: "state.option.NH" })}</option>
      <option value="NJ">{formatMessage({ id: "state.option.NJ" })}</option>
      <option value="NM">{formatMessage({ id: "state.option.NM" })}</option>
      <option value="NY">{formatMessage({ id: "state.option.NY" })}</option>
      <option value="NC">{formatMessage({ id: "state.option.NC" })}</option>
      <option value="ND">{formatMessage({ id: "state.option.ND" })}</option>
      <option value="OH">{formatMessage({ id: "state.option.OH" })}</option>
      <option value="OK">{formatMessage({ id: "state.option.OK" })}</option>
      <option value="OR">{formatMessage({ id: "state.option.OR" })}</option>
      <option value="PA">{formatMessage({ id: "state.option.PA" })}</option>
      <option value="RI">{formatMessage({ id: "state.option.RI" })}</option>
      <option value="SC">{formatMessage({ id: "state.option.SC" })}</option>
      <option value="SD">{formatMessage({ id: "state.option.SD" })}</option>
      <option value="TN">{formatMessage({ id: "state.option.TN" })}</option>
      <option value="TX">{formatMessage({ id: "state.option.TX" })}</option>
      <option value="UT">{formatMessage({ id: "state.option.UT" })}</option>
      <option value="VT">{formatMessage({ id: "state.option.VT" })}</option>
      <option value="VA">{formatMessage({ id: "state.option.VA" })}</option>
      <option value="WA">{formatMessage({ id: "state.option.WA" })}</option>
      <option value="WV">{formatMessage({ id: "state.option.WV" })}</option>
      <option value="WI">{formatMessage({ id: "state.option.WI" })}</option>
      <option value="WY">{formatMessage({ id: "state.option.WY" })}</option>
    </select>
  );
};

export default State;
