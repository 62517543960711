import React, { Component } from "react";
import Helper from "../../utilities/Helper";
import { withIntl } from "../../../common/lang";

class PreviousLiverTransplant extends Component {
  componentDidUpdate = (prv) => {
    if (prv.previousLiverTransplant !== this.props.previousLiverTransplant) {
      this.props.setSelected(
        "previousLiverTransplant",
        this.checkPreviousLiverTransplant(this.props.previousLiverTransplant)
      );
    }
  };

  handlePreviousLiverTransplantInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  previousLiverTransplantHelper = () => {
    const { formatMessage } = this.props.intl;
    if (this.checkPreviousLiverTransplant(this.props.previousLiverTransplant)) {
      return (
        <Helper
          text={formatMessage({ id: "form.previousLiverTransplant.helper" })}
        />
      );
    }
  };

  checkPreviousLiverTransplant = (previousLiverTransplant) => {
    if (previousLiverTransplant === "yes") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.previousLiverTransplant" })}
            </div>
          </div>
          <div className="col-12">
            <select
              className="form-control search-select search-select-md"
              name="previousLiverTransplant"
              onChange={this.handlePreviousLiverTransplantInputChange}
              value={this.props.previousLiverTransplant}
            >
              <option value="">
                {formatMessage({ id: "ui.label.select" })}
              </option>
              <option value="yes">{formatMessage({ id: "form.yes" })}</option>
              <option value="no">{formatMessage({ id: "form.no" })}</option>
            </select>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">
              {this.previousLiverTransplantHelper()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(PreviousLiverTransplant);
