import AuthService from './AuthService';
const Host = process.env.REACT_APP_API_HOST;

export default class DataService {
    constructor(domain) {
        this.domain = domain || Host;
        this.Auth = new AuthService();
    }

    async importOrganData(formData) {
        return await this.Auth.fetchWithCreds(`${this.domain}/data/import`, {
            method: 'POST',
            body: formData
        }, 
        false);
    }
}