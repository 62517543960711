import React, { Component } from "react";

import { withIntl } from "../../../common/lang";
import Helper from "../../utilities/Helper";

class Age extends Component {
  componentDidUpdate = (prv) => {
    if (prv.age !== this.props.age) {
      this.props.setSelected("age", this.checkAge(this.props.age));
    }
  };

  handleAgeInputChange = (event) => {
    this.props.handleOnChange(event);
  };

  ageHelper = () => {
    const { formatMessage } = this.props.intl;
    if (this.props.age && this.props.age < 18) {
      return (
        <Helper text={formatMessage({ id: "form.age.helper.lessThan18" })} />
      );
    } else if (this.checkAge(this.props.age)) {
      return (
        <Helper text={formatMessage({ id: "form.age.helper.ageCriteria" })} />
      );
    } else if (this.checkAgeRange(this.props.age)) {
      return (
        <Helper text={formatMessage({ id: "form.age.helper.closeTo70" })} />
      );
    }
  };

  checkAge = (age) => {
    if (this.props.organ === "kidney" || this.props.organ === "lung") {
      if (age >= 70) {
        return true;
      } else {
        return false;
      }
    } else {
      if (age >= 65) {
        return true;
      } else {
        return false;
      }
    }
  };

  checkAgeRange = (age) => {
    if (age >= 65 && age < 70) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className="col-12 element-group">
        <div className="row">
          <div className="col-12">
            <div className="search-label">
              {formatMessage({ id: "form.age" })}
            </div>
          </div>
          <div className="col-12">
            <input
              className="form-control inline-search-age"
              name="age"
              placeholder={formatMessage({ id: "form.age.placeholder" })}
              onChange={this.handleAgeInputChange}
              value={this.props.age}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="helper-text">{this.ageHelper()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withIntl(Age);
