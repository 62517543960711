import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";

import { useIntl as useIntlLocal } from "../../common/lang";
import AuthService from "../../services/AuthService";

const LANGUAGES = {
  en: "English",
  es: "Español",
};
const getLanguageValue = (value) => {
  return { value, label: LANGUAGES[value] };
};

const Navigation = (props) => {
  const { getLocale, updateLocale } = useIntlLocal();
  const { formatMessage } = useIntl();
  const authService = new AuthService();

  const handleLocaleChange = (e) => {
    updateLocale(e.value);
  };

  const getOrganDisplay = () => {
    const organText =
      props.organ && props.organDisplay
        ? formatMessage({ id: `organs.${props.organ}` })
        : formatMessage({ id: "navigation.organ.none" });
    return `${formatMessage({ id: "navigation.organ" })}: ${organText}`;
  };

  const userLinks = () => {
    if (authService.loggedIn() && authService.isAdmin()) {
      return (
        <ul className="navbar-nav ml-auto">
          <li className="nav-item d-flex align-items-center">
            <NavLink className="nav-link" to="/admin">
              Admin
            </NavLink>
          </li>
          <li className="nav-item d-flex align-items-center">
            <Link
              className="nav-link"
              to="/login"
              onClick={() => props.onLogout()}
            >
              Logout
            </Link>
          </li>
        </ul>
      );
    } else if (authService.loggedIn()) {
      return (
        <ul className="navbar-nav ml-auto">
          <li className="nav-item d-flex align-items-center">
            <Link
              className="nav-link"
              to="/login"
              onClick={() => props.onLogout()}
            >
              Logout
            </Link>
          </li>
        </ul>
      );
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-purple d-print-none">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarToggler">
        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
          <li
            className="nav-item d-flex align-items-center"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <NavLink end className="nav-link text-wrap" to="/">
              {formatMessage({ id: "navigation.home" })}
            </NavLink>
          </li>
          <li
            className="nav-item d-flex align-items-center nav-link cursor-default"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            {getOrganDisplay()}
          </li>
          <li
            className="nav-item d-flex align-items-center"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <NavLink to="/search/basic" className="nav-link text-wrap">
              {formatMessage({ id: "navigation.basicSearch" })}
            </NavLink>
          </li>
          <li
            className="nav-item d-flex align-items-center"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <Link to="/" className="nav-link text-wrap">
              {formatMessage({ id: "navigation.personalizedSearch" })}
            </Link>
          </li>
          <li
            className="nav-item d-flex align-items-center text-wrap"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <NavLink className="nav-link text-wrap" to="/feedback">
              {formatMessage({ id: "navigation.feedback" })}
            </NavLink>
          </li>
          <li
            className="nav-item d-flex align-items-center text-wrap"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <NavLink className="nav-link text-wrap" to="/faq">
              {formatMessage({ id: "navigation.faq" })}
            </NavLink>
          </li>
        </ul>
        {userLinks()}
      </div>

      <Select
        className="select-dropdown navigation__language form-inline"
        options={[
          { value: "en", label: LANGUAGES.en },
          { value: "es", label: LANGUAGES.es },
        ]}
        defaultValue={getLanguageValue(getLocale())}
        placeholder={formatMessage({ id: "navigation.language" })}
        onChange={handleLocaleChange}
        styles={{
          container: (style) => ({
            ...style,
            backgroundColor: "transparent",
            minWidth: "125px",
          }),
        }}
      />
    </nav>
  );
};

function mapStateToProps(state) {
  return {
    organ: state.organ,
    organDisplay: state.organDisplay,
  };
}

export default connect(mapStateToProps)(Navigation);
