import AuthService from "./AuthService";
const Host = process.env.REACT_APP_API_HOST;

export default class UserService {
  constructor(domain) {
    this.domain = domain || Host;
    this.Auth = new AuthService();
  }

  async getUsers() {
    return this.Auth.fetchWithCreds(`${this.domain}/users`, {
      method: "GET",
    });
  }

  async getUser(id) {
    return await this.Auth.fetchWithCreds(`${this.domain}/users/${id}`, {
      method: "GET",
    });
  }

  async updateUser(userId, firstName, lastName, email, isActive) {
    return await this.Auth.fetchWithCreds(`${this.domain}/users/update`, {
      method: "POST",
      body: JSON.stringify({
        userId,
        firstName,
        lastName,
        email,
        isActive,
      }),
    });
  }

  async addUser(firstName, lastName, email, companyId) {
    return await this.Auth.fetchWithCreds(`${this.domain}/users/create`, {
      method: "POST",
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        companyId,
      }),
    });
  }

  async deleteUser(userId) {
    return await this.Auth.fetchWithCreds(`${this.domain}/users/delete`, {
      method: "POST",
      body: JSON.stringify({
        userId,
      }),
    });
  }

  async getCompanies() {
    return await this.Auth.fetchWithCreds(`${this.domain}/companies/`, {
      method: "GET",
    });
  }
}
