import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import Dashboard from "../../components/admin/Dashboard";
import Users from "../../components/admin/users/Users";
import AddUser from "../../components/admin/users/AddUser";
import EditUser from "../../components/admin/users/EditUser";
import DeleteUser from "../../components/admin/users/DeleteUser";
import AuthService from "../../services/AuthService";

import Companies from "../../components/admin/companies/Companies";
import AddCompany from "../../components/admin/companies/AddCompany";
import EditCompany from "../../components/admin/companies/EditCompany";
import DeleteCompany from "../../components/admin/companies/DeleteCompany";

import FeedbackList from "../../components/admin/feedback/FeedbackList";
import ViewFeedback from "../../components/admin/feedback/ViewFeedback";
import DeleteFeedback from "../../components/admin/feedback/DeleteFeedback";

import Import from "../../components/admin/data/Import";
import PrivateRoute from "../../components/auth/PrivateRoute";

export const Admin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authService = new AuthService();

    if (authService.loggedIn() && !authService.isAdmin()) {
      navigate("/");
    }
  });

  return (
    <div>
      <Routes>
        <Route path="*" element={<PrivateRoute />}>
          <Route exact path="data/import" element={<Import />} />
          <Route exact path="companies" element={<Companies />} />
          <Route exact path="companies/add" element={<AddCompany />} />
          <Route exact path="companies/edit/:id" element={<EditCompany />} />
          <Route
            exact
            path="companies/delete/:id"
            element={<DeleteCompany />}
          />
          <Route exact path="users" element={<Users />} />
          <Route exact path="users/add" element={<AddUser />} />
          <Route exact path="users/edit/:id" element={<EditUser />} />
          <Route exact path="users/delete/:id" element={<DeleteUser />} />
          <Route exact path="feedback" element={<FeedbackList />} />
          <Route exact path="feedback/:id" element={<ViewFeedback />} />
          <Route
            exact
            path="feedback/delete/:id"
            element={<DeleteFeedback />}
          />
          <Route path="*" element={<Dashboard />} />
        </Route>
      </Routes>
    </div>
  );
};

export default Admin;
