import React from "react";
import { useIntl } from "react-intl";

const Distance = (props) => {
  const { formatMessage } = useIntl();

  let cell = props.cellValue;
  if (cell === null) {
    return (
      <div className="distance">{formatMessage({ id: "ui.label.na" })}</div>
    );
  }

  let miles = Math.round(cell).toLocaleString("en");
  let milesText = formatMessage({ id: "ui.label.miles" });

  if (miles === "0") {
    miles = "< 1";
    milesText = formatMessage({ id: "ui.label.mile" });
  } else if (miles === "1") {
    milesText = formatMessage({ id: "ui.label.mile" });
  }

  return (
    <div className="distance">
      {miles} {milesText}
    </div>
  );
};

export default Distance;
