import { jwtDecode } from "jwt-decode";
import "isomorphic-fetch";
const Host = process.env.REACT_APP_API_HOST;

export default class AuthService {
  // Initializing important variables
  constructor(domain) {
    this.domain = domain || Host;
    this.fetchWithCreds = this.fetchWithCreds.bind(this);
    this.login = this.login.bind(this);
  }

  async login(username, password) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const res = await fetch(`${this.domain}/auth/token`, {
      method: "POST",
      body: JSON.stringify({
        username,
        password,
      }),
      headers,
    });

    if (res.ok) {
      let data = await res.json();
      this.setAccessToken(data.accessToken);
      this.setRefreshToken(data.refreshToken);
      return true;
    }

    return false;
  }

  loggedIn() {
    const token = this.getAccessToken();
    return !!token;
  }

  checkAuth = () => {
    return new Promise((resolve, reject) => {
      let accessToken = this.getAccessToken();
      let refreshToken = this.getRefreshToken();
      if (!!accessToken && !this.isTokenExpired(accessToken)) {
        resolve(true);
      } else if (!!accessToken && this.isTokenExpired(accessToken)) {
        this.refresh(accessToken, refreshToken)
          .then((response) => {
            if (response.ok) {
              //console.log('got refresh token');
              //console.log('refresh token resp: ' + response);
              return response.json();
            }
          })
          .then((data) => {
            //console.log('setting new tokens');
            this.setAccessToken(data.accessToken);
            this.setRefreshToken(data.refreshToken);
            resolve(true);
          })
          .catch((err) => {
            this.logout();
            //console.log('check auth:' + err);
            resolve(false);
          });
      } else {
        console.log("resolving false default");
        resolve(false);
      }
    });
  };

  isAdmin() {
    const token = this.getAccessToken();
    if (!!token) {
      const decoded = jwtDecode(token);
      if (decoded.IsAdmin === "True") {
        return true;
      }
    }

    return false;
  }

  isSuperAdmin() {
    const token = this.getAccessToken();
    if (!!token) {
      const decoded = jwtDecode(token);
      if (decoded.IsSuperAdmin === "True") {
        return true;
      }
    }

    return false;
  }

  isTokenExpired(token) {
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000) {
        //console.log('Token expired');
        return true;
      } else {
        //console.log('token not expired');
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  setAccessToken(accessToken) {
    this.logout();
    if (
      typeof accessToken !== "undefined" &&
      accessToken !== "undefined" &&
      accessToken !== ""
    ) {
      sessionStorage.setItem("accessToken", accessToken);
    }
  }

  getAccessToken() {
    var accessToken = sessionStorage.getItem("accessToken");

    if (
      typeof accessToken !== "undefined" &&
      accessToken !== "undefined" &&
      accessToken !== ""
    ) {
      return accessToken;
    } else {
      sessionStorage.removeItem("accessToken");
      return "";
    }
  }

  setRefreshToken(refreshToken) {
    if (
      typeof refreshToken !== "undefined" &&
      refreshToken !== "undefined" &&
      refreshToken !== ""
    ) {
      sessionStorage.setItem("refreshToken", refreshToken);
    }
  }

  getRefreshToken() {
    var refreshToken = sessionStorage.getItem("refreshToken");
    if (
      typeof refreshToken !== "undefined" &&
      refreshToken !== "undefined" &&
      refreshToken !== ""
    ) {
      return refreshToken;
    } else {
      sessionStorage.removeItem("refreshToken");
      return "";
    }
  }

  logout() {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
  }

  async fetchWithCreds(url, options, useDefaultHeaders = true) {
    var accessToken = this.getAccessToken();
    let headers = {};
    if (useDefaultHeaders) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + accessToken;
    }

    var response = await fetch(url, {
      headers,
      ...options,
    });

    if (response.ok) {
      //console.log('looks good');
      return await response.json();
    }
    //console.log(response.status);
    if (response.status === 401 && response.headers.has("Token-Expired")) {
      var refreshToken = this.getRefreshToken();
      var refreshResponse = await this.refresh(accessToken, refreshToken);

      //console.log(refreshResponse);
      if (!refreshResponse.ok) {
        //console.log("//failed to refresh so return original 401 response2");
        //return response; //failed to refresh so return original 401 response
        this.logout();
        window.location.href = "/login";
      }

      var jsonRefreshResponse = await refreshResponse.json(); //read the json with the new tokens
      this.setAccessToken(jsonRefreshResponse.accessToken);
      this.setRefreshToken(jsonRefreshResponse.refreshToken);
      return await this.fetchWithCreds(url, options, useDefaultHeaders); //repeat the original request
    } else {
      //status is not 401 and/or there's no Token-Expired header
      //console.log("//status is not 401 and/or there's no Token-Expired header");
      return response; //return the original 401 response
    }
  }

  refresh(accessToken, refreshToken) {
    //console.log('getting refresh token');
    return fetch(`${this.domain}/auth/token/refresh`, {
      method: "POST",
      body: JSON.stringify({
        accessToken,
        refreshToken,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }
}
